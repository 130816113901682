import { ClassEventsList } from "../components/List/ClassEventsList";
import { ClassEventsCreate } from "../components/Create/ClassEventsCreate";
import { ClassEventsEdit } from "../components/Edit/ClassEventsEdit";
import { ClassEventsView } from "../components/View/ClassEventsView";
import { SettingFilled } from "@ant-design/icons";
export const CLASS_EVENTS_LIST = {
    path: "/admin/class-events",
    component: ClassEventsList,
    menu: {
        key: "CLASS_EVENTS_LIST",
        label: "management.class_events.menu.title",
        icon: SettingFilled,
    },
};
export const CLASS_EVENTS_CREATE = {
    path: "/admin/class-events/create",
    component: ClassEventsCreate,
};
export const CLASS_EVENTS_EDIT = {
    path: "/admin/class-events/:id/edit",
    component: ClassEventsEdit,
};
export const CLASS_EVENTS_VIEW = {
    path: "/admin/class-events/:id/view",
    component: ClassEventsView,
};
