import "./i18n";
import { COURSES_LIST as BASE_COURSES_LIST, COURSES_CREATE as BASE_COURSES_CREATE, COURSES_EDIT as BASE_COURSES_EDIT, COURSES_VIEW as BASE_COURSES_VIEW, } from "./config/routes";
export const COURSES_LIST = {
    ...BASE_COURSES_LIST,
};
export const COURSES_CREATE = {
    ...BASE_COURSES_CREATE,
};
export const COURSES_EDIT = {
    ...BASE_COURSES_EDIT,
};
export const COURSES_VIEW = {
    ...BASE_COURSES_VIEW,
};
