import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
import { buildModuleDataSource as buildDataSourceModule } from "./utils";
const modules = [
    {
        title: "Dashboard",
        name: "dashboard",
        submodules: [
            {
                title: "Analytics",
                name: "analytics",
            },
            {
                title: "Todo List",
                name: "todoList",
            },
            {
                title: "Deals to close",
                name: "dealsToClose",
            },
        ],
    },
    {
        title: "Your Agency",
        name: "agency",
        submodules: [
            {
                title: "Projects",
                name: "projects",
            },
            {
                title: "Sales (Deal Analyser, Audit, Revenue)",
                name: "sales",
            },
            {
                title: "Calendar",
                name: "calendar",
            },
        ],
    },
    {
        title: "Clients",
        name: "clients",
        submodules: [
            {
                title: "My Leads",
                name: "myLeads",
            },
            {
                title: "Search",
                name: "search",
            },
            {
                title: "Leads",
                name: "leads",
            },
            {
                title: "Clients",
                name: "clients",
            },
        ],
    },
    {
        title: "Campaigns",
        name: "campaigns",
        submodules: [
            {
                title: "Email",
                name: "email",
            },
            {
                title: "Campaigns",
                name: "campaigns",
            },
        ],
    },
    {
        title: "Fulfillment Center",
        name: "fulfillmentCenter",
    },
    {
        title: "Learning Center",
        name: "learningCenter",
    },
    {
        title: "Resource Center",
        name: "resourceCenter",
    },
    {
        title: "Agency Store",
        name: "agencyStore",
    },
];
const columns = [
    {
        title: "Module",
        dataIndex: "module",
        key: "module",
    },
    {
        title: "Submodule",
        dataIndex: "submodule",
        key: "submodule",
    },
    {
        title: "View",
        dataIndex: "view",
        key: "view",
    },
    {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
    },
    {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
    },
    {
        title: "Create",
        dataIndex: "create",
        key: "create",
    },
];
const dataSource = modules.map((module) => buildDataSourceModule(module)).flat(2);
export const PermissionRenderer = (props) => {
    return (
    // TODO: anything better than this style prop?
    _jsx(Ant.Row, Object.assign({ style: { display: "flex", justifyContent: "center", marginBottom: "1em" } }, { children: _jsx(Ant.Table, { pagination: false, columns: columns, dataSource: dataSource }, void 0) }), void 0));
};
