var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { BusinessesCollection, ImagesCollection, TodosListsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let TeamMemberListFiltersForm = class TeamMemberListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.team_members.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "lastName",
                label: t("management.team_members.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "phoneNumber",
                label: t("management.team_members.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.team_members.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "permissions",
                label: t("management.team_members.fields.permissions"),
                name: ["permissions"],
                columns: true,
                nest: [
                    {
                        id: "dashboard",
                        label: t("management.team_members.fields.permissions.dashboard"),
                        name: ["permissions", "dashboard"],
                        required: true,
                        nest: [
                            {
                                id: "analytics",
                                label: t("management.team_members.fields.permissions.dashboard.analytics"),
                                name: ["permissions", "dashboard", "analytics"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "todoList",
                                label: t("management.team_members.fields.permissions.dashboard.todoList"),
                                name: ["permissions", "dashboard", "todoList"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "dealsToClose",
                                label: t("management.team_members.fields.permissions.dashboard.dealsToClose"),
                                name: ["permissions", "dashboard", "dealsToClose"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "agency",
                        label: t("management.team_members.fields.permissions.agency"),
                        name: ["permissions", "agency"],
                        required: true,
                        nest: [
                            {
                                id: "projects",
                                label: t("management.team_members.fields.permissions.agency.projects"),
                                name: ["permissions", "agency", "projects"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "sales",
                                label: t("management.team_members.fields.permissions.agency.sales"),
                                name: ["permissions", "agency", "sales"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "calendar",
                                label: t("management.team_members.fields.permissions.agency.calendar"),
                                name: ["permissions", "agency", "calendar"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "clients",
                        label: t("management.team_members.fields.permissions.clients"),
                        name: ["permissions", "clients"],
                        required: true,
                        nest: [
                            {
                                id: "myLeads",
                                label: t("management.team_members.fields.permissions.clients.myLeads"),
                                name: ["permissions", "clients", "myLeads"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "search",
                                label: t("management.team_members.fields.permissions.clients.search"),
                                name: ["permissions", "clients", "search"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "leads",
                                label: t("management.team_members.fields.permissions.clients.leads"),
                                name: ["permissions", "clients", "leads"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "clients",
                                label: t("management.team_members.fields.permissions.clients.clients"),
                                name: ["permissions", "clients", "clients"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "campaigns",
                        label: t("management.team_members.fields.permissions.campaigns"),
                        name: ["permissions", "campaigns"],
                        required: true,
                        nest: [
                            {
                                id: "email",
                                label: t("management.team_members.fields.permissions.campaigns.email"),
                                name: ["permissions", "campaigns", "email"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "campaigns",
                                label: t("management.team_members.fields.permissions.campaigns.campaigns"),
                                name: ["permissions", "campaigns", "campaigns"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "fulfillmentCenter",
                        label: t("management.team_members.fields.permissions.fulfillmentCenter"),
                        name: ["permissions", "fulfillmentCenter"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "learningCenter",
                        label: t("management.team_members.fields.permissions.learningCenter"),
                        name: ["permissions", "learningCenter"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "resourceCenter",
                        label: t("management.team_members.fields.permissions.resourceCenter"),
                        name: ["permissions", "resourceCenter"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "agencyStore",
                        label: t("management.team_members.fields.permissions.agencyStore"),
                        name: ["permissions", "agencyStore"],
                        required: true,
                        nest: [],
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.team_members.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.team_members.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.team_members.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.team_members.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.team_members.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.team_members.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.team_members.fields.todosList"),
                name: ["todosListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "ownerId",
                label: t("management.team_members.fields.owner"),
                name: ["ownerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
TeamMemberListFiltersForm = __decorate([
    Service({ transient: true })
], TeamMemberListFiltersForm);
export { TeamMemberListFiltersForm };
