import "./i18n";
import { SUBSCRIPTION_PACKAGES_LIST as BASE_SUBSCRIPTION_PACKAGES_LIST, SUBSCRIPTION_PACKAGES_CREATE as BASE_SUBSCRIPTION_PACKAGES_CREATE, SUBSCRIPTION_PACKAGES_EDIT as BASE_SUBSCRIPTION_PACKAGES_EDIT, SUBSCRIPTION_PACKAGES_VIEW as BASE_SUBSCRIPTION_PACKAGES_VIEW, } from "./config/routes";
export const SUBSCRIPTION_PACKAGES_LIST = {
    ...BASE_SUBSCRIPTION_PACKAGES_LIST,
};
export const SUBSCRIPTION_PACKAGES_CREATE = {
    ...BASE_SUBSCRIPTION_PACKAGES_CREATE,
};
export const SUBSCRIPTION_PACKAGES_EDIT = {
    ...BASE_SUBSCRIPTION_PACKAGES_EDIT,
};
export const SUBSCRIPTION_PACKAGES_VIEW = {
    ...BASE_SUBSCRIPTION_PACKAGES_VIEW,
};
