var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let ServiceItemPackageViewer = class ServiceItemPackageViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.service_item_packages.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "name",
                label: t("management.service_item_packages.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "details",
                label: t("management.service_item_packages.fields.details"),
                dataIndex: ["details"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.likesPerPost",
                label: t("management.service_item_packages.fields.benefits.likesPerPost"),
                dataIndex: ["benefits", "likesPerPost"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isPrivate",
                label: t("management.service_item_packages.fields.isPrivate"),
                dataIndex: ["isPrivate"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice.price",
                label: t("management.service_item_packages.fields.retailPrice.price"),
                dataIndex: ["retailPrice", "price"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice.isMore",
                label: t("management.service_item_packages.fields.retailPrice.isMore"),
                dataIndex: ["retailPrice", "isMore"],
                render: (value) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice.per",
                label: t("management.service_item_packages.fields.retailPrice.per"),
                dataIndex: ["retailPrice", "per"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fees.oneTime",
                label: t("management.service_item_packages.fields.fees.oneTime"),
                dataIndex: ["fees", "oneTime"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fees.subscription",
                label: t("management.service_item_packages.fields.fees.subscription"),
                dataIndex: ["fees", "subscription"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.service_item_packages.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.service_item_packages.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                label: t("management.service_item_packages.fields.createdById"),
                dataIndex: ["createdById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                label: t("management.service_item_packages.fields.updatedById"),
                dataIndex: ["updatedById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "serviceItem",
                label: t("management.service_item_packages.fields.serviceItem"),
                dataIndex: ["serviceItem"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SERVICE_ITEMS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            details: 1,
            benefits: {
                likesPerPost: 1,
            },
            isPrivate: 1,
            retailPrice: {
                price: 1,
                isMore: 1,
                per: 1,
            },
            fees: {
                oneTime: {
                    name: 1,
                    per: {
                        type: 1,
                        granularity: {
                            min: 1,
                            max: 1,
                        },
                    },
                    price: 1,
                    stripeProductId: 1,
                    components: {
                        name: 1,
                        price: 1,
                    },
                },
                subscription: {
                    name: 1,
                    recurrency: 1,
                    price: 1,
                    stripeProductId: 1,
                },
            },
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            serviceItem: {
                _id: 1,
                name: 1,
            },
            serviceItemId: 1,
        };
    }
};
ServiceItemPackageViewer = __decorate([
    Service({ transient: true })
], ServiceItemPackageViewer);
export { ServiceItemPackageViewer };
