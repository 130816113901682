import { ClassEvent } from "@root/api.types";
import { ClassEventsCollection as BaseClassEventsCollection } from "./ClassEvents.collection";
import { gql } from "@apollo/client";
import 'moment-timezone';
export class ClassEventsCollection extends BaseClassEventsCollection {
    addClassEvent(document) {
        const selectedTimes = [];
        const { timezone, ...remainingData } = document;
        // const split_string = timezone.split('_') 
        // const offset = Number(split_string[split_string.length - 1])
        // document.eventDays.map((eventday) => {
        //   // const originalOffset = eventday.utcOffset();
        //   // const targetOffset = offset;
        //   // const totaloffset = -originalOffset + targetOffset*60
        //   // const utcMoment =  eventday.clone().subtract(totaloffset, 'minutes');
        //   const newDate = moment(eventday).tz(timezone, true)
        //   selectedTimes.push(newDate)
        // })
        // remainingData.eventDays = selectedTimes
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation AddClassEvent($input: ClassEventInsertInput!) {
            AddClassEvent(input: $input)
          }
        `,
            variables: {
                input: remainingData,
            },
        })
            .then((response) => {
            return response.data.mutationName;
        });
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return super.getTransformMap().eventDays((v) => v.length() && v.map((v) => new Date(v)));
    }
}
