var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ImagesCollection, ServiceItemsCollection, ResourcesCollection, } from "@bundles/UIAppBundle/collections";
let ResourceCreateForm = class ResourceCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.resources.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.resources.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "types",
                label: t("management.resources.fields.types"),
                name: ["types"],
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "SCRIPTS", label: "Scripts" },
                        { value: "CONTRACTS", label: "Contracts" },
                        { value: "PROSPECTING", label: "Prospecting" },
                        {
                            value: "SALES_AND_PRESENTATION",
                            label: "Sales And Presentation",
                        },
                        { value: "CASE_STUDIES", label: "Case Studies" },
                        { value: "EXPLAINER_VIDEOS", label: "Explainer Videos" },
                        {
                            value: "PROPOSALS_AND_ONBOARDING",
                            label: "Proposals And Onboarding",
                        },
                        { value: "OPERATIONS_AND_SCALE", label: "Operations And Scale" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, mode: "multiple", placeholder: t("management.resources.fields.types") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.resources.fields.types_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "industries",
                label: t("management.resources.fields.industries"),
                name: ["industries"],
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ATTORNEYS", label: "Attorneys" },
                        { value: "AUTOMOTIVE", label: "Automotive" },
                        { value: "DOCTOR", label: "Doctor" },
                        { value: "ECOMMERCE", label: "Ecommerce" },
                        { value: "ENTERTAINMENT", label: "Entertainment" },
                        { value: "FITNESS", label: "Fitness" },
                        { value: "FOOD", label: "Food" },
                        {
                            value: "HEALTH_WELLNESS_SUPPLEMENTS",
                            label: "Health Wellness Supplements",
                        },
                        { value: "MARKETING_AGENCY", label: "Marketing Agency" },
                        { value: "REAL_ESTATE", label: "Real Estate" },
                        { value: "SALONS", label: "Salons" },
                        { value: "TRADE_SERVICES", label: "Trade Services" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, mode: "multiple", placeholder: t("management.resources.fields.industries") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.resources.fields.industries_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "fileId",
                label: t("management.resources.fields.file"),
                name: ["fileId"],
                component: UIComponents.AdminFileUpload,
                componentProps: { field: "_id" },
            },
            {
                id: "logoId",
                label: t("management.resources.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
            {
                id: "recommendedForServicesIds",
                label: t("management.resources.fields.recommendedForServices"),
                name: ["recommendedForServicesIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ServiceItemsCollection, field: "name", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.resources.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.RESOURCES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.RESOURCES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.RESOURCES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ResourcesCollection),
    __metadata("design:type", typeof (_a = typeof ResourcesCollection !== "undefined" && ResourcesCollection) === "function" ? _a : Object)
], ResourceCreateForm.prototype, "collection", void 0);
ResourceCreateForm = __decorate([
    Service({ transient: true })
], ResourceCreateForm);
export { ResourceCreateForm };
