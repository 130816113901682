import { CustomServiceItemsList } from "../components/List/CustomServiceItemsList";
import { CustomServiceItemsView } from "../components/View/CustomServiceItemsView";
import { SettingFilled } from "@ant-design/icons";
export const CUSTOM_SERVICE_ITEMS_LIST = {
    path: "/admin/custom-service-items",
    component: CustomServiceItemsList,
    menu: {
        key: "CUSTOM_SERVICE_ITEMS_LIST",
        label: "management.custom_service_items.menu.title",
        icon: SettingFilled,
    },
};
export const CUSTOM_SERVICE_ITEMS_CREATE = {
    path: "/admin/custom-service-items/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const CUSTOM_SERVICE_ITEMS_EDIT = {
    path: "/admin/custom-service-items/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const CUSTOM_SERVICE_ITEMS_VIEW = {
    path: "/admin/custom-service-items/:id/view",
    component: CustomServiceItemsView,
};
