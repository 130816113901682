import { NotesList } from "../components/List/NotesList";
import { NotesCreate } from "../components/Create/NotesCreate";
import { NotesEdit } from "../components/Edit/NotesEdit";
import { NotesView } from "../components/View/NotesView";
import { SettingFilled } from "@ant-design/icons";
export const NOTES_LIST = {
    path: "/admin/notes",
    component: NotesList,
    menu: {
        key: "NOTES_LIST",
        label: "management.notes.menu.title",
        icon: SettingFilled,
    },
};
export const NOTES_CREATE = {
    path: "/admin/notes/create",
    component: NotesCreate,
};
export const NOTES_EDIT = {
    path: "/admin/notes/:id/edit",
    component: NotesEdit,
};
export const NOTES_VIEW = {
    path: "/admin/notes/:id/view",
    component: NotesView,
};
