import "./i18n";
import { COURSES_SECTIONS_LIST as BASE_COURSES_SECTIONS_LIST, COURSES_SECTIONS_CREATE as BASE_COURSES_SECTIONS_CREATE, COURSES_SECTIONS_EDIT as BASE_COURSES_SECTIONS_EDIT, COURSES_SECTIONS_VIEW as BASE_COURSES_SECTIONS_VIEW, } from "./config/routes";
export const COURSES_SECTIONS_LIST = {
    ...BASE_COURSES_SECTIONS_LIST,
};
export const COURSES_SECTIONS_CREATE = {
    ...BASE_COURSES_SECTIONS_CREATE,
};
export const COURSES_SECTIONS_EDIT = {
    ...BASE_COURSES_SECTIONS_EDIT,
};
export const COURSES_SECTIONS_VIEW = {
    ...BASE_COURSES_SECTIONS_VIEW,
};
