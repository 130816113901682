import { jsx as _jsx } from "react/jsx-runtime";
import * as Ant from "antd";
const Switch = (props) => {
    const { module, submodule, permission } = props;
    const name = ["permissions", module];
    if (submodule) {
        name.push(submodule);
    }
    name.push(permission);
    return (_jsx(Ant.Form.Item, Object.assign({ initialValue: false, valuePropName: "checked", name: name }, { children: _jsx(Ant.Switch, {}, void 0) }), void 0));
};
const Permissions = (props) => {
    return ["view", "edit", "delete", "create"].reduce((acc, permission) => {
        acc[permission] = _jsx(Switch, Object.assign({}, props, { permission: permission }), void 0);
        return acc;
    }, {});
};
export const buildModuleDataSource = (module) => {
    const { title: moduleTitle, name: moduleName, submodules } = module;
    if (!submodules) {
        return {
            key: moduleName,
            module: moduleTitle,
            ...Permissions({
                module: moduleName,
            }),
        };
    }
    return submodules.map((submodule, index) => {
        const { title: submoduleTitle, name: submoduleName } = submodule;
        return {
            key: `${moduleName}.${submoduleName}`,
            ...(index === 0 && { module: moduleTitle }),
            submodule: submoduleTitle,
            ...Permissions({
                module: moduleName,
                submodule: submoduleName,
            }),
        };
    });
};
