import { Collection, } from "@bluelibs/x-ui";
import { Business } from "@root/api.types";
import { UsersCollection, ImagesCollection, CountriesCollection, TimezonesCollection, BusinessSubscriptionsCollection, BusinessAddonsCollection, CalendarsCollection, TodosListsCollection, } from "@bundles/UIAppBundle/collections";
export class BusinessesCollection extends Collection {
    getName() {
        return "Businesses";
    }
    getInputs() {
        return {
            insert: "BusinessInsertInput!",
            update: "BusinessUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "owner",
                field: "ownerId",
            },
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => CountriesCollection,
                name: "country",
                field: "countryId",
            },
            {
                collection: () => TimezonesCollection,
                name: "timezone",
                field: "timezoneId",
            },
            {
                collection: () => BusinessSubscriptionsCollection,
                name: "subscription",
                field: "subscriptionId",
            },
            {
                collection: () => BusinessAddonsCollection,
                name: "addon",
                many: true,
                field: "addonIds",
            },
            {
                collection: () => CalendarsCollection,
                name: "calendar",
                field: "calendarId",
            },
            {
                collection: () => TodosListsCollection,
                name: "todosList",
                field: "todosListId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
