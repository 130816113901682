var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let ClassEventListFiltersForm = class ClassEventListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "updatedAt",
                label: t("management.class_events.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.class_events.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.class_events.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.class_events.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "meeting",
                label: t("management.class_events.fields.meeting"),
                name: ["meeting"],
                columns: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.class_events.fields.meeting.id"),
                        name: ["meeting", "id"],
                        component: Ant.Input,
                    },
                    {
                        id: "joinUrl",
                        label: t("management.class_events.fields.meeting.joinUrl"),
                        name: ["meeting", "joinUrl"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "password",
                        label: t("management.class_events.fields.meeting.password"),
                        name: ["meeting", "password"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "RepeatFrequency",
                label: t("management.class_events.fields.RepeatFrequency"),
                name: ["RepeatFrequency"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.class_events.fields.RepeatFrequency") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "WEEKLY" }, { children: "Weekly" }), "WEEKLY"), _jsx(Ant.Select.Option, Object.assign({ value: "MONTHLY" }, { children: "Monthly" }), "MONTHLY"), _jsx(Ant.Select.Option, Object.assign({ value: "MONTHLY_DAYWISE" }, { children: "Monthly Daywise" }), "MONTHLY_DAYWISE")] }), void 0) }), void 0)),
            },
            {
                id: "subscriptionType",
                label: t("management.class_events.fields.subscriptionType"),
                name: ["subscriptionType"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.class_events.fields.subscriptionType") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "LITE" }, { children: "Lite" }), "LITE"), _jsx(Ant.Select.Option, Object.assign({ value: "PLUS" }, { children: "Plus" }), "PLUS"), _jsx(Ant.Select.Option, Object.assign({ value: "PRO" }, { children: "Pro" }), "PRO"), _jsx(Ant.Select.Option, Object.assign({ value: "PRO_ELITE" }, { children: "Pro Elite" }), "PRO_ELITE"), _jsx(Ant.Select.Option, Object.assign({ value: "PRO_PINNACLE" }, { children: "Pro Pinnacle" }), "PRO_PINNACLE")] }), void 0) }), void 0)),
            },
            {
                id: "invitedEmails",
                label: t("management.class_events.fields.invitedEmails"),
                name: ["invitedEmails"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "durationInMinutes",
                label: t("management.class_events.fields.durationInMinutes"),
                name: ["durationInMinutes"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "eventDays",
                label: t("management.class_events.fields.eventDays"),
                name: ["eventDays"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "eventName",
                label: t("management.class_events.fields.eventName"),
                name: ["eventName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
        ]);
    }
};
ClassEventListFiltersForm = __decorate([
    Service({ transient: true })
], ClassEventListFiltersForm);
export { ClassEventListFiltersForm };
