import { PublicationsList } from "../components/List/PublicationsList";
import { PublicationsView } from "../components/View/PublicationsView";
import { SettingFilled } from "@ant-design/icons";
export const PUBLICATIONS_LIST = {
    path: "/admin/publications",
    component: PublicationsList,
    menu: {
        key: "PUBLICATIONS_LIST",
        label: "management.publications.menu.title",
        icon: SettingFilled,
    },
};
export const PUBLICATIONS_CREATE = {
    path: "/admin/publications/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const PUBLICATIONS_EDIT = {
    path: "/admin/publications/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const PUBLICATIONS_VIEW = {
    path: "/admin/publications/:id/view",
    component: PublicationsView,
};
