import "./i18n";
import { TODOS_LISTS_LIST as BASE_TODOS_LISTS_LIST, TODOS_LISTS_CREATE as BASE_TODOS_LISTS_CREATE, TODOS_LISTS_EDIT as BASE_TODOS_LISTS_EDIT, TODOS_LISTS_VIEW as BASE_TODOS_LISTS_VIEW, } from "./config/routes";
export const TODOS_LISTS_LIST = {
    ...BASE_TODOS_LISTS_LIST,
};
export const TODOS_LISTS_CREATE = {
    ...BASE_TODOS_LISTS_CREATE,
};
export const TODOS_LISTS_EDIT = {
    ...BASE_TODOS_LISTS_EDIT,
};
export const TODOS_LISTS_VIEW = {
    ...BASE_TODOS_LISTS_VIEW,
};
