import { Collection, } from "@bluelibs/x-ui";
import { Order } from "@root/api.types";
import { OrderItemsCollection, ProjectsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
export class OrdersCollection extends Collection {
    getName() {
        return "Orders";
    }
    getInputs() {
        return {
            insert: "OrderInsertInput!",
            update: "OrderUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => OrderItemsCollection,
                name: "orderItems",
                many: true,
                field: "orderItemsIds",
            },
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
            {
                collection: () => ProjectsCollection,
                name: "usedInProject",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            dueDate: (v) => (v && new Date(v) ? new Date(v) : v),
            scheduleDate: (v) => (v && new Date(v) ? new Date(v) : v),
            orderDate: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
