var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ServiceItemsCollection, } from "@bundles/UIAppBundle/collections";
let ServiceItemPackageListFiltersForm = class ServiceItemPackageListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.service_item_packages.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "details",
                label: t("management.service_item_packages.fields.details"),
                name: ["details"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "benefits",
                label: t("management.service_item_packages.fields.benefits"),
                name: ["benefits"],
                columns: true,
                nest: [
                    {
                        id: "likesPerPost",
                        label: t("management.service_item_packages.fields.benefits.likesPerPost"),
                        name: ["benefits", "likesPerPost"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "isPrivate",
                label: t("management.service_item_packages.fields.isPrivate"),
                name: ["isPrivate"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "retailPrice",
                label: t("management.service_item_packages.fields.retailPrice"),
                name: ["retailPrice"],
                columns: true,
                nest: [
                    {
                        id: "price",
                        label: t("management.service_item_packages.fields.retailPrice.price"),
                        name: ["retailPrice", "price"],
                        required: true,
                        component: Ant.InputNumber,
                    },
                    {
                        id: "isMore",
                        label: t("management.service_item_packages.fields.retailPrice.isMore"),
                        name: ["retailPrice", "isMore"],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                    },
                    {
                        id: "per",
                        label: t("management.service_item_packages.fields.retailPrice.per"),
                        name: ["retailPrice", "per"],
                        render: (props) => {
                            const options = [
                                { value: "MONTH", label: "Month" },
                                { value: "YEAR", label: "Year" },
                                { value: "INDOC_SEQUENCE", label: "Indoc Sequence" },
                                { value: "EACH", label: "Each" },
                                { value: "CAMPAIGN", label: "Campaign" },
                                { value: "FOLLOWER", label: "Follower" },
                                { value: "SUBSCRIBER", label: "Subscriber" },
                                { value: "LIKE_OR_FOLLOW", label: "Like Or Follow" },
                                { value: "PAGE", label: "Page" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ allowClear: true, placeholder: t("management.service_item_packages.fields.retailPrice.per") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.service_item_packages.fields.retailPrice.per_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                ],
            },
            {
                id: "fees",
                label: t("management.service_item_packages.fields.fees"),
                name: ["fees"],
                columns: true,
                nest: [
                    {
                        id: "oneTime",
                        label: t("management.service_item_packages.fields.fees.oneTime"),
                        name: ["fees", "oneTime"],
                        nest: [],
                    },
                    {
                        id: "subscription",
                        label: t("management.service_item_packages.fields.fees.subscription"),
                        name: ["fees", "subscription"],
                        nest: [],
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.service_item_packages.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.service_item_packages.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.service_item_packages.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.service_item_packages.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "serviceItemId",
                label: t("management.service_item_packages.fields.serviceItem"),
                name: ["serviceItemId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ServiceItemsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ServiceItemPackageListFiltersForm = __decorate([
    Service({ transient: true })
], ServiceItemPackageListFiltersForm);
export { ServiceItemPackageListFiltersForm };
