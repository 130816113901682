import { Collection, } from "@bluelibs/x-ui";
import { Timezone } from "@root/api.types";
export class TimezonesCollection extends Collection {
    getName() {
        return "Timezones";
    }
    getInputs() {
        return {
            insert: "TimezoneInsertInput!",
            update: "TimezoneUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
