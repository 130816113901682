import { Collection, } from "@bluelibs/x-ui";
import { Resource } from "@root/api.types";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { ImagesCollection, ServiceItemsCollection, } from "@bundles/UIAppBundle/collections";
export class ResourcesCollection extends Collection {
    getName() {
        return "Resources";
    }
    getInputs() {
        return {
            insert: "ResourceInsertInput!",
            update: "ResourceUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => AppFilesCollection,
                name: "file",
                field: "fileId",
            },
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => ServiceItemsCollection,
                name: "recommendedForServices",
                many: true,
                field: "recommendedForServicesIds",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
