import { Collection, } from "@bluelibs/x-ui";
import { Country } from "@root/api.types";
import { ImagesCollection } from "@bundles/UIAppBundle/collections";
export class CountriesCollection extends Collection {
    getName() {
        return "Countries";
    }
    getInputs() {
        return {
            insert: "CountryInsertInput!",
            update: "CountryUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ImagesCollection,
                name: "flag",
                field: "flagId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
