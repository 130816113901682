import { TrainingPlanCoursesList } from "../components/List/TrainingPlanCoursesList";
import { TrainingPlanCoursesCreate } from "../components/Create/TrainingPlanCoursesCreate";
import { TrainingPlanCoursesEdit } from "../components/Edit/TrainingPlanCoursesEdit";
import { TrainingPlanCoursesView } from "../components/View/TrainingPlanCoursesView";
import { SettingFilled } from "@ant-design/icons";
export const TRAINING_PLAN_COURSES_LIST = {
    path: "/admin/training-plan-courses",
    component: TrainingPlanCoursesList,
    menu: {
        key: "TRAINING_PLAN_COURSES_LIST",
        label: "management.training_plan_courses.menu.title",
        icon: SettingFilled,
    },
};
export const TRAINING_PLAN_COURSES_CREATE = {
    path: "/admin/training-plan-courses/create",
    component: TrainingPlanCoursesCreate,
};
export const TRAINING_PLAN_COURSES_EDIT = {
    path: "/admin/training-plan-courses/:id/edit",
    component: TrainingPlanCoursesEdit,
};
export const TRAINING_PLAN_COURSES_VIEW = {
    path: "/admin/training-plan-courses/:id/view",
    component: TrainingPlanCoursesView,
};
