var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let SubscriptionBundleViewer = class SubscriptionBundleViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.subscription_bundles.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "accessName",
                label: t("management.subscription_bundles.fields.accessName"),
                dataIndex: ["accessName"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.freeTrial",
                label: t("management.subscription_bundles.fields.benefits.freeTrial"),
                dataIndex: ["benefits", "freeTrial"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.users",
                label: t("management.subscription_bundles.fields.benefits.users"),
                dataIndex: ["benefits", "users"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.pricePerCredit",
                label: t("management.subscription_bundles.fields.benefits.pricePerCredit"),
                dataIndex: ["benefits", "pricePerCredit"],
                render: (value) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.dealAnalyzer",
                label: t("management.subscription_bundles.fields.benefits.dealAnalyzer"),
                dataIndex: ["benefits", "dealAnalyzer"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.auditGenerator",
                label: t("management.subscription_bundles.fields.benefits.auditGenerator"),
                dataIndex: ["benefits", "auditGenerator"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.revenueForecaster",
                label: t("management.subscription_bundles.fields.benefits.revenueForecaster"),
                dataIndex: ["benefits", "revenueForecaster"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.localBusinessSearch",
                label: t("management.subscription_bundles.fields.benefits.localBusinessSearch"),
                dataIndex: ["benefits", "localBusinessSearch"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.whiteLabelFulfillment",
                label: t("management.subscription_bundles.fields.benefits.whiteLabelFulfillment"),
                dataIndex: ["benefits", "whiteLabelFulfillment"],
                render: (value) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "string",
                                    value,
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "benefits.fulfillmentPricing",
                label: t("management.subscription_bundles.fields.benefits.fulfillmentPricing"),
                dataIndex: ["benefits", "fulfillmentPricing"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.resources",
                label: t("management.subscription_bundles.fields.benefits.resources"),
                dataIndex: ["benefits", "resources"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.courses",
                label: t("management.subscription_bundles.fields.benefits.courses"),
                dataIndex: ["benefits", "courses"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.groupCoaching",
                label: t("management.subscription_bundles.fields.benefits.groupCoaching"),
                dataIndex: ["benefits", "groupCoaching"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.internalTeamMessaging",
                label: t("management.subscription_bundles.fields.benefits.internalTeamMessaging"),
                dataIndex: ["benefits", "internalTeamMessaging"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.showOnHomepage",
                label: t("management.subscription_bundles.fields.benefits.showOnHomepage"),
                dataIndex: ["benefits", "showOnHomepage"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.CRM",
                label: t("management.subscription_bundles.fields.benefits.CRM"),
                dataIndex: ["benefits", "CRM"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.leadGenerator",
                label: t("management.subscription_bundles.fields.benefits.leadGenerator"),
                dataIndex: ["benefits", "leadGenerator"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.outboundEmails",
                label: t("management.subscription_bundles.fields.benefits.outboundEmails"),
                dataIndex: ["benefits", "outboundEmails"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.oneOnOneCoaching",
                label: t("management.subscription_bundles.fields.benefits.oneOnOneCoaching"),
                dataIndex: ["benefits", "oneOnOneCoaching"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.leads",
                label: t("management.subscription_bundles.fields.benefits.leads"),
                dataIndex: ["benefits", "leads"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.dfySetup",
                label: t("management.subscription_bundles.fields.benefits.dfySetup"),
                dataIndex: ["benefits", "dfySetup"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.guaranteedLeads",
                label: t("management.subscription_bundles.fields.benefits.guaranteedLeads"),
                dataIndex: ["benefits", "guaranteedLeads"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.outboundSms",
                label: t("management.subscription_bundles.fields.benefits.outboundSms"),
                dataIndex: ["benefits", "outboundSms"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.whiteLabelDashboard",
                label: t("management.subscription_bundles.fields.benefits.whiteLabelDashboard"),
                dataIndex: ["benefits", "whiteLabelDashboard"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.aiArticleGenerator",
                label: t("management.subscription_bundles.fields.benefits.aiArticleGenerator"),
                dataIndex: ["benefits", "aiArticleGenerator"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.aiProposalGenerator",
                label: t("management.subscription_bundles.fields.benefits.aiProposalGenerator"),
                dataIndex: ["benefits", "aiProposalGenerator"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.fulfillmentCenter",
                label: t("management.subscription_bundles.fields.benefits.fulfillmentCenter"),
                dataIndex: ["benefits", "fulfillmentCenter"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.resourceCenter",
                label: t("management.subscription_bundles.fields.benefits.resourceCenter"),
                dataIndex: ["benefits", "resourceCenter"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.tools",
                label: t("management.subscription_bundles.fields.benefits.tools"),
                dataIndex: ["benefits", "tools"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.mediaSpotlight",
                label: t("management.subscription_bundles.fields.benefits.mediaSpotlight"),
                dataIndex: ["benefits", "mediaSpotlight"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.subscription_bundles.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.subscription_bundles.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                label: t("management.subscription_bundles.fields.createdById"),
                dataIndex: ["createdById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                label: t("management.subscription_bundles.fields.updatedById"),
                dataIndex: ["updatedById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "subscriptionPackage",
                label: t("management.subscription_bundles.fields.subscriptionPackage"),
                dataIndex: ["subscriptionPackage"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SUBSCRIPTION_PACKAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            accessName: 1,
            benefits: {
                freeTrial: 1,
                users: 1,
                pricePerCredit: 1,
                dealAnalyzer: 1,
                auditGenerator: 1,
                revenueForecaster: 1,
                localBusinessSearch: 1,
                whiteLabelFulfillment: 1,
                fulfillmentPricing: 1,
                resources: 1,
                courses: 1,
                groupCoaching: 1,
                internalTeamMessaging: 1,
                showOnHomepage: 1,
                CRM: 1,
                leadGenerator: {
                    linkedin: 1,
                    instagram: 1,
                },
                outboundEmails: 1,
                oneOnOneCoaching: 1,
                leads: 1,
                dfySetup: 1,
                guaranteedLeads: 1,
                outboundSms: 1,
                whiteLabelDashboard: 1,
                aiArticleGenerator: 1,
                aiProposalGenerator: 1,
                fulfillmentCenter: 1,
                resourceCenter: 1,
                tools: 1,
                mediaSpotlight: 1,
            },
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            subscriptionPackage: {
                _id: 1,
            },
        };
    }
};
SubscriptionBundleViewer = __decorate([
    Service({ transient: true })
], SubscriptionBundleViewer);
export { SubscriptionBundleViewer };
