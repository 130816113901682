import "./i18n";
import { TODOS_LIST as BASE_TODOS_LIST, TODOS_CREATE as BASE_TODOS_CREATE, TODOS_EDIT as BASE_TODOS_EDIT, TODOS_VIEW as BASE_TODOS_VIEW, } from "./config/routes";
export const TODOS_LIST = {
    ...BASE_TODOS_LIST,
};
export const TODOS_CREATE = {
    ...BASE_TODOS_CREATE,
};
export const TODOS_EDIT = {
    ...BASE_TODOS_EDIT,
};
export const TODOS_VIEW = {
    ...BASE_TODOS_VIEW,
};
