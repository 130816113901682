import { Collection, } from "@bluelibs/x-ui";
import { BusinessDetail } from "@root/api.types";
import { ImagesCollection, CountriesCollection, TimezonesCollection, } from "@bundles/UIAppBundle/collections";
export class BusinessDetailsCollection extends Collection {
    getName() {
        return "BusinessDetails";
    }
    getInputs() {
        return {
            insert: "BusinessDetailInsertInput!",
            update: "BusinessDetailUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => CountriesCollection,
                name: "country",
                field: "countryId",
            },
            {
                collection: () => TimezonesCollection,
                name: "timezone",
                field: "timezoneId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
