export const TICKETS_LIST = {
    path: "/admin/tickets",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const TICKETS_CREATE = {
    path: "/admin/tickets/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const TICKETS_EDIT = {
    path: "/admin/tickets/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const TICKETS_VIEW = {
    path: "/admin/tickets/:id/view",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
