var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Inject, Service } from "@bluelibs/core";
import { BusinessEditForm as BaseBusinessEditForm } from "./BusinessEditForm.base";
import { TimezonesCollection, SubscriptionPackagesCollection, AddonsCollection } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { useEffect, useState } from "react";
let BusinessEditForm = class BusinessEditForm extends BaseBusinessEditForm {
    capitalizeFirstLetter(str) {
        return str
            .replace("_", " ")
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase();
        });
    }
    async build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        super.build();
        this.add([
            {
                id: "subscriptionName",
                label: "Subscription Name",
                name: ["subscription", "acquiredPackage", "subscriptionBundle", "accessName"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const [options, setOptions] = useState([]);
                    useEffect(() => {
                        this.subscriptionPackagesCollection.getSubscriptions().then((subscriptions) => {
                            const mappedOptions = subscriptions.map((subscription) => ({
                                label: `${this.capitalizeFirstLetter(subscription.accessName)} - ${this.capitalizeFirstLetter(subscription.pricingType)}`,
                                value: subscription._id,
                            }));
                            setOptions(mappedOptions);
                        });
                    }, []);
                    return (options && (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: "Subscription Name" }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: `${option.label}` }), option.value))) }), void 0) }), void 0)));
                },
            },
        ]);
        this.add({
            id: "addons",
            label: "Addons Acquired",
            name: ["addon"],
            isList: true,
            required: false,
            initialValue: [],
            render: (props) => {
                const [options, setOptions] = useState([]);
                useEffect(() => {
                    this.addonsCollection.find({}, {
                        _id: 1,
                        addonName: 1,
                    }).then((addon) => {
                        const mappedOptions = addon.map((addon) => ({
                            label: addon.addonName,
                            value: addon._id,
                        }));
                        setOptions(mappedOptions);
                    });
                }, []);
                console.log(props);
                return (options && (_jsx(_Fragment, { children: _jsx(Ant.Form.Item, Object.assign({}, props, { name: [...props.name.toString(), "acquiredAddon", "_id"] }, { children: _jsx(Ant.Select, Object.assign({ placeholder: "Addon Name" }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: `${option.label}` }), option.value))) }), void 0) }), void 0) }, void 0)));
            },
        });
        const timezoneId = "timezoneId";
        const timeZoneData = {
            id: "timezoneId",
            label: t("management.businesses.fields.timezone"),
            name: ["timezoneId"],
            required: true,
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TimezonesCollection, field: "name", required: false }, void 0) }), void 0)),
        };
        this.update(timezoneId, timeZoneData);
        this.remove("stripeCustomerId");
        this.remove("isOnboarded");
        this.remove("ownerId");
        this.remove("logoId");
        this.remove("countryId");
        this.remove("subscriptionId");
        this.remove("calendarId");
        this.remove("addonIds");
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
    async onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .editSubscription(values, _id)
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.businesses.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            return this.router.go(Routes.BUSINESSES_LIST);
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SubscriptionPackagesCollection),
    __metadata("design:type", typeof (_a = typeof SubscriptionPackagesCollection !== "undefined" && SubscriptionPackagesCollection) === "function" ? _a : Object)
], BusinessEditForm.prototype, "subscriptionPackagesCollection", void 0);
__decorate([
    Inject(() => AddonsCollection),
    __metadata("design:type", typeof (_b = typeof AddonsCollection !== "undefined" && AddonsCollection) === "function" ? _b : Object)
], BusinessEditForm.prototype, "addonsCollection", void 0);
BusinessEditForm = __decorate([
    Service({ transient: true })
], BusinessEditForm);
export { BusinessEditForm };
