var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { Inject, Service } from "@bluelibs/core";
import { UserCreateForm as BaseUserCreateForm } from "./UserCreateForm.base";
import { Routes } from "@bundles/UIAppBundle";
import { SmileOutlined } from "@ant-design/icons";
import { features } from "./features";
import * as Ant from "antd";
import { UsersCollection, } from "@bundles/UIAppBundle/collections";
let UserCreateForm = class UserCreateForm extends BaseUserCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: "First Name",
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: "Last Name",
                name: ["lastName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: "Phone Number",
                name: ["phoneNumber"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.users.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "password",
                label: t("management.users.fields.password"),
                name: ["password"],
                required: true,
                component: Ant.Input.Password,
            },
        ]);
        this.remove("isFirstLogin");
        this.remove("roles");
        this.remove("isEnabled");
        this.remove("profile");
        this.remove("profilePhotoId");
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    async onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .addUser(document)
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.users.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.list) {
                return this.router.go(Routes.USERS_LIST);
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => UsersCollection),
    __metadata("design:type", typeof (_a = typeof UsersCollection !== "undefined" && UsersCollection) === "function" ? _a : Object)
], UserCreateForm.prototype, "collection", void 0);
UserCreateForm = __decorate([
    Service({ transient: true })
], UserCreateForm);
export { UserCreateForm };
