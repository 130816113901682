var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { OrderEditForm as BaseOrderEditForm } from "./OrderEditForm.base";
import * as Ant from "antd";
let OrderEditForm = class OrderEditForm extends BaseOrderEditForm {
    build() {
        super.build();
        const { UIComponents } = this;
        const { t } = this.i18n;
        const statusId = "status";
        const statusData = {
            id: "status",
            label: t("management.orders.fields.status"),
            name: ["status"],
            required: true,
            render: (props) => {
                const options = [
                    { value: "Completed", label: "Completed" },
                    { value: "Pending", label: "Pending" },
                    { value: "In Review", label: "In Review" },
                    { value: "Canceled", label: "Canceled" },
                ];
                return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.orders.fields.status") }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: option.label }), option.value))) }), void 0) }), void 0));
            },
        };
        this.update(statusId, statusData);
        this.remove('updatedById');
        this.remove('createdById');
        this.remove('updatedAt');
        this.remove('createdAt');
        this.remove('dueDate');
        this.remove('stripeCheckoutSessionId');
        this.remove('type');
        this.remove('orderItems');
        this.remove('orderItemsIds');
        this.remove('usedInProject');
        this.remove('business');
        this.remove('scheduleDate');
        this.remove('ownerId');
        this.remove('orderDate');
        this.remove('service');
        this.remove('package');
        this.remove('retailPrice');
        this.remove('businessId');
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    // static getRequestBody(): QueryBodyType<Order> {
    //   // You have the ability to modify the request by adding certain fields or relations
    //   return super.getRequestBody();
    // }
    static getRequestBody() {
        const originalRequestBody = super.getRequestBody();
        // Modify originalRequestBody as needed
        delete originalRequestBody.updatedById;
        delete originalRequestBody.createdById;
        delete originalRequestBody.dueDate;
        delete originalRequestBody.stripeCheckoutSessionId;
        delete originalRequestBody.type;
        delete originalRequestBody.orderItems;
        delete originalRequestBody.orderItemsIds;
        delete originalRequestBody.usedInProject;
        delete originalRequestBody.business;
        delete originalRequestBody.businessId;
        return originalRequestBody;
    }
};
OrderEditForm = __decorate([
    Service({ transient: true })
], OrderEditForm);
export { OrderEditForm };
