var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { BusinessesCollection, TeamMembersCollection, TodosListsCollection, } from "@bundles/UIAppBundle/collections";
let TodosListEditForm = class TodosListEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.todos_lists.fields.name"),
                name: ["name"],
                component: Ant.Input,
            },
            {
                id: "businessId",
                label: t("management.todos_lists.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", required: false }, void 0) }), void 0)),
            },
            {
                id: "teamMemberId",
                label: t("management.todos_lists.fields.teamMember"),
                name: ["teamMemberId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TeamMembersCollection, field: "fullName", required: false }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
            teamMember: {
                _id: 1,
                fullName: 1,
            },
            teamMemberId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.todos_lists.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TodosListsCollection),
    __metadata("design:type", typeof (_a = typeof TodosListsCollection !== "undefined" && TodosListsCollection) === "function" ? _a : Object)
], TodosListEditForm.prototype, "collection", void 0);
TodosListEditForm = __decorate([
    Service({ transient: true })
], TodosListEditForm);
export { TodosListEditForm };
