import "./i18n";
import { RESOURCES_LIST as BASE_RESOURCES_LIST, RESOURCES_CREATE as BASE_RESOURCES_CREATE, RESOURCES_EDIT as BASE_RESOURCES_EDIT, RESOURCES_VIEW as BASE_RESOURCES_VIEW, } from "./config/routes";
export const RESOURCES_LIST = {
    ...BASE_RESOURCES_LIST,
};
export const RESOURCES_CREATE = {
    ...BASE_RESOURCES_CREATE,
};
export const RESOURCES_EDIT = {
    ...BASE_RESOURCES_EDIT,
};
export const RESOURCES_VIEW = {
    ...BASE_RESOURCES_VIEW,
};
