import { BusinessesCompletedCourseSectionsList } from "../components/List/BusinessesCompletedCourseSectionsList";
import { BusinessesCompletedCourseSectionsCreate } from "../components/Create/BusinessesCompletedCourseSectionsCreate";
import { BusinessesCompletedCourseSectionsEdit } from "../components/Edit/BusinessesCompletedCourseSectionsEdit";
import { BusinessesCompletedCourseSectionsView } from "../components/View/BusinessesCompletedCourseSectionsView";
import { SettingFilled } from "@ant-design/icons";
export const BUSINESSES_COMPLETED_COURSE_SECTIONS_LIST = {
    path: "/admin/businesses-completed-course-sections",
    component: BusinessesCompletedCourseSectionsList,
    menu: {
        key: "BUSINESSES_COMPLETED_COURSE_SECTIONS_LIST",
        label: "management.businesses_completed_course_sections.menu.title",
        icon: SettingFilled,
    },
};
export const BUSINESSES_COMPLETED_COURSE_SECTIONS_CREATE = {
    path: "/admin/businesses-completed-course-sections/create",
    component: BusinessesCompletedCourseSectionsCreate,
};
export const BUSINESSES_COMPLETED_COURSE_SECTIONS_EDIT = {
    path: "/admin/businesses-completed-course-sections/:id/edit",
    component: BusinessesCompletedCourseSectionsEdit,
};
export const BUSINESSES_COMPLETED_COURSE_SECTIONS_VIEW = {
    path: "/admin/businesses-completed-course-sections/:id/view",
    component: BusinessesCompletedCourseSectionsView,
};
