var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let AiArticleList = class AiArticleList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "articleHtml",
                title: t("management.ai_articles.fields.articleHtml"),
                key: "management.ai_articles.fields.articleHtml",
                dataIndex: ["articleHtml"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "title",
                title: t("management.ai_articles.fields.title"),
                key: "management.ai_articles.fields.title",
                dataIndex: ["title"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "type",
                title: t("management.ai_articles.fields.type"),
                key: "management.ai_articles.fields.type",
                dataIndex: ["type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "promptId",
                title: t("management.ai_articles.fields.promptId"),
                key: "management.ai_articles.fields.promptId",
                dataIndex: ["promptId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.ai_articles.fields.createdAt"),
                key: "management.ai_articles.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.ai_articles.fields.updatedAt"),
                key: "management.ai_articles.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.ai_articles.fields.createdById"),
                key: "management.ai_articles.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.ai_articles.fields.updatedById"),
                key: "management.ai_articles.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "business",
                title: t("management.ai_articles.fields.business"),
                key: "management.ai_articles.fields.business",
                dataIndex: ["business"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESSES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            business: "business.name",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            articleHtml: 1,
            title: 1,
            type: 1,
            promptId: 1,
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
        };
    }
};
AiArticleList = __decorate([
    Service({ transient: true })
], AiArticleList);
export { AiArticleList };
