import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter } from "@bluelibs/x-ui";
import { LockOutlined, DashboardFilled, ToolOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Button, Space, Row, Col, Alert, Card } from "antd";
export function Home() {
    var _a;
    const guardian = useGuardian();
    const router = useRouter();
    const style = { minHeight: "100vh" };
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style }, { children: _jsx(Col, Object.assign({ sm: 24, md: 16, lg: 8 }, { children: _jsxs(Card, Object.assign({ title: "Welcome to your brand new app!" }, { children: [_jsxs("p", { children: ["This is your application starter url. You can configure this behavior in ", _jsx("strong", { children: `{bundle}/pages/Home` }, void 0), " folder."] }, void 0), guardian.state.isLoggedIn && (_jsx(Alert, { type: "success", message: `You are logged in as ${(_a = guardian.state.user.profile) === null || _a === void 0 ? void 0 : _a.firstName}` }, void 0)), _jsxs("p", { children: [_jsx("br", {}, void 0), _jsxs(Space, { children: [_jsx("a", Object.assign({ href: "/public/schema.html", target: "_blank", rel: "noreferrer" }, { children: _jsx(Button, Object.assign({ icon: _jsx(ToolOutlined, {}, void 0) }, { children: "GraphQL Schema" }), void 0) }), void 0), _jsx("a", Object.assign({ href: process.env.API_URL, target: "_blank", rel: "noreferrer" }, { children: _jsx(Button, Object.assign({ icon: _jsx(ToolOutlined, {}, void 0) }, { children: "GraphQL Playground" }), void 0) }), void 0)] }, void 0), _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsxs(Space, { children: [_jsx(Link, Object.assign({ to: router.path(Routes.DASHBOARD) }, { children: _jsx(Button, Object.assign({ icon: _jsx(DashboardFilled, {}, void 0) }, { children: "Dashboard" }), void 0) }), void 0), _jsx(Link, Object.assign({ to: router.path(Routes.LOGIN) }, { children: _jsx(Button, Object.assign({ icon: _jsx(LockOutlined, {}, void 0) }, { children: "Login" }), void 0) }), void 0)] }, void 0)] }, void 0)] }), void 0) }), void 0) }), void 0));
}
