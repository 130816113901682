var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let TodoList = class TodoList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                title: t("management.todos.fields.title"),
                key: "management.todos.fields.title",
                dataIndex: ["title"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "completed",
                title: t("management.todos.fields.completed"),
                key: "management.todos.fields.completed",
                dataIndex: ["completed"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "favourite",
                title: t("management.todos.fields.favourite"),
                key: "management.todos.fields.favourite",
                dataIndex: ["favourite"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "dueDate",
                title: t("management.todos.fields.dueDate"),
                key: "management.todos.fields.dueDate",
                dataIndex: ["dueDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.todos.fields.createdAt"),
                key: "management.todos.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.todos.fields.updatedAt"),
                key: "management.todos.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.todos.fields.createdById"),
                key: "management.todos.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.todos.fields.updatedById"),
                key: "management.todos.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "todosList",
                title: t("management.todos.fields.todosList"),
                key: "management.todos.fields.todosList",
                dataIndex: ["todosList"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TODOS_LISTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "assignedTo",
                title: t("management.todos.fields.assignedTo"),
                key: "management.todos.fields.assignedTo",
                dataIndex: ["assignedTo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "executedBy",
                title: t("management.todos.fields.executedBy"),
                key: "management.todos.fields.executedBy",
                dataIndex: ["executedBy"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            todosList: "todosList._id",
            assignedTo: "assignedTo.fullName",
            executedBy: "executedBy.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            title: 1,
            completed: 1,
            favourite: 1,
            dueDate: 1,
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
            assignedTo: {
                _id: 1,
                fullName: 1,
            },
            assignedToId: 1,
            executedBy: {
                _id: 1,
                fullName: 1,
            },
            executedById: 1,
        };
    }
};
TodoList = __decorate([
    Service({ transient: true })
], TodoList);
export { TodoList };
