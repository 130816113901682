import "./i18n";
import { NOTES_LIST as BASE_NOTES_LIST, NOTES_CREATE as BASE_NOTES_CREATE, NOTES_EDIT as BASE_NOTES_EDIT, NOTES_VIEW as BASE_NOTES_VIEW, } from "./config/routes";
export const NOTES_LIST = {
    ...BASE_NOTES_LIST,
};
export const NOTES_CREATE = {
    ...BASE_NOTES_CREATE,
};
export const NOTES_EDIT = {
    ...BASE_NOTES_EDIT,
};
export const NOTES_VIEW = {
    ...BASE_NOTES_VIEW,
};
