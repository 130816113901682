import { ResourcesList } from "../components/List/ResourcesList";
import { ResourcesCreate } from "../components/Create/ResourcesCreate";
import { ResourcesEdit } from "../components/Edit/ResourcesEdit";
import { ResourcesView } from "../components/View/ResourcesView";
import { SettingFilled } from "@ant-design/icons";
export const RESOURCES_LIST = {
    path: "/admin/resources",
    component: ResourcesList,
    menu: {
        key: "RESOURCES_LIST",
        label: "management.resources.menu.title",
        icon: SettingFilled,
    },
};
export const RESOURCES_CREATE = {
    path: "/admin/resources/create",
    component: ResourcesCreate,
};
export const RESOURCES_EDIT = {
    path: "/admin/resources/:id/edit",
    component: ResourcesEdit,
};
export const RESOURCES_VIEW = {
    path: "/admin/resources/:id/view",
    component: ResourcesView,
};
