import { TodosList } from "../components/List/TodosList";
import { TodosCreate } from "../components/Create/TodosCreate";
import { TodosEdit } from "../components/Edit/TodosEdit";
import { TodosView } from "../components/View/TodosView";
import { SettingFilled } from "@ant-design/icons";
export const TODOS_LIST = {
    path: "/admin/todos",
    component: TodosList,
    menu: {
        key: "TODOS_LIST",
        label: "management.todos.menu.title",
        icon: SettingFilled,
    },
};
export const TODOS_CREATE = {
    path: "/admin/todos/create",
    component: TodosCreate,
};
export const TODOS_EDIT = {
    path: "/admin/todos/:id/edit",
    component: TodosEdit,
};
export const TODOS_VIEW = {
    path: "/admin/todos/:id/view",
    component: TodosView,
};
