import { Collection, } from "@bluelibs/x-ui";
import { BusinessSubscription } from "@root/api.types";
import { SubscriptionPackagesCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
export class BusinessSubscriptionsCollection extends Collection {
    getName() {
        return "BusinessSubscriptions";
    }
    getInputs() {
        return {
            insert: "BusinessSubscriptionInsertInput!",
            update: "BusinessSubscriptionUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => SubscriptionPackagesCollection,
                name: "acquiredPackage",
                field: "acquiredPackageId",
            },
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            startedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            expiresAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
