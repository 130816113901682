import "./i18n";
import { LEADS_LIST as BASE_LEADS_LIST, LEADS_CREATE as BASE_LEADS_CREATE, LEADS_EDIT as BASE_LEADS_EDIT, LEADS_VIEW as BASE_LEADS_VIEW, } from "./config/routes";
export const LEADS_LIST = {
    ...BASE_LEADS_LIST,
};
export const LEADS_CREATE = {
    ...BASE_LEADS_CREATE,
};
export const LEADS_EDIT = {
    ...BASE_LEADS_EDIT,
};
export const LEADS_VIEW = {
    ...BASE_LEADS_VIEW,
};
