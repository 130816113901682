import { TrainingPlanCompletedCoursesList } from "../components/List/TrainingPlanCompletedCoursesList";
import { TrainingPlanCompletedCoursesCreate } from "../components/Create/TrainingPlanCompletedCoursesCreate";
import { TrainingPlanCompletedCoursesEdit } from "../components/Edit/TrainingPlanCompletedCoursesEdit";
import { TrainingPlanCompletedCoursesView } from "../components/View/TrainingPlanCompletedCoursesView";
import { SettingFilled } from "@ant-design/icons";
export const TRAINING_PLAN_COMPLETED_COURSES_LIST = {
    path: "/admin/training-plan-completed-courses",
    component: TrainingPlanCompletedCoursesList,
    menu: {
        key: "TRAINING_PLAN_COMPLETED_COURSES_LIST",
        label: "management.training_plan_completed_courses.menu.title",
        icon: SettingFilled,
    },
};
export const TRAINING_PLAN_COMPLETED_COURSES_CREATE = {
    path: "/admin/training-plan-completed-courses/create",
    component: TrainingPlanCompletedCoursesCreate,
};
export const TRAINING_PLAN_COMPLETED_COURSES_EDIT = {
    path: "/admin/training-plan-completed-courses/:id/edit",
    component: TrainingPlanCompletedCoursesEdit,
};
export const TRAINING_PLAN_COMPLETED_COURSES_VIEW = {
    path: "/admin/training-plan-completed-courses/:id/view",
    component: TrainingPlanCompletedCoursesView,
};
