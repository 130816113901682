import "./i18n";
import { SERVICE_ITEM_PACKAGES_LIST as BASE_SERVICE_ITEM_PACKAGES_LIST, SERVICE_ITEM_PACKAGES_CREATE as BASE_SERVICE_ITEM_PACKAGES_CREATE, SERVICE_ITEM_PACKAGES_EDIT as BASE_SERVICE_ITEM_PACKAGES_EDIT, SERVICE_ITEM_PACKAGES_VIEW as BASE_SERVICE_ITEM_PACKAGES_VIEW, } from "./config/routes";
export const SERVICE_ITEM_PACKAGES_LIST = {
    ...BASE_SERVICE_ITEM_PACKAGES_LIST,
};
export const SERVICE_ITEM_PACKAGES_CREATE = {
    ...BASE_SERVICE_ITEM_PACKAGES_CREATE,
};
export const SERVICE_ITEM_PACKAGES_EDIT = {
    ...BASE_SERVICE_ITEM_PACKAGES_EDIT,
};
export const SERVICE_ITEM_PACKAGES_VIEW = {
    ...BASE_SERVICE_ITEM_PACKAGES_VIEW,
};
