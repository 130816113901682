import { CalendarEventsList } from "../components/List/CalendarEventsList";
import { CalendarEventsCreate } from "../components/Create/CalendarEventsCreate";
import { CalendarEventsEdit } from "../components/Edit/CalendarEventsEdit";
import { CalendarEventsView } from "../components/View/CalendarEventsView";
import { SettingFilled } from "@ant-design/icons";
export const CALENDAR_EVENTS_LIST = {
    path: "/admin/calendar-events",
    component: CalendarEventsList,
    menu: {
        key: "CALENDAR_EVENTS_LIST",
        label: "management.calendar_events.menu.title",
        icon: SettingFilled,
    },
};
export const CALENDAR_EVENTS_CREATE = {
    path: "/admin/calendar-events/create",
    component: CalendarEventsCreate,
};
export const CALENDAR_EVENTS_EDIT = {
    path: "/admin/calendar-events/:id/edit",
    component: CalendarEventsEdit,
};
export const CALENDAR_EVENTS_VIEW = {
    path: "/admin/calendar-events/:id/view",
    component: CalendarEventsView,
};
