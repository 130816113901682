import { CountriesList } from "../components/List/CountriesList";
import { CountriesCreate } from "../components/Create/CountriesCreate";
import { CountriesEdit } from "../components/Edit/CountriesEdit";
import { CountriesView } from "../components/View/CountriesView";
import { SettingFilled } from "@ant-design/icons";
export const COUNTRIES_LIST = {
    path: "/admin/countries",
    component: CountriesList,
    menu: {
        key: "COUNTRIES_LIST",
        label: "management.countries.menu.title",
        icon: SettingFilled,
    },
};
export const COUNTRIES_CREATE = {
    path: "/admin/countries/create",
    component: CountriesCreate,
};
export const COUNTRIES_EDIT = {
    path: "/admin/countries/:id/edit",
    component: CountriesEdit,
};
export const COUNTRIES_VIEW = {
    path: "/admin/countries/:id/view",
    component: CountriesView,
};
