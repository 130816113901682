var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { UserEditForm as BaseUserEditForm } from "./UserEditForm.base";
import { Routes } from "@bundles/UIAppBundle";
import { SmileOutlined } from "@ant-design/icons";
import { features } from "./features";
import * as Ant from "antd";
let UserEditForm = class UserEditForm extends BaseUserEditForm {
    build() {
        super.build();
        const { t } = this.i18n;
        const profileId = "profile";
        const profileData = {
            id: "profile",
            label: t("management.users.fields.profile"),
            name: ["profile"],
            required: true,
            nest: [
                {
                    id: "firstName",
                    label: "First Name",
                    name: ["profile", "firstName"],
                    required: true,
                    component: Ant.Input,
                },
                {
                    id: "lastName",
                    label: "Last Name",
                    name: ["profile", "lastName"],
                    required: true,
                    component: Ant.Input,
                },
            ],
        };
        this.update(profileId, profileData);
        this.add([
            {
                id: "email",
                label: "email",
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
        ]);
        this.add([
            {
                id: "password",
                label: t("management.users.fields.password"),
                name: ["password"],
                required: true,
                component: Ant.Input.Password,
            },
        ]);
        this.remove("isFirstLogin");
        this.remove("roles");
        this.remove("isEnabled");
        this.remove("profilePhotoId");
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        const requestBody = super.getRequestBody();
        Object.assign(requestBody, {
            email: 1,
        });
        return requestBody;
    }
    async onSubmit(_id, document) {
        const { t } = this.i18n;
        document._id = _id;
        return this.collection
            .editUser(document)
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.users.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.list) {
                return this.router.go(Routes.USERS_LIST);
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
UserEditForm = __decorate([
    Service({ transient: true })
], UserEditForm);
export { UserEditForm };
