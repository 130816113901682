import { CoursesSectionsList } from "../components/List/CoursesSectionsList";
import { CoursesSectionsCreate } from "../components/Create/CoursesSectionsCreate";
import { CoursesSectionsEdit } from "../components/Edit/CoursesSectionsEdit";
import { CoursesSectionsView } from "../components/View/CoursesSectionsView";
import { SettingFilled } from "@ant-design/icons";
export const COURSES_SECTIONS_LIST = {
    path: "/admin/courses-sections",
    component: CoursesSectionsList,
    menu: {
        key: "COURSES_SECTIONS_LIST",
        label: "management.courses_sections.menu.title",
        icon: SettingFilled,
    },
};
export const COURSES_SECTIONS_CREATE = {
    path: "/admin/courses-sections/create",
    component: CoursesSectionsCreate,
};
export const COURSES_SECTIONS_EDIT = {
    path: "/admin/courses-sections/:id/edit",
    component: CoursesSectionsEdit,
};
export const COURSES_SECTIONS_VIEW = {
    path: "/admin/courses-sections/:id/view",
    component: CoursesSectionsView,
};
