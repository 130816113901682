import { BusinessAddonsList } from "../components/List/BusinessAddonsList";
import { BusinessAddonsView } from "../components/View/BusinessAddonsView";
import { SettingFilled } from "@ant-design/icons";
export const BUSINESS_ADDONS_LIST = {
    path: "/admin/business-addons",
    component: BusinessAddonsList,
    menu: {
        key: "BUSINESS_ADDONS_LIST",
        label: "management.business_addons.menu.title",
        icon: SettingFilled,
    },
};
export const BUSINESS_ADDONS_CREATE = {
    path: "/admin/business-addons/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const BUSINESS_ADDONS_EDIT = {
    path: "/admin/business-addons/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const BUSINESS_ADDONS_VIEW = {
    path: "/admin/business-addons/:id/view",
    component: BusinessAddonsView,
};
