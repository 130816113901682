var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { OrderViewer as BaseOrderViewer } from "./OrderViewer.base";
let OrderViewer = class OrderViewer extends BaseOrderViewer {
    build() {
        super.build();
        this.remove('updatedById');
        this.remove('createdById');
        this.remove('updatedAt');
        this.remove('createdAt');
        this.remove('dueDate');
        this.remove('stripeCheckoutSessionId');
        this.remove('type');
        this.remove('orderItems');
        this.remove('usedInProject');
        this.remove('business');
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    // static getRequestBody(): QueryBodyType<Order> {
    //   // You have the ability to modify the request by adding certain fields or relations
    //   return super.getRequestBody();
    // }
    static getRequestBody() {
        const originalRequestBody = super.getRequestBody();
        // Modify originalRequestBody as needed
        delete originalRequestBody.updatedById;
        delete originalRequestBody.createdById;
        delete originalRequestBody.dueDate;
        delete originalRequestBody.stripeCheckoutSessionId;
        delete originalRequestBody.type;
        delete originalRequestBody.orderItems;
        delete originalRequestBody.orderItemsIds;
        delete originalRequestBody.usedInProject;
        delete originalRequestBody.business;
        delete originalRequestBody.businessId;
        return originalRequestBody;
    }
};
OrderViewer = __decorate([
    Service({ transient: true })
], OrderViewer);
export { OrderViewer };
