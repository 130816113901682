import { LeadsList } from "../components/List/LeadsList";
import { LeadsView } from "../components/View/LeadsView";
import { SettingFilled } from "@ant-design/icons";
export const LEADS_LIST = {
    path: "/admin/leads",
    component: LeadsList,
    menu: {
        key: "LEADS_LIST",
        label: "management.leads.menu.title",
        icon: SettingFilled,
    },
};
export const LEADS_CREATE = {
    path: "/admin/leads/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const LEADS_EDIT = {
    path: "/admin/leads/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const LEADS_VIEW = {
    path: "/admin/leads/:id/view",
    component: LeadsView,
};
