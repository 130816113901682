import { SubscriptionPackagesList } from "../components/List/SubscriptionPackagesList";
import { SubscriptionPackagesEdit } from "../components/Edit/SubscriptionPackagesEdit";
import { SubscriptionPackagesView } from "../components/View/SubscriptionPackagesView";
import { SettingFilled } from "@ant-design/icons";
export const SUBSCRIPTION_PACKAGES_LIST = {
    path: "/admin/subscription-packages",
    component: SubscriptionPackagesList,
    menu: {
        key: "SUBSCRIPTION_PACKAGES_LIST",
        label: "management.subscription_packages.menu.title",
        icon: SettingFilled,
    },
};
export const SUBSCRIPTION_PACKAGES_CREATE = {
    path: "/admin/subscription-packages/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const SUBSCRIPTION_PACKAGES_EDIT = {
    path: "/admin/subscription-packages/:id/edit",
    component: SubscriptionPackagesEdit,
};
export const SUBSCRIPTION_PACKAGES_VIEW = {
    path: "/admin/subscription-packages/:id/view",
    component: SubscriptionPackagesView,
};
