import { CalendarsList } from "../components/List/CalendarsList";
import { CalendarsCreate } from "../components/Create/CalendarsCreate";
import { CalendarsEdit } from "../components/Edit/CalendarsEdit";
import { CalendarsView } from "../components/View/CalendarsView";
import { SettingFilled } from "@ant-design/icons";
export const CALENDARS_LIST = {
    path: "/admin/calendars",
    component: CalendarsList,
    menu: {
        key: "CALENDARS_LIST",
        label: "management.calendars.menu.title",
        icon: SettingFilled,
    },
};
export const CALENDARS_CREATE = {
    path: "/admin/calendars/create",
    component: CalendarsCreate,
};
export const CALENDARS_EDIT = {
    path: "/admin/calendars/:id/edit",
    component: CalendarsEdit,
};
export const CALENDARS_VIEW = {
    path: "/admin/calendars/:id/view",
    component: CalendarsView,
};
