import { Collection, } from "@bluelibs/x-ui";
import { Ticket } from "@root/api.types";
import { BusinessesCollection } from "@bundles/UIAppBundle/collections";
export class TicketsCollection extends Collection {
    getName() {
        return "Tickets";
    }
    getInputs() {
        return {
            insert: "TicketInsertInput!",
            update: "TicketUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
