import "./i18n";
import { TEAM_MEMBER_INVITES_LIST as BASE_TEAM_MEMBER_INVITES_LIST, TEAM_MEMBER_INVITES_CREATE as BASE_TEAM_MEMBER_INVITES_CREATE, TEAM_MEMBER_INVITES_EDIT as BASE_TEAM_MEMBER_INVITES_EDIT, TEAM_MEMBER_INVITES_VIEW as BASE_TEAM_MEMBER_INVITES_VIEW, } from "./config/routes";
export const TEAM_MEMBER_INVITES_LIST = {
    ...BASE_TEAM_MEMBER_INVITES_LIST,
};
export const TEAM_MEMBER_INVITES_CREATE = {
    ...BASE_TEAM_MEMBER_INVITES_CREATE,
};
export const TEAM_MEMBER_INVITES_EDIT = {
    ...BASE_TEAM_MEMBER_INVITES_EDIT,
};
export const TEAM_MEMBER_INVITES_VIEW = {
    ...BASE_TEAM_MEMBER_INVITES_VIEW,
};
