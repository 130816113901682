import { CreditsPackagesList } from "../components/List/CreditsPackagesList";
import { CreditsPackagesCreate } from "../components/Create/CreditsPackagesCreate";
import { CreditsPackagesEdit } from "../components/Edit/CreditsPackagesEdit";
import { CreditsPackagesView } from "../components/View/CreditsPackagesView";
import { SettingFilled } from "@ant-design/icons";
export const CREDITS_PACKAGES_LIST = {
    path: "/admin/credits-packages",
    component: CreditsPackagesList,
    menu: {
        key: "CREDITS_PACKAGES_LIST",
        label: "management.credits_packages.menu.title",
        icon: SettingFilled,
    },
};
export const CREDITS_PACKAGES_CREATE = {
    path: "/admin/credits-packages/create",
    component: CreditsPackagesCreate,
};
export const CREDITS_PACKAGES_EDIT = {
    path: "/admin/credits-packages/:id/edit",
    component: CreditsPackagesEdit,
};
export const CREDITS_PACKAGES_VIEW = {
    path: "/admin/credits-packages/:id/view",
    component: CreditsPackagesView,
};
