var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
let AddonList = class AddonList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "addonName",
                title: t("management.addons.fields.addonName"),
                key: "management.addons.fields.addonName",
                dataIndex: ["addonName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits",
                title: t("management.addons.fields.benefits"),
                key: "management.addons.fields.benefits",
                dataIndex: ["benefits"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "price",
                title: t("management.addons.fields.price"),
                key: "management.addons.fields.price",
                dataIndex: ["price"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "pricingType",
                title: t("management.addons.fields.pricingType"),
                key: "management.addons.fields.pricingType",
                dataIndex: ["pricingType"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.addons.fields.createdAt"),
                key: "management.addons.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.addons.fields.updatedAt"),
                key: "management.addons.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.addons.fields.createdById"),
                key: "management.addons.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.addons.fields.updatedById"),
                key: "management.addons.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {};
    }
    static getRequestBody() {
        return {
            _id: 1,
            addonName: 1,
            benefits: 1,
            price: 1,
            pricingType: 1,
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
        };
    }
};
AddonList = __decorate([
    Service({ transient: true })
], AddonList);
export { AddonList };
