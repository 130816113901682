import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { PhoneOutlined, MailOutlined, } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Tabs, Row, Col, Alert, Card, } from "antd";
const { TabPane } = Tabs;
export function RequestMagicLink(props) {
    const guardian = useGuardian();
    const { userId } = props.queryVariables;
    const tl = useTranslate("authentication.requestMagicLink");
    const router = useRouter();
    const [linkSentError, setLinkSentError] = useState(null);
    const [method, setMethod] = useState("email");
    const [username, setUsername] = useState("");
    const onSubmit = () => {
        guardian
            .requestLoginLink({ username, method, userId })
            .then((data) => {
            if (data.magicCodeSent) {
                router.go(Routes.SUBMIT_MAGIC_LINK, {
                    query: {
                        userId: data.userId,
                        method: data.method,
                        format: data.magicAuthFormat,
                    },
                });
            }
        })
            .catch((err) => {
            console.log(err);
            setLinkSentError(err);
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style, className: "request-magic-link-page" }, { children: _jsx(Col, Object.assign({ sm: 24, md: 12, lg: 6 }, { children: _jsx(Card, Object.assign({ title: tl("header") }, { children: _jsxs(Form, Object.assign({ className: "authentication-form", onFinish: (data) => onSubmit() }, { children: [userId ? (_jsx(_Fragment, {}, void 0)) : (_jsx(Form.Item, Object.assign({ name: "username", rules: [{ required: !userId }] }, { children: _jsx(Input, { value: username, prefix: method === "email" ? _jsx(MailOutlined, {}, void 0) : _jsx(PhoneOutlined, {}, void 0), onChange: (e) => setUsername(e.target.value), placeholder: tl(method === "email" ? "fields.email" : "fields.phoneNumber") }, void 0) }), void 0)), _jsx(Form.Item, { children: _jsx("a", Object.assign({ className: "authentication-form-magic-code", onClick: onSubmit }, { children: tl("already_have_code") }), void 0) }, void 0), _jsx(Form.Item, { children: _jsx(Link, Object.assign({ className: "authentication-form-login", to: router.path(Routes.LOGIN) }, { children: tl("simple_login_btn") }), void 0) }, void 0), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", onClick: onSubmit, className: "authentication-form-button" }, { children: tl("send_magic_link") }), void 0) }, void 0), linkSentError && (_jsx(Alert, { message: linkSentError.networkError
                                ? linkSentError.toString()
                                : tl("invalid_credentials"), type: "error" }, void 0))] }), void 0) }), void 0) }), void 0) }), void 0));
}
