import "./i18n";
import { SUBSCRIPTION_BUNDLES_LIST as BASE_SUBSCRIPTION_BUNDLES_LIST, SUBSCRIPTION_BUNDLES_CREATE as BASE_SUBSCRIPTION_BUNDLES_CREATE, SUBSCRIPTION_BUNDLES_EDIT as BASE_SUBSCRIPTION_BUNDLES_EDIT, SUBSCRIPTION_BUNDLES_VIEW as BASE_SUBSCRIPTION_BUNDLES_VIEW, } from "./config/routes";
export const SUBSCRIPTION_BUNDLES_LIST = {
    ...BASE_SUBSCRIPTION_BUNDLES_LIST,
};
export const SUBSCRIPTION_BUNDLES_CREATE = {
    ...BASE_SUBSCRIPTION_BUNDLES_CREATE,
};
export const SUBSCRIPTION_BUNDLES_EDIT = {
    ...BASE_SUBSCRIPTION_BUNDLES_EDIT,
};
export const SUBSCRIPTION_BUNDLES_VIEW = {
    ...BASE_SUBSCRIPTION_BUNDLES_VIEW,
};
