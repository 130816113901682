var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let OrderList = class OrderList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "updatedById",
                title: t("management.orders.fields.updatedById"),
                key: "management.orders.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.orders.fields.createdById"),
                key: "management.orders.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.orders.fields.updatedAt"),
                key: "management.orders.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.orders.fields.createdAt"),
                key: "management.orders.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "dueDate",
                title: t("management.orders.fields.dueDate"),
                key: "management.orders.fields.dueDate",
                dataIndex: ["dueDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "scheduleDate",
                title: t("management.orders.fields.scheduleDate"),
                key: "management.orders.fields.scheduleDate",
                dataIndex: ["scheduleDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "ownerId",
                title: t("management.orders.fields.ownerId"),
                key: "management.orders.fields.ownerId",
                dataIndex: ["ownerId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                title: t("management.orders.fields.status"),
                key: "management.orders.fields.status",
                dataIndex: ["status"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orderDate",
                title: t("management.orders.fields.orderDate"),
                key: "management.orders.fields.orderDate",
                dataIndex: ["orderDate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "service",
                title: t("management.orders.fields.service"),
                key: "management.orders.fields.service",
                dataIndex: ["service"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "package",
                title: t("management.orders.fields.package"),
                key: "management.orders.fields.package",
                dataIndex: ["package"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice",
                title: t("management.orders.fields.retailPrice"),
                key: "management.orders.fields.retailPrice",
                dataIndex: ["retailPrice"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "stripeCheckoutSessionId",
                title: t("management.orders.fields.stripeCheckoutSessionId"),
                key: "management.orders.fields.stripeCheckoutSessionId",
                dataIndex: ["stripeCheckoutSessionId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "type",
                title: t("management.orders.fields.type"),
                key: "management.orders.fields.type",
                dataIndex: ["type"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "orderItems",
                title: t("management.orders.fields.orderItems"),
                key: "management.orders.fields.orderItems",
                dataIndex: ["orderItems"],
                sorter: true,
                render: (value, model) => {
                    return (_jsx(_Fragment, { children: value &&
                            value.map((value, idx) => {
                                const props = {
                                    type: "relation",
                                    value,
                                    relation: {
                                        path: router.path(Routes.ORDER_ITEMS_VIEW, {
                                            params: {
                                                id: value === null || value === void 0 ? void 0 : value._id,
                                            },
                                        }),
                                        dataIndex: "_id",
                                    },
                                };
                                return (_createElement(UIComponents.AdminListItemRenderer, { ...props, key: idx }));
                            }) }, void 0));
                },
            },
            {
                id: "usedInProject",
                title: t("management.orders.fields.usedInProject"),
                key: "management.orders.fields.usedInProject",
                dataIndex: ["usedInProject"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.PROJECTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "business",
                title: t("management.orders.fields.business"),
                key: "management.orders.fields.business",
                dataIndex: ["business"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESSES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            orderItems: "orderItems._id",
            usedInProject: "usedInProject._id",
            business: "business.name",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            updatedById: 1,
            createdById: 1,
            updatedAt: 1,
            createdAt: 1,
            dueDate: 1,
            scheduleDate: 1,
            ownerId: 1,
            status: 1,
            orderDate: 1,
            service: 1,
            package: 1,
            retailPrice: 1,
            stripeCheckoutSessionId: 1,
            type: 1,
            orderItems: {
                _id: 1,
            },
            orderItemsIds: 1,
            usedInProject: {
                _id: 1,
            },
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
        };
    }
};
OrderList = __decorate([
    Service({ transient: true })
], OrderList);
export { OrderList };
