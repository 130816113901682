import { Collection, } from "@bluelibs/x-ui";
import { Todo } from "@root/api.types";
import { TodosListsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class TodosCollection extends Collection {
    getName() {
        return "Todos";
    }
    getInputs() {
        return {
            insert: "TodoInsertInput!",
            update: "TodoUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => TodosListsCollection,
                name: "todosList",
                field: "todosListId",
            },
            {
                collection: () => UsersCollection,
                name: "assignedTo",
                field: "assignedToId",
            },
            {
                collection: () => UsersCollection,
                name: "executedBy",
                field: "executedById",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            dueDate: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
