import "./i18n";
import { CALENDAR_EVENTS_LIST as BASE_CALENDAR_EVENTS_LIST, CALENDAR_EVENTS_CREATE as BASE_CALENDAR_EVENTS_CREATE, CALENDAR_EVENTS_EDIT as BASE_CALENDAR_EVENTS_EDIT, CALENDAR_EVENTS_VIEW as BASE_CALENDAR_EVENTS_VIEW, } from "./config/routes";
export const CALENDAR_EVENTS_LIST = {
    ...BASE_CALENDAR_EVENTS_LIST,
};
export const CALENDAR_EVENTS_CREATE = {
    ...BASE_CALENDAR_EVENTS_CREATE,
};
export const CALENDAR_EVENTS_EDIT = {
    ...BASE_CALENDAR_EVENTS_EDIT,
};
export const CALENDAR_EVENTS_VIEW = {
    ...BASE_CALENDAR_EVENTS_VIEW,
};
