import { Collection, } from "@bluelibs/x-ui";
import { Project } from "@root/api.types";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { ClientsCollection, OrdersCollection, TeamMembersCollection, ImagesCollection, TodosListsCollection, NoteListsCollection, CustomServiceItemsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
export class ProjectsCollection extends Collection {
    getName() {
        return "Projects";
    }
    getInputs() {
        return {
            insert: "ProjectInsertInput!",
            update: "ProjectUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ClientsCollection,
                name: "client",
                field: "clientId",
            },
            {
                collection: () => OrdersCollection,
                name: "serviceOrders",
                many: true,
                field: "serviceOrdersIds",
            },
            {
                collection: () => TeamMembersCollection,
                name: "assignedTeamMembers",
                many: true,
                field: "assignedTeamMembersIds",
            },
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => ImagesCollection,
                name: "cover",
                field: "coverId",
            },
            {
                collection: () => TodosListsCollection,
                name: "todosList",
                field: "todosListId",
            },
            {
                collection: () => NoteListsCollection,
                name: "noteList",
                field: "noteListId",
            },
            {
                collection: () => AppFilesCollection,
                name: "uploadedContracts",
                many: true,
                field: "uploadedContractsIds",
            },
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
            {
                collection: () => CustomServiceItemsCollection,
                name: "customServiceItem",
                many: true,
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            deadline: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
