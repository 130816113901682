import "./i18n";
import { TIMEZONES_LIST as BASE_TIMEZONES_LIST, TIMEZONES_CREATE as BASE_TIMEZONES_CREATE, TIMEZONES_EDIT as BASE_TIMEZONES_EDIT, TIMEZONES_VIEW as BASE_TIMEZONES_VIEW, } from "./config/routes";
export const TIMEZONES_LIST = {
    ...BASE_TIMEZONES_LIST,
};
export const TIMEZONES_CREATE = {
    ...BASE_TIMEZONES_CREATE,
};
export const TIMEZONES_EDIT = {
    ...BASE_TIMEZONES_EDIT,
};
export const TIMEZONES_VIEW = {
    ...BASE_TIMEZONES_VIEW,
};
