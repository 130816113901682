var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { TeamMembersCollection, CalendarsCollection, } from "@bundles/UIAppBundle/collections";
let CalendarEventListFiltersForm = class CalendarEventListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.calendar_events.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "date",
                label: t("management.calendar_events.fields.date"),
                name: ["date"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "durationInMinutes",
                label: t("management.calendar_events.fields.durationInMinutes"),
                name: ["durationInMinutes"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "invitedEmails",
                label: t("management.calendar_events.fields.invitedEmails"),
                name: ["invitedEmails"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "meeting",
                label: t("management.calendar_events.fields.meeting"),
                name: ["meeting"],
                columns: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.calendar_events.fields.meeting.id"),
                        name: ["meeting", "id"],
                        component: Ant.Input,
                    },
                    {
                        id: "joinUrl",
                        label: t("management.calendar_events.fields.meeting.joinUrl"),
                        name: ["meeting", "joinUrl"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "password",
                        label: t("management.calendar_events.fields.meeting.password"),
                        name: ["meeting", "password"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.calendar_events.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.calendar_events.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.calendar_events.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.calendar_events.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "invitedTeamMembersIds",
                label: t("management.calendar_events.fields.invitedTeamMembers"),
                name: ["invitedTeamMembersIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TeamMembersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "calendarId",
                label: t("management.calendar_events.fields.calendar"),
                name: ["calendarId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CalendarsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
CalendarEventListFiltersForm = __decorate([
    Service({ transient: true })
], CalendarEventListFiltersForm);
export { CalendarEventListFiltersForm };
