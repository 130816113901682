var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { UsersCollection, TrainingPlanCompletedCoursesCollection, } from "@bundles/UIAppBundle/collections";
let TrainingPlanCompletedCourseCreateForm = class TrainingPlanCompletedCourseCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "action_items",
                label: t("management.training_plan_completed_courses.fields.action_items"),
                name: ["action_items"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "sections",
                label: t("management.training_plan_completed_courses.fields.sections"),
                name: ["sections"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "userId",
                label: t("management.training_plan_completed_courses.fields.user"),
                name: ["userId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.training_plan_completed_courses.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.TRAINING_PLAN_COMPLETED_COURSES_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.TRAINING_PLAN_COMPLETED_COURSES_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.TRAINING_PLAN_COMPLETED_COURSES_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TrainingPlanCompletedCoursesCollection),
    __metadata("design:type", typeof (_a = typeof TrainingPlanCompletedCoursesCollection !== "undefined" && TrainingPlanCompletedCoursesCollection) === "function" ? _a : Object)
], TrainingPlanCompletedCourseCreateForm.prototype, "collection", void 0);
TrainingPlanCompletedCourseCreateForm = __decorate([
    Service({ transient: true })
], TrainingPlanCompletedCourseCreateForm);
export { TrainingPlanCompletedCourseCreateForm };
