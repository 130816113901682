var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { PermissionRenderer } from "../components/Renderers/PermissionRenderer";
import { TeamMemberCreateForm as BaseTeamMemberCreateForm } from "./TeamMemberCreateForm.base";
let TeamMemberCreateForm = class TeamMemberCreateForm extends BaseTeamMemberCreateForm {
    build() {
        super.build();
        const { t } = this.i18n;
        // Perform additional modifications such as updating rendering functions, labels, description
        this.remove("permissions");
        this.add({
            id: "permissions",
            label: t("management.team_members.fields.permissions"),
            name: ["permissions"],
            required: true,
            render: (props) => _jsx(PermissionRenderer, Object.assign({}, props), void 0),
        });
    }
};
TeamMemberCreateForm = __decorate([
    Service({ transient: true })
], TeamMemberCreateForm);
export { TeamMemberCreateForm };
