import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ant from "antd";
import * as React from "react";
import * as debounce from "lodash.debounce";
import { use, useTranslate } from "@bluelibs/x-ui";
import { TrainingPlanSectionListFiltersForm } from "../../config/TrainingPlanSectionListFiltersForm";
export const TrainingPlanSectionsListFilters = React.memo(TrainingPlanSectionsListFiltersBase);
export function TrainingPlanSectionsListFiltersBase(props) {
    const [form] = Ant.Form.useForm();
    const t = useTranslate();
    const debouncedFilterUpdates = React.useMemo(() => {
        const setFilters = (_, filters) => {
            props.onUpdate(filters);
        };
        return debounce(setFilters, 500);
    }, [props.onUpdate]);
    const filterForm = use(TrainingPlanSectionListFiltersForm, {
        transient: true,
    });
    filterForm.build();
    return (_jsxs(Ant.Form, Object.assign({ form: form, labelCol: { span: 24 }, onValuesChange: debouncedFilterUpdates }, { children: [_jsx(Ant.Row, Object.assign({ gutter: [16, 8] }, { children: filterForm.rest().map((item) => {
                    return (_jsx(Ant.Col, Object.assign({ span: 8 }, { children: filterForm.render(item) }), item.id));
                }) }), void 0), _jsx(Ant.Form.Item, { children: _jsx(Ant.Button, Object.assign({ htmlType: "button", onClick: () => {
                        form.resetFields();
                        props.onUpdate({});
                    } }, { children: t("generics.list_filters_reset") }), void 0) }, void 0)] }), void 0));
}
