var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { UsersCollection, ImagesCollection, CountriesCollection, TimezonesCollection, BusinessSubscriptionsCollection, BusinessAddonsCollection, CalendarsCollection, TodosListsCollection, } from "@bundles/UIAppBundle/collections";
let BusinessListFiltersForm = class BusinessListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.businesses.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "address",
                label: t("management.businesses.fields.address"),
                name: ["address"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "description",
                label: t("management.businesses.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "phoneNumber",
                label: t("management.businesses.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "socialMediaLinks",
                label: t("management.businesses.fields.socialMediaLinks"),
                name: ["socialMediaLinks"],
                columns: true,
                nest: [
                    {
                        id: "facebook",
                        label: t("management.businesses.fields.socialMediaLinks.facebook"),
                        name: ["socialMediaLinks", "facebook"],
                        component: Ant.Input,
                    },
                    {
                        id: "twitter",
                        label: t("management.businesses.fields.socialMediaLinks.twitter"),
                        name: ["socialMediaLinks", "twitter"],
                        component: Ant.Input,
                    },
                    {
                        id: "linkedin",
                        label: t("management.businesses.fields.socialMediaLinks.linkedin"),
                        name: ["socialMediaLinks", "linkedin"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "accountPaused",
                label: t("management.businesses.fields.accountPaused"),
                name: ["accountPaused"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "numberOfCredits",
                label: t("management.businesses.fields.numberOfCredits"),
                name: ["numberOfCredits"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "stripeCustomerId",
                label: t("management.businesses.fields.stripeCustomerId"),
                name: ["stripeCustomerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "isOnboarded",
                label: t("management.businesses.fields.isOnboarded"),
                name: ["isOnboarded"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.businesses.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.businesses.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.businesses.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.businesses.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "ownerId",
                label: t("management.businesses.fields.owner"),
                name: ["ownerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.businesses.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "countryId",
                label: t("management.businesses.fields.country"),
                name: ["countryId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CountriesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "timezoneId",
                label: t("management.businesses.fields.timezone"),
                name: ["timezoneId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TimezonesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "subscriptionId",
                label: t("management.businesses.fields.subscription"),
                name: ["subscriptionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessSubscriptionsCollection, field: "stripeSubscriptionId", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "addonIds",
                label: t("management.businesses.fields.addon"),
                name: ["addonIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessAddonsCollection, field: "stripeAddonSubscriptionId", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "calendarId",
                label: t("management.businesses.fields.calendar"),
                name: ["calendarId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CalendarsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.businesses.fields.todosList"),
                name: ["todosListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
BusinessListFiltersForm = __decorate([
    Service({ transient: true })
], BusinessListFiltersForm);
export { BusinessListFiltersForm };
