import "./i18n";
import { CALENDARS_LIST as BASE_CALENDARS_LIST, CALENDARS_CREATE as BASE_CALENDARS_CREATE, CALENDARS_EDIT as BASE_CALENDARS_EDIT, CALENDARS_VIEW as BASE_CALENDARS_VIEW, } from "./config/routes";
export const CALENDARS_LIST = {
    ...BASE_CALENDARS_LIST,
};
export const CALENDARS_CREATE = {
    ...BASE_CALENDARS_CREATE,
};
export const CALENDARS_EDIT = {
    ...BASE_CALENDARS_EDIT,
};
export const CALENDARS_VIEW = {
    ...BASE_CALENDARS_VIEW,
};
