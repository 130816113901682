var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { CommentsCollection } from "@bundles/UIAppBundle/collections";
let CommentEditForm = class CommentEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isMainComment",
                label: t("management.comments.fields.isMainComment"),
                name: ["isMainComment"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "comment",
                label: t("management.comments.fields.comment"),
                name: ["comment"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "commenterId",
                label: t("management.comments.fields.commenterId"),
                name: ["commenterId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "commentIds",
                label: t("management.comments.fields.commentIds"),
                name: ["commentIds"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "likerIds",
                label: t("management.comments.fields.likerIds"),
                name: ["likerIds"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "isApproved",
                label: t("management.comments.fields.isApproved"),
                name: ["isApproved"],
                required: true,
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            isMainComment: 1,
            comment: 1,
            commenterId: 1,
            commentIds: 1,
            likerIds: 1,
            isApproved: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.comments.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CommentsCollection),
    __metadata("design:type", typeof (_a = typeof CommentsCollection !== "undefined" && CommentsCollection) === "function" ? _a : Object)
], CommentEditForm.prototype, "collection", void 0);
CommentEditForm = __decorate([
    Service({ transient: true })
], CommentEditForm);
export { CommentEditForm };
