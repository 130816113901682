import { TodosListsList } from "../components/List/TodosListsList";
import { TodosListsCreate } from "../components/Create/TodosListsCreate";
import { TodosListsEdit } from "../components/Edit/TodosListsEdit";
import { TodosListsView } from "../components/View/TodosListsView";
import { SettingFilled } from "@ant-design/icons";
export const TODOS_LISTS_LIST = {
    path: "/admin/todos-lists",
    component: TodosListsList,
    menu: {
        key: "TODOS_LISTS_LIST",
        label: "management.todos_lists.menu.title",
        icon: SettingFilled,
    },
};
export const TODOS_LISTS_CREATE = {
    path: "/admin/todos-lists/create",
    component: TodosListsCreate,
};
export const TODOS_LISTS_EDIT = {
    path: "/admin/todos-lists/:id/edit",
    component: TodosListsEdit,
};
export const TODOS_LISTS_VIEW = {
    path: "/admin/todos-lists/:id/view",
    component: TodosListsView,
};
