var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ImagesCollection, ServiceItemsCollection, } from "@bundles/UIAppBundle/collections";
let ResourceListFiltersForm = class ResourceListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.resources.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "description",
                label: t("management.resources.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "types",
                label: t("management.resources.fields.types"),
                name: ["types"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.resources.fields.types") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "SCRIPTS" }, { children: "Scripts" }), "SCRIPTS"), _jsx(Ant.Select.Option, Object.assign({ value: "CONTRACTS" }, { children: "Contracts" }), "CONTRACTS"), _jsx(Ant.Select.Option, Object.assign({ value: "PROSPECTING" }, { children: "Prospecting" }), "PROSPECTING"), _jsx(Ant.Select.Option, Object.assign({ value: "SALES_AND_PRESENTATION" }, { children: "Sales And Presentation" }), "SALES_AND_PRESENTATION"), _jsx(Ant.Select.Option, Object.assign({ value: "CASE_STUDIES" }, { children: "Case Studies" }), "CASE_STUDIES"), _jsx(Ant.Select.Option, Object.assign({ value: "EXPLAINER_VIDEOS" }, { children: "Explainer Videos" }), "EXPLAINER_VIDEOS"), _jsx(Ant.Select.Option, Object.assign({ value: "PROPOSALS_AND_ONBOARDING" }, { children: "Proposals And Onboarding" }), "PROPOSALS_AND_ONBOARDING"), _jsx(Ant.Select.Option, Object.assign({ value: "OPERATIONS_AND_SCALE" }, { children: "Operations And Scale" }), "OPERATIONS_AND_SCALE")] }), void 0) }), void 0)),
            },
            {
                id: "industries",
                label: t("management.resources.fields.industries"),
                name: ["industries"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.resources.fields.industries") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "ATTORNEYS" }, { children: "Attorneys" }), "ATTORNEYS"), _jsx(Ant.Select.Option, Object.assign({ value: "AUTOMOTIVE" }, { children: "Automotive" }), "AUTOMOTIVE"), _jsx(Ant.Select.Option, Object.assign({ value: "DOCTOR" }, { children: "Doctor" }), "DOCTOR"), _jsx(Ant.Select.Option, Object.assign({ value: "ECOMMERCE" }, { children: "Ecommerce" }), "ECOMMERCE"), _jsx(Ant.Select.Option, Object.assign({ value: "ENTERTAINMENT" }, { children: "Entertainment" }), "ENTERTAINMENT"), _jsx(Ant.Select.Option, Object.assign({ value: "FITNESS" }, { children: "Fitness" }), "FITNESS"), _jsx(Ant.Select.Option, Object.assign({ value: "FOOD" }, { children: "Food" }), "FOOD"), _jsx(Ant.Select.Option, Object.assign({ value: "HEALTH_WELLNESS_SUPPLEMENTS" }, { children: "Health Wellness Supplements" }), "HEALTH_WELLNESS_SUPPLEMENTS"), _jsx(Ant.Select.Option, Object.assign({ value: "MARKETING_AGENCY" }, { children: "Marketing Agency" }), "MARKETING_AGENCY"), _jsx(Ant.Select.Option, Object.assign({ value: "REAL_ESTATE" }, { children: "Real Estate" }), "REAL_ESTATE"), _jsx(Ant.Select.Option, Object.assign({ value: "SALONS" }, { children: "Salons" }), "SALONS"), _jsx(Ant.Select.Option, Object.assign({ value: "TRADE_SERVICES" }, { children: "Trade Services" }), "TRADE_SERVICES")] }), void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.resources.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.resources.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.resources.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.resources.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.resources.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "recommendedForServicesIds",
                label: t("management.resources.fields.recommendedForServices"),
                name: ["recommendedForServicesIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ServiceItemsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ResourceListFiltersForm = __decorate([
    Service({ transient: true })
], ResourceListFiltersForm);
export { ResourceListFiltersForm };
