var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { OrderItemsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
let OrderListFiltersForm = class OrderListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "updatedAt",
                label: t("management.orders.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.orders.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.orders.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.orders.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "dueDate",
                label: t("management.orders.fields.dueDate"),
                name: ["dueDate"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "scheduleDate",
                label: t("management.orders.fields.scheduleDate"),
                name: ["scheduleDate"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "ownerId",
                label: t("management.orders.fields.ownerId"),
                name: ["ownerId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "status",
                label: t("management.orders.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "orderDate",
                label: t("management.orders.fields.orderDate"),
                name: ["orderDate"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "service",
                label: t("management.orders.fields.service"),
                name: ["service"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "package",
                label: t("management.orders.fields.package"),
                name: ["package"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "retailPrice",
                label: t("management.orders.fields.retailPrice"),
                name: ["retailPrice"],
                tooltip: t("management.orders.fields.retailPrice_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "stripeCheckoutSessionId",
                label: t("management.orders.fields.stripeCheckoutSessionId"),
                name: ["stripeCheckoutSessionId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.orders.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.orders.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "SUBSCRIPTION" }, { children: "Subscription" }), "SUBSCRIPTION"), _jsx(Ant.Select.Option, Object.assign({ value: "SERVICE_ITEM_PACKAGES" }, { children: "Service Item Packages" }), "SERVICE_ITEM_PACKAGES"), _jsx(Ant.Select.Option, Object.assign({ value: "CREDITS" }, { children: "Credits" }), "CREDITS")] }), void 0) }), void 0)),
            },
            {
                id: "orderItemsIds",
                label: t("management.orders.fields.orderItems"),
                name: ["orderItemsIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrderItemsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.orders.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
OrderListFiltersForm = __decorate([
    Service({ transient: true })
], OrderListFiltersForm);
export { OrderListFiltersForm };
