import { TeamMemberInvitesList } from "../components/List/TeamMemberInvitesList";
import { TeamMemberInvitesCreate } from "../components/Create/TeamMemberInvitesCreate";
import { TeamMemberInvitesEdit } from "../components/Edit/TeamMemberInvitesEdit";
import { TeamMemberInvitesView } from "../components/View/TeamMemberInvitesView";
import { SettingFilled } from "@ant-design/icons";
export const TEAM_MEMBER_INVITES_LIST = {
    path: "/admin/team-member-invites",
    component: TeamMemberInvitesList,
    menu: {
        key: "TEAM_MEMBER_INVITES_LIST",
        label: "management.team_member_invites.menu.title",
        icon: SettingFilled,
    },
};
export const TEAM_MEMBER_INVITES_CREATE = {
    path: "/admin/team-member-invites/create",
    component: TeamMemberInvitesCreate,
};
export const TEAM_MEMBER_INVITES_EDIT = {
    path: "/admin/team-member-invites/:id/edit",
    component: TeamMemberInvitesEdit,
};
export const TEAM_MEMBER_INVITES_VIEW = {
    path: "/admin/team-member-invites/:id/view",
    component: TeamMemberInvitesView,
};
