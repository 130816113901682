var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { ClassEventList as BaseClassEventList } from "./ClassEventList.base";
let ClassEventList = class ClassEventList extends BaseClassEventList {
    build() {
        super.build();
        // Perform additional modifications such as updating how a list item renders or add additional ones
    }
    // static getRequestBody(): QueryBodyType<ClassEvent> {
    //   // You have the ability to modify the request by adding certain fields or relations
    //   return super.getRequestBody();
    // }
    static getRequestBody() {
        const originalRequestBody = super.getRequestBody();
        // Modify originalRequestBody as needed
        delete originalRequestBody.updatedById;
        delete originalRequestBody.createdById;
        return originalRequestBody;
    }
};
ClassEventList = __decorate([
    Service({ transient: true })
], ClassEventList);
export { ClassEventList };
