import "./i18n";
import { NEWSLETTER_SUBSCRIPTIONS_LIST as BASE_NEWSLETTER_SUBSCRIPTIONS_LIST, NEWSLETTER_SUBSCRIPTIONS_CREATE as BASE_NEWSLETTER_SUBSCRIPTIONS_CREATE, NEWSLETTER_SUBSCRIPTIONS_EDIT as BASE_NEWSLETTER_SUBSCRIPTIONS_EDIT, NEWSLETTER_SUBSCRIPTIONS_VIEW as BASE_NEWSLETTER_SUBSCRIPTIONS_VIEW, } from "./config/routes";
export const NEWSLETTER_SUBSCRIPTIONS_LIST = {
    ...BASE_NEWSLETTER_SUBSCRIPTIONS_LIST,
};
export const NEWSLETTER_SUBSCRIPTIONS_CREATE = {
    ...BASE_NEWSLETTER_SUBSCRIPTIONS_CREATE,
};
export const NEWSLETTER_SUBSCRIPTIONS_EDIT = {
    ...BASE_NEWSLETTER_SUBSCRIPTIONS_EDIT,
};
export const NEWSLETTER_SUBSCRIPTIONS_VIEW = {
    ...BASE_NEWSLETTER_SUBSCRIPTIONS_VIEW,
};
