import { Collection, } from "@bluelibs/x-ui";
import { Client } from "@root/api.types";
import { NoteListsCollection, ImagesCollection, TeamMembersCollection, BusinessDetailsCollection, ProjectsCollection, TodosListsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
export class ClientsCollection extends Collection {
    getName() {
        return "Clients";
    }
    getInputs() {
        return {
            insert: "ClientInsertInput!",
            update: "ClientUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => NoteListsCollection,
                name: "noteList",
                field: "noteListId",
            },
            {
                collection: () => ImagesCollection,
                name: "avatar",
                field: "avatarId",
            },
            {
                collection: () => ImagesCollection,
                name: "cover",
                field: "coverId",
            },
            {
                collection: () => TeamMembersCollection,
                name: "assignedTeamMembers",
                many: true,
                field: "assignedTeamMembersIds",
            },
            {
                collection: () => BusinessDetailsCollection,
                name: "businessDetails",
                field: "businessDetailsId",
            },
            {
                collection: () => TodosListsCollection,
                name: "todosList",
                field: "todosListId",
            },
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
            {
                collection: () => ProjectsCollection,
                name: "projects",
                many: true,
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
