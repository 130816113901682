var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let ServiceItemPackageList = class ServiceItemPackageList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                title: t("management.service_item_packages.fields.name"),
                key: "management.service_item_packages.fields.name",
                dataIndex: ["name"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "details",
                title: t("management.service_item_packages.fields.details"),
                key: "management.service_item_packages.fields.details",
                dataIndex: ["details"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "benefits.likesPerPost",
                title: t("management.service_item_packages.fields.benefits.likesPerPost"),
                key: "management.service_item_packages.fields.benefits.likesPerPost",
                dataIndex: ["benefits", "likesPerPost"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isPrivate",
                title: t("management.service_item_packages.fields.isPrivate"),
                key: "management.service_item_packages.fields.isPrivate",
                dataIndex: ["isPrivate"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice.price",
                title: t("management.service_item_packages.fields.retailPrice.price"),
                key: "management.service_item_packages.fields.retailPrice.price",
                dataIndex: ["retailPrice", "price"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "number",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice.isMore",
                title: t("management.service_item_packages.fields.retailPrice.isMore"),
                key: "management.service_item_packages.fields.retailPrice.isMore",
                dataIndex: ["retailPrice", "isMore"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "retailPrice.per",
                title: t("management.service_item_packages.fields.retailPrice.per"),
                key: "management.service_item_packages.fields.retailPrice.per",
                dataIndex: ["retailPrice", "per"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fees.oneTime",
                title: t("management.service_item_packages.fields.fees.oneTime"),
                key: "management.service_item_packages.fields.fees.oneTime",
                dataIndex: ["fees", "oneTime"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fees.subscription",
                title: t("management.service_item_packages.fields.fees.subscription"),
                key: "management.service_item_packages.fields.fees.subscription",
                dataIndex: ["fees", "subscription"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.service_item_packages.fields.createdAt"),
                key: "management.service_item_packages.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.service_item_packages.fields.updatedAt"),
                key: "management.service_item_packages.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.service_item_packages.fields.createdById"),
                key: "management.service_item_packages.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.service_item_packages.fields.updatedById"),
                key: "management.service_item_packages.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "serviceItem",
                title: t("management.service_item_packages.fields.serviceItem"),
                key: "management.service_item_packages.fields.serviceItem",
                dataIndex: ["serviceItem"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SERVICE_ITEMS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            serviceItem: "serviceItem.name",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            details: 1,
            benefits: {
                likesPerPost: 1,
            },
            isPrivate: 1,
            retailPrice: {
                price: 1,
                isMore: 1,
                per: 1,
            },
            fees: {
                oneTime: {
                    name: 1,
                    per: {
                        type: 1,
                        granularity: {
                            min: 1,
                            max: 1,
                        },
                    },
                    price: 1,
                    stripeProductId: 1,
                    components: {
                        name: 1,
                        price: 1,
                    },
                },
                subscription: {
                    name: 1,
                    recurrency: 1,
                    price: 1,
                    stripeProductId: 1,
                },
            },
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            serviceItem: {
                _id: 1,
                name: 1,
            },
            serviceItemId: 1,
        };
    }
};
ServiceItemPackageList = __decorate([
    Service({ transient: true })
], ServiceItemPackageList);
export { ServiceItemPackageList };
