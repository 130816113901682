import { Collection, } from "@bluelibs/x-ui";
import { ServiceItem } from "@root/api.types";
import { AppFilesCollection, } from "@bluelibs/x-ui-admin";
import { ServiceItemPackagesCollection, ImagesCollection, } from "@bundles/UIAppBundle/collections";
export class ServiceItemsCollection extends Collection {
    getName() {
        return "ServiceItems";
    }
    getInputs() {
        return {
            insert: "ServiceItemInsertInput!",
            update: "ServiceItemUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => AppFilesCollection,
                name: "video",
                field: "videoId",
            },
            {
                collection: () => ServiceItemPackagesCollection,
                name: "packages",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
