import { Collection, } from "@bluelibs/x-ui";
import { MediaSpotlightItem } from "@root/api.types";
import { ImagesCollection } from "@bundles/UIAppBundle/collections";
export class MediaSpotlightItemsCollection extends Collection {
    getName() {
        return "MediaSpotlightItems";
    }
    getInputs() {
        return {
            insert: "MediaSpotlightItemInsertInput!",
            update: "MediaSpotlightItemUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
