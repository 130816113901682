import "./i18n";
import { TRAINING_PLAN_SECTIONS_LIST as BASE_TRAINING_PLAN_SECTIONS_LIST, TRAINING_PLAN_SECTIONS_CREATE as BASE_TRAINING_PLAN_SECTIONS_CREATE, TRAINING_PLAN_SECTIONS_EDIT as BASE_TRAINING_PLAN_SECTIONS_EDIT, TRAINING_PLAN_SECTIONS_VIEW as BASE_TRAINING_PLAN_SECTIONS_VIEW, } from "./config/routes";
export const TRAINING_PLAN_SECTIONS_LIST = {
    ...BASE_TRAINING_PLAN_SECTIONS_LIST,
};
export const TRAINING_PLAN_SECTIONS_CREATE = {
    ...BASE_TRAINING_PLAN_SECTIONS_CREATE,
};
export const TRAINING_PLAN_SECTIONS_EDIT = {
    ...BASE_TRAINING_PLAN_SECTIONS_EDIT,
};
export const TRAINING_PLAN_SECTIONS_VIEW = {
    ...BASE_TRAINING_PLAN_SECTIONS_VIEW,
};
