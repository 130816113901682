import { Timezone } from "@root/api.types";
import { TimezonesCollection as BaseTimezonesCollection } from "./Timezones.collection";
import { gql } from "@apollo/client";
export class TimezonesCollection extends BaseTimezonesCollection {
    async getTimezones() {
        try {
            const response = await this.apolloClient.query({
                query: gql `
            query BusinessTimezonesGet {
              BusinessTimezonesGet {
                _id
                name
                utcOffset
              }
            }
          `
            });
            return response.data.BusinessTimezonesGet;
        }
        catch (error) {
            console.error("Error fetching time zones:", error);
            throw error;
        }
    }
}
