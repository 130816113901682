var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ImagesCollection, TeamMembersCollection, NoteListsCollection, BusinessDetailsCollection, BusinessesCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
let LeadListFiltersForm = class LeadListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.leads.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "lastName",
                label: t("management.leads.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.leads.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "phoneNumber",
                label: t("management.leads.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "address",
                label: t("management.leads.fields.address"),
                name: ["address"],
                columns: true,
                nest: [
                    {
                        id: "city",
                        label: t("management.leads.fields.address.city"),
                        name: ["address", "city"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.leads.fields.address.street"),
                        name: ["address", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "state",
                        label: t("management.leads.fields.address.state"),
                        name: ["address", "state"],
                        component: Ant.Input,
                    },
                    {
                        id: "zipCode",
                        label: t("management.leads.fields.address.zipCode"),
                        name: ["address", "zipCode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "status",
                label: t("management.leads.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.leads.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "NEW" }, { children: "New" }), "NEW"), _jsx(Ant.Select.Option, Object.assign({ value: "PROPOSAL_SENT" }, { children: "Proposal Sent" }), "PROPOSAL_SENT"), _jsx(Ant.Select.Option, Object.assign({ value: "IN_NEGOTIATION" }, { children: "In Negotiation" }), "IN_NEGOTIATION")] }), void 0) }), void 0)),
            },
            {
                id: "followUp",
                label: t("management.leads.fields.followUp"),
                name: ["followUp"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.leads.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.leads.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.leads.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.leads.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "avatarId",
                label: t("management.leads.fields.avatar"),
                name: ["avatarId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "coverId",
                label: t("management.leads.fields.cover"),
                name: ["coverId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "assignedTeamMembersIds",
                label: t("management.leads.fields.assignedTeamMembers"),
                name: ["assignedTeamMembersIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TeamMembersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "noteListId",
                label: t("management.leads.fields.noteList"),
                name: ["noteListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: NoteListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessDetailsId",
                label: t("management.leads.fields.businessDetails"),
                name: ["businessDetailsId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessDetailsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.leads.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "createdById",
                label: t("management.leads.fields.createdBy"),
                name: ["createdById"],
                tooltip: t("management.leads.fields.createdBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "updatedById",
                label: t("management.leads.fields.updatedBy"),
                name: ["updatedById"],
                tooltip: t("management.leads.fields.updatedBy_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
LeadListFiltersForm = __decorate([
    Service({ transient: true })
], LeadListFiltersForm);
export { LeadListFiltersForm };
