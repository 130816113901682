var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { BusinessesCollection, } from "@bundles/UIAppBundle/collections";
let TeamMemberInviteListFiltersForm = class TeamMemberInviteListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "email",
                label: t("management.team_member_invites.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "token",
                label: t("management.team_member_invites.fields.token"),
                name: ["token"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "roles",
                label: t("management.team_member_invites.fields.roles"),
                name: ["roles"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.team_member_invites.fields.roles") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "BUSINESS" }, { children: "Business" }), "BUSINESS"), _jsx(Ant.Select.Option, Object.assign({ value: "TEAM_MEMBER" }, { children: "Team Member" }), "TEAM_MEMBER"), _jsx(Ant.Select.Option, Object.assign({ value: "CLIENT" }, { children: "Client" }), "CLIENT")] }), void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.team_member_invites.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.team_member_invites.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.team_member_invites.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.team_member_invites.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.team_member_invites.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
TeamMemberInviteListFiltersForm = __decorate([
    Service({ transient: true })
], TeamMemberInviteListFiltersForm);
export { TeamMemberInviteListFiltersForm };
