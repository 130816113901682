var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { SubscriptionBundlesCollection, SubscriptionPackagesCollection, } from "@bundles/UIAppBundle/collections";
let SubscriptionPackageEditForm = class SubscriptionPackageEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "price",
                label: t("management.subscription_packages.fields.price"),
                name: ["price"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "pricingType",
                label: t("management.subscription_packages.fields.pricingType"),
                name: ["pricingType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "MONTHLY", label: "Monthly" },
                        { value: "ANNUALLY", label: "Annually" },
                        { value: "LIFETIME", label: "Lifetime" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.subscription_packages.fields.pricingType") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.subscription_packages.fields.pricingType_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "subscriptionBundleId",
                label: t("management.subscription_packages.fields.subscriptionBundle"),
                name: ["subscriptionBundleId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SubscriptionBundlesCollection, field: "accessName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            price: 1,
            pricingType: 1,
            subscriptionBundle: {
                _id: 1,
                accessName: 1,
            },
            subscriptionBundleId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.subscription_packages.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SubscriptionPackagesCollection),
    __metadata("design:type", typeof (_a = typeof SubscriptionPackagesCollection !== "undefined" && SubscriptionPackagesCollection) === "function" ? _a : Object)
], SubscriptionPackageEditForm.prototype, "collection", void 0);
SubscriptionPackageEditForm = __decorate([
    Service({ transient: true })
], SubscriptionPackageEditForm);
export { SubscriptionPackageEditForm };
