import "./i18n";
import { TRAINING_PLAN_COMPLETED_COURSES_LIST as BASE_TRAINING_PLAN_COMPLETED_COURSES_LIST, TRAINING_PLAN_COMPLETED_COURSES_CREATE as BASE_TRAINING_PLAN_COMPLETED_COURSES_CREATE, TRAINING_PLAN_COMPLETED_COURSES_EDIT as BASE_TRAINING_PLAN_COMPLETED_COURSES_EDIT, TRAINING_PLAN_COMPLETED_COURSES_VIEW as BASE_TRAINING_PLAN_COMPLETED_COURSES_VIEW, } from "./config/routes";
export const TRAINING_PLAN_COMPLETED_COURSES_LIST = {
    ...BASE_TRAINING_PLAN_COMPLETED_COURSES_LIST,
};
export const TRAINING_PLAN_COMPLETED_COURSES_CREATE = {
    ...BASE_TRAINING_PLAN_COMPLETED_COURSES_CREATE,
};
export const TRAINING_PLAN_COMPLETED_COURSES_EDIT = {
    ...BASE_TRAINING_PLAN_COMPLETED_COURSES_EDIT,
};
export const TRAINING_PLAN_COMPLETED_COURSES_VIEW = {
    ...BASE_TRAINING_PLAN_COMPLETED_COURSES_VIEW,
};
