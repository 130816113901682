import "./i18n";
import { ADDONS_LIST as BASE_ADDONS_LIST, ADDONS_CREATE as BASE_ADDONS_CREATE, ADDONS_EDIT as BASE_ADDONS_EDIT, ADDONS_VIEW as BASE_ADDONS_VIEW, } from "./config/routes";
export const ADDONS_LIST = {
    ...BASE_ADDONS_LIST,
};
export const ADDONS_CREATE = {
    ...BASE_ADDONS_CREATE,
};
export const ADDONS_EDIT = {
    ...BASE_ADDONS_EDIT,
};
export const ADDONS_VIEW = {
    ...BASE_ADDONS_VIEW,
};
