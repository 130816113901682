import { BusinessesCollection as BaseBusinessesCollection } from "./Businesses.collection";
import { gql } from "@apollo/client";
import { ObjectId } from "@bluelibs/ejson";
import { Business } from "@root/api.types";
export class BusinessesCollection extends BaseBusinessesCollection {
    editSubscription(values, _id) {
        const data = {
            businessId: _id,
        };
        if (values.subscription !== null) {
            const subValue = values.subscription.acquiredPackage.subscriptionBundle.accessName;
            if (/\d/.test(subValue)) {
                data.targetSubscriptionPackageId = new ObjectId(subValue);
            }
        }
        if (values.address !== null) {
            data.address = values.address;
        }
        if (values.description !== null) {
            data.description = values.description;
        }
        if (values.name !== null) {
            data.name = values.name;
        }
        if (values.numberOfCredits !== null) {
            data.numberOfCredits = values.numberOfCredits;
        }
        if (values.phoneNumber !== null) {
            data.phoneNumber = values.phoneNumber;
        }
        if (values.socialMediaLinks.facebook && values.socialMediaLinks.linkedin && values.socialMediaLinks.twitter) {
            data.socialMediaLinks = values.socialMediaLinks;
        }
        if (values.timezoneId !== null) {
            data.timezoneId = new ObjectId(values.timezoneId);
        }
        if (values.todosListId !== null) {
            data.todosListId = new ObjectId(values.todosListId);
        }
        if (values.accountPaused !== null) {
            data.accountPaused = values.accountPaused;
        }
        if (values.addon !== null) {
            const addonIds = [];
            values.addon.forEach(element => {
                var _a;
                addonIds.push(new ObjectId((_a = element === null || element === void 0 ? void 0 : element.acquiredAddon) === null || _a === void 0 ? void 0 : _a._id));
            });
            data.addonIds = addonIds;
        }
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation EditSubscription($input: EditSubscriptionInput!) {
            EditSubscription(input: $input)
          }
        `,
            variables: {
                input: data,
            },
        })
            .then((response) => {
            return response.data.mutationName;
        });
    }
}
