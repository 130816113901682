var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { CalendarsCollection, } from "@bundles/UIAppBundle/collections";
let CalendarEditForm = class CalendarEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.calendars.fields.name"),
                name: ["name"],
                component: Ant.Input,
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.calendars.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CalendarsCollection),
    __metadata("design:type", typeof (_a = typeof CalendarsCollection !== "undefined" && CalendarsCollection) === "function" ? _a : Object)
], CalendarEditForm.prototype, "collection", void 0);
CalendarEditForm = __decorate([
    Service({ transient: true })
], CalendarEditForm);
export { CalendarEditForm };
