var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { NoteListsCollection, NotesCollection, } from "@bundles/UIAppBundle/collections";
let NoteEditForm = class NoteEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "text",
                label: t("management.notes.fields.text"),
                name: ["text"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "history",
                label: t("management.notes.fields.history"),
                name: ["history"],
                required: true,
                isList: true,
                nest: [
                    {
                        id: "date",
                        label: t("management.notes.fields.history.date"),
                        name: ["history", "date"],
                        required: true,
                        initialValue: [],
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
                    },
                    {
                        id: "text",
                        label: t("management.notes.fields.history.text"),
                        name: ["history", "text"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                ],
                initialValue: [],
            },
            {
                id: "noteListId",
                label: t("management.notes.fields.noteList"),
                name: ["noteListId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: NoteListsCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            text: 1,
            history: {
                date: 1,
                text: 1,
            },
            noteList: {
                _id: 1,
            },
            noteListId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.notes.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => NotesCollection),
    __metadata("design:type", typeof (_a = typeof NotesCollection !== "undefined" && NotesCollection) === "function" ? _a : Object)
], NoteEditForm.prototype, "collection", void 0);
NoteEditForm = __decorate([
    Service({ transient: true })
], NoteEditForm);
export { NoteEditForm };
