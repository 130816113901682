import { Collection, } from "@bluelibs/x-ui";
import { Course } from "@root/api.types";
import { CoursesSectionsCollection, ImagesCollection, } from "@bundles/UIAppBundle/collections";
export class CoursesCollection extends Collection {
    getName() {
        return "Courses";
    }
    getInputs() {
        return {
            insert: "CourseInsertInput!",
            update: "CourseUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => CoursesSectionsCollection,
                name: "sections",
                many: true,
                field: "sectionsIds",
            },
            {
                collection: () => ImagesCollection,
                name: "cover",
                field: "coverId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
