import "./i18n";
import { CHANNEL_MESSAGES_LIST as BASE_CHANNEL_MESSAGES_LIST, CHANNEL_MESSAGES_CREATE as BASE_CHANNEL_MESSAGES_CREATE, CHANNEL_MESSAGES_EDIT as BASE_CHANNEL_MESSAGES_EDIT, CHANNEL_MESSAGES_VIEW as BASE_CHANNEL_MESSAGES_VIEW, } from "./config/routes";
export const CHANNEL_MESSAGES_LIST = {
    ...BASE_CHANNEL_MESSAGES_LIST,
};
export const CHANNEL_MESSAGES_CREATE = {
    ...BASE_CHANNEL_MESSAGES_CREATE,
};
export const CHANNEL_MESSAGES_EDIT = {
    ...BASE_CHANNEL_MESSAGES_EDIT,
};
export const CHANNEL_MESSAGES_VIEW = {
    ...BASE_CHANNEL_MESSAGES_VIEW,
};
