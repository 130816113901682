import "./i18n";
import { TRAINING_PLAN_ACTION_ITEM_LIST as BASE_TRAINING_PLAN_ACTION_ITEM_LIST, TRAINING_PLAN_ACTION_ITEM_CREATE as BASE_TRAINING_PLAN_ACTION_ITEM_CREATE, TRAINING_PLAN_ACTION_ITEM_EDIT as BASE_TRAINING_PLAN_ACTION_ITEM_EDIT, TRAINING_PLAN_ACTION_ITEM_VIEW as BASE_TRAINING_PLAN_ACTION_ITEM_VIEW, } from "./config/routes";
export const TRAINING_PLAN_ACTION_ITEM_LIST = {
    ...BASE_TRAINING_PLAN_ACTION_ITEM_LIST,
};
export const TRAINING_PLAN_ACTION_ITEM_CREATE = {
    ...BASE_TRAINING_PLAN_ACTION_ITEM_CREATE,
};
export const TRAINING_PLAN_ACTION_ITEM_EDIT = {
    ...BASE_TRAINING_PLAN_ACTION_ITEM_EDIT,
};
export const TRAINING_PLAN_ACTION_ITEM_VIEW = {
    ...BASE_TRAINING_PLAN_ACTION_ITEM_VIEW,
};
