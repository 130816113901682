import "./i18n";
import { BUSINESSES_LIST as BASE_BUSINESSES_LIST, BUSINESSES_CREATE as BASE_BUSINESSES_CREATE, BUSINESSES_EDIT as BASE_BUSINESSES_EDIT, BUSINESSES_VIEW as BASE_BUSINESSES_VIEW, } from "./config/routes";
export const BUSINESSES_LIST = {
    ...BASE_BUSINESSES_LIST,
};
export const BUSINESSES_CREATE = {
    ...BASE_BUSINESSES_CREATE,
};
export const BUSINESSES_EDIT = {
    ...BASE_BUSINESSES_EDIT,
};
export const BUSINESSES_VIEW = {
    ...BASE_BUSINESSES_VIEW,
};
