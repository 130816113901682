import "./i18n";
import { SERVICE_ITEMS_LIST as BASE_SERVICE_ITEMS_LIST, SERVICE_ITEMS_CREATE as BASE_SERVICE_ITEMS_CREATE, SERVICE_ITEMS_EDIT as BASE_SERVICE_ITEMS_EDIT, SERVICE_ITEMS_VIEW as BASE_SERVICE_ITEMS_VIEW, } from "./config/routes";
export const SERVICE_ITEMS_LIST = {
    ...BASE_SERVICE_ITEMS_LIST,
};
export const SERVICE_ITEMS_CREATE = {
    ...BASE_SERVICE_ITEMS_CREATE,
};
export const SERVICE_ITEMS_EDIT = {
    ...BASE_SERVICE_ITEMS_EDIT,
};
export const SERVICE_ITEMS_VIEW = {
    ...BASE_SERVICE_ITEMS_VIEW,
};
