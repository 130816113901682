import { TrainingPlanSectionsList } from "../components/List/TrainingPlanSectionsList";
import { TrainingPlanSectionsCreate } from "../components/Create/TrainingPlanSectionsCreate";
import { TrainingPlanSectionsEdit } from "../components/Edit/TrainingPlanSectionsEdit";
import { TrainingPlanSectionsView } from "../components/View/TrainingPlanSectionsView";
import { SettingFilled } from "@ant-design/icons";
export const TRAINING_PLAN_SECTIONS_LIST = {
    path: "/admin/training-plan-sections",
    component: TrainingPlanSectionsList,
    menu: {
        key: "TRAINING_PLAN_SECTIONS_LIST",
        label: "management.training_plan_sections.menu.title",
        icon: SettingFilled,
    },
};
export const TRAINING_PLAN_SECTIONS_CREATE = {
    path: "/admin/training-plan-sections/create",
    component: TrainingPlanSectionsCreate,
};
export const TRAINING_PLAN_SECTIONS_EDIT = {
    path: "/admin/training-plan-sections/:id/edit",
    component: TrainingPlanSectionsEdit,
};
export const TRAINING_PLAN_SECTIONS_VIEW = {
    path: "/admin/training-plan-sections/:id/view",
    component: TrainingPlanSectionsView,
};
