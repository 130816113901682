import { Collection, } from "@bluelibs/x-ui";
import { OrderItem } from "@root/api.types";
import { OrdersCollection, ServiceItemPackagesCollection, SubscriptionBundlesCollection, CreditsPackagesCollection, } from "@bundles/UIAppBundle/collections";
export class OrderItemsCollection extends Collection {
    getName() {
        return "OrderItems";
    }
    getInputs() {
        return {
            insert: "OrderItemInsertInput!",
            update: "OrderItemUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => OrdersCollection,
                name: "order",
                field: "orderId",
            },
            {
                collection: () => ServiceItemPackagesCollection,
                name: "serviceItemPackage",
                field: "serviceItemPackageId",
            },
            {
                collection: () => SubscriptionBundlesCollection,
                name: "subscriptionPackage",
                field: "subscriptionPackageId",
            },
            {
                collection: () => CreditsPackagesCollection,
                name: "creditsPackage",
                field: "creditsPackageId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
