var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { BusinessesCollection, ImagesCollection, TodosListsCollection, UsersCollection, TeamMembersCollection, } from "@bundles/UIAppBundle/collections";
let TeamMemberEditForm = class TeamMemberEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.team_members.fields.firstName"),
                name: ["firstName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "lastName",
                label: t("management.team_members.fields.lastName"),
                name: ["lastName"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "phoneNumber",
                label: t("management.team_members.fields.phoneNumber"),
                name: ["phoneNumber"],
                component: Ant.Input,
            },
            {
                id: "email",
                label: t("management.team_members.fields.email"),
                name: ["email"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "permissions",
                label: t("management.team_members.fields.permissions"),
                name: ["permissions"],
                required: true,
                nest: [
                    {
                        id: "dashboard",
                        label: t("management.team_members.fields.permissions.dashboard"),
                        name: ["permissions", "dashboard"],
                        required: true,
                        nest: [
                            {
                                id: "analytics",
                                label: t("management.team_members.fields.permissions.dashboard.analytics"),
                                name: ["permissions", "dashboard", "analytics"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "todoList",
                                label: t("management.team_members.fields.permissions.dashboard.todoList"),
                                name: ["permissions", "dashboard", "todoList"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "dealsToClose",
                                label: t("management.team_members.fields.permissions.dashboard.dealsToClose"),
                                name: ["permissions", "dashboard", "dealsToClose"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "agency",
                        label: t("management.team_members.fields.permissions.agency"),
                        name: ["permissions", "agency"],
                        required: true,
                        nest: [
                            {
                                id: "projects",
                                label: t("management.team_members.fields.permissions.agency.projects"),
                                name: ["permissions", "agency", "projects"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "sales",
                                label: t("management.team_members.fields.permissions.agency.sales"),
                                name: ["permissions", "agency", "sales"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "calendar",
                                label: t("management.team_members.fields.permissions.agency.calendar"),
                                name: ["permissions", "agency", "calendar"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "clients",
                        label: t("management.team_members.fields.permissions.clients"),
                        name: ["permissions", "clients"],
                        required: true,
                        nest: [
                            {
                                id: "myLeads",
                                label: t("management.team_members.fields.permissions.clients.myLeads"),
                                name: ["permissions", "clients", "myLeads"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "search",
                                label: t("management.team_members.fields.permissions.clients.search"),
                                name: ["permissions", "clients", "search"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "leads",
                                label: t("management.team_members.fields.permissions.clients.leads"),
                                name: ["permissions", "clients", "leads"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "clients",
                                label: t("management.team_members.fields.permissions.clients.clients"),
                                name: ["permissions", "clients", "clients"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "campaigns",
                        label: t("management.team_members.fields.permissions.campaigns"),
                        name: ["permissions", "campaigns"],
                        required: true,
                        nest: [
                            {
                                id: "email",
                                label: t("management.team_members.fields.permissions.campaigns.email"),
                                name: ["permissions", "campaigns", "email"],
                                required: true,
                                nest: [],
                            },
                            {
                                id: "campaigns",
                                label: t("management.team_members.fields.permissions.campaigns.campaigns"),
                                name: ["permissions", "campaigns", "campaigns"],
                                required: true,
                                nest: [],
                            },
                        ],
                    },
                    {
                        id: "fulfillmentCenter",
                        label: t("management.team_members.fields.permissions.fulfillmentCenter"),
                        name: ["permissions", "fulfillmentCenter"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "learningCenter",
                        label: t("management.team_members.fields.permissions.learningCenter"),
                        name: ["permissions", "learningCenter"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "resourceCenter",
                        label: t("management.team_members.fields.permissions.resourceCenter"),
                        name: ["permissions", "resourceCenter"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "agencyStore",
                        label: t("management.team_members.fields.permissions.agencyStore"),
                        name: ["permissions", "agencyStore"],
                        required: true,
                        nest: [],
                    },
                ],
            },
            {
                id: "businessId",
                label: t("management.team_members.fields.business"),
                name: ["businessId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.team_members.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", required: false }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.team_members.fields.todosList"),
                name: ["todosListId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
            {
                id: "ownerId",
                label: t("management.team_members.fields.owner"),
                name: ["ownerId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            firstName: 1,
            lastName: 1,
            phoneNumber: 1,
            email: 1,
            permissions: {
                dashboard: {
                    analytics: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    todoList: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    dealsToClose: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                agency: {
                    projects: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    sales: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    calendar: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                clients: {
                    myLeads: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    search: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    leads: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    clients: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                campaigns: {
                    email: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    campaigns: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                fulfillmentCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                learningCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                resourceCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                agencyStore: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
            },
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.team_members.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TeamMembersCollection),
    __metadata("design:type", typeof (_a = typeof TeamMembersCollection !== "undefined" && TeamMembersCollection) === "function" ? _a : Object)
], TeamMemberEditForm.prototype, "collection", void 0);
TeamMemberEditForm = __decorate([
    Service({ transient: true })
], TeamMemberEditForm);
export { TeamMemberEditForm };
