var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { TodosListsCollection, UsersCollection, TodosCollection, } from "@bundles/UIAppBundle/collections";
let TodoEditForm = class TodoEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.todos.fields.title"),
                name: ["title"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "completed",
                label: t("management.todos.fields.completed"),
                name: ["completed"],
                required: true,
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "favourite",
                label: t("management.todos.fields.favourite"),
                name: ["favourite"],
                required: true,
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "dueDate",
                label: t("management.todos.fields.dueDate"),
                name: ["dueDate"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.todos.fields.todosList"),
                name: ["todosListId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
            {
                id: "assignedToId",
                label: t("management.todos.fields.assignedTo"),
                name: ["assignedToId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: true }, void 0) }), void 0)),
            },
            {
                id: "executedById",
                label: t("management.todos.fields.executedBy"),
                name: ["executedById"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: UsersCollection, field: "fullName", required: false }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            title: 1,
            completed: 1,
            favourite: 1,
            dueDate: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
            assignedTo: {
                _id: 1,
                fullName: 1,
            },
            assignedToId: 1,
            executedBy: {
                _id: 1,
                fullName: 1,
            },
            executedById: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.todos.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TodosCollection),
    __metadata("design:type", typeof (_a = typeof TodosCollection !== "undefined" && TodosCollection) === "function" ? _a : Object)
], TodoEditForm.prototype, "collection", void 0);
TodoEditForm = __decorate([
    Service({ transient: true })
], TodoEditForm);
export { TodoEditForm };
