var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { NoteListsCollection, ImagesCollection, TeamMembersCollection, BusinessDetailsCollection, TodosListsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
let ClientListFiltersForm = class ClientListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                label: t("management.clients.fields.firstName"),
                name: ["firstName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "lastName",
                label: t("management.clients.fields.lastName"),
                name: ["lastName"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.clients.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "phoneNumber",
                label: t("management.clients.fields.phoneNumber"),
                name: ["phoneNumber"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "address",
                label: t("management.clients.fields.address"),
                name: ["address"],
                columns: true,
                nest: [
                    {
                        id: "city",
                        label: t("management.clients.fields.address.city"),
                        name: ["address", "city"],
                        component: Ant.Input,
                    },
                    {
                        id: "street",
                        label: t("management.clients.fields.address.street"),
                        name: ["address", "street"],
                        component: Ant.Input,
                    },
                    {
                        id: "state",
                        label: t("management.clients.fields.address.state"),
                        name: ["address", "state"],
                        component: Ant.Input,
                    },
                    {
                        id: "zipCode",
                        label: t("management.clients.fields.address.zipCode"),
                        name: ["address", "zipCode"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.clients.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.clients.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.clients.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.clients.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "noteListId",
                label: t("management.clients.fields.noteList"),
                name: ["noteListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: NoteListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "avatarId",
                label: t("management.clients.fields.avatar"),
                name: ["avatarId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "coverId",
                label: t("management.clients.fields.cover"),
                name: ["coverId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "assignedTeamMembersIds",
                label: t("management.clients.fields.assignedTeamMembers"),
                name: ["assignedTeamMembersIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TeamMembersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessDetailsId",
                label: t("management.clients.fields.businessDetails"),
                name: ["businessDetailsId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessDetailsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.clients.fields.todosList"),
                name: ["todosListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.clients.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ClientListFiltersForm = __decorate([
    Service({ transient: true })
], ClientListFiltersForm);
export { ClientListFiltersForm };
