import { ChannelMessagesList } from "../components/List/ChannelMessagesList";
import { ChannelMessagesCreate } from "../components/Create/ChannelMessagesCreate";
import { ChannelMessagesEdit } from "../components/Edit/ChannelMessagesEdit";
import { ChannelMessagesView } from "../components/View/ChannelMessagesView";
import { SettingFilled } from "@ant-design/icons";
export const CHANNEL_MESSAGES_LIST = {
    path: "/admin/channel-messages",
    component: ChannelMessagesList,
    menu: {
        key: "CHANNEL_MESSAGES_LIST",
        label: "management.channel_messages.menu.title",
        icon: SettingFilled,
    },
};
export const CHANNEL_MESSAGES_CREATE = {
    path: "/admin/channel-messages/create",
    component: ChannelMessagesCreate,
};
export const CHANNEL_MESSAGES_EDIT = {
    path: "/admin/channel-messages/:id/edit",
    component: ChannelMessagesEdit,
};
export const CHANNEL_MESSAGES_VIEW = {
    path: "/admin/channel-messages/:id/view",
    component: ChannelMessagesView,
};
