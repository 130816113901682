import "./i18n";
import { BUSINESS_DETAILS_LIST as BASE_BUSINESS_DETAILS_LIST, BUSINESS_DETAILS_CREATE as BASE_BUSINESS_DETAILS_CREATE, BUSINESS_DETAILS_EDIT as BASE_BUSINESS_DETAILS_EDIT, BUSINESS_DETAILS_VIEW as BASE_BUSINESS_DETAILS_VIEW, } from "./config/routes";
export const BUSINESS_DETAILS_LIST = {
    ...BASE_BUSINESS_DETAILS_LIST,
};
export const BUSINESS_DETAILS_CREATE = {
    ...BASE_BUSINESS_DETAILS_CREATE,
};
export const BUSINESS_DETAILS_EDIT = {
    ...BASE_BUSINESS_DETAILS_EDIT,
};
export const BUSINESS_DETAILS_VIEW = {
    ...BASE_BUSINESS_DETAILS_VIEW,
};
