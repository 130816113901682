import { UsersCollection as BaseUsersCollection } from "./Users.collection";
import { gql } from "@apollo/client";
import { User } from "@root/api.types";
export class UsersCollection extends BaseUsersCollection {
    addUser(document) {
        const { _id, ...newUserDocument } = document;
        return this.apolloClient
            .mutate({
            mutation: gql `
        mutation BusinessRegisterUser($input: BusinessRegisterUserInput!) {
          BusinessRegisterUser(input: $input)
        }
      `,
            variables: {
                input: newUserDocument,
            },
        })
            .then((response) => {
            return response.data.mutationName;
        });
    }
    editUser(document) {
        return this.apolloClient
            .mutate({
            mutation: gql `
          mutation EditUser($input: EditUserInput!) {
            EditUser(input: $input)
          }
        `,
            variables: {
                input: document
            },
        })
            .then((response) => {
            return response.data.mutationName;
        });
    }
}
