import "./i18n";
import { COUNTRIES_LIST as BASE_COUNTRIES_LIST, COUNTRIES_CREATE as BASE_COUNTRIES_CREATE, COUNTRIES_EDIT as BASE_COUNTRIES_EDIT, COUNTRIES_VIEW as BASE_COUNTRIES_VIEW, } from "./config/routes";
export const COUNTRIES_LIST = {
    ...BASE_COUNTRIES_LIST,
};
export const COUNTRIES_CREATE = {
    ...BASE_COUNTRIES_CREATE,
};
export const COUNTRIES_EDIT = {
    ...BASE_COUNTRIES_EDIT,
};
export const COUNTRIES_VIEW = {
    ...BASE_COUNTRIES_VIEW,
};
