var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { ClassEventsCollection, } from "@bundles/UIAppBundle/collections";
let ClassEventCreateForm = class ClassEventCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "meeting",
                label: t("management.class_events.fields.meeting"),
                name: ["meeting"],
                required: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.class_events.fields.meeting.id"),
                        name: ["meeting", "id"],
                        component: Ant.Input,
                    },
                    {
                        id: "joinUrl",
                        label: t("management.class_events.fields.meeting.joinUrl"),
                        name: ["meeting", "joinUrl"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "password",
                        label: t("management.class_events.fields.meeting.password"),
                        name: ["meeting", "password"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "RepeatFrequency",
                label: t("management.class_events.fields.RepeatFrequency"),
                name: ["RepeatFrequency"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "WEEKLY", label: "Weekly" },
                        { value: "MONTHLY", label: "Monthly" },
                        { value: "MONTHLY_DAYWISE", label: "Monthly Daywise" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.class_events.fields.RepeatFrequency") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.class_events.fields.RepeatFrequency_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "subscriptionType",
                label: t("management.class_events.fields.subscriptionType"),
                name: ["subscriptionType"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "LITE", label: "Lite" },
                        { value: "PLUS", label: "Plus" },
                        { value: "PRO", label: "Pro" },
                        { value: "PRO_ELITE", label: "Pro Elite" },
                        { value: "PRO_PINNACLE", label: "Pro Pinnacle" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.class_events.fields.subscriptionType") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.class_events.fields.subscriptionType_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "invitedEmails",
                label: t("management.class_events.fields.invitedEmails"),
                name: ["invitedEmails"],
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "durationInMinutes",
                label: t("management.class_events.fields.durationInMinutes"),
                name: ["durationInMinutes"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "eventDays",
                label: t("management.class_events.fields.eventDays"),
                name: ["eventDays"],
                required: true,
                initialValue: [],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
                isList: true,
            },
            {
                id: "eventName",
                label: t("management.class_events.fields.eventName"),
                name: ["eventName"],
                required: true,
                component: Ant.Input,
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.class_events.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.CLASS_EVENTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.CLASS_EVENTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.CLASS_EVENTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => ClassEventsCollection),
    __metadata("design:type", typeof (_a = typeof ClassEventsCollection !== "undefined" && ClassEventsCollection) === "function" ? _a : Object)
], ClassEventCreateForm.prototype, "collection", void 0);
ClassEventCreateForm = __decorate([
    Service({ transient: true })
], ClassEventCreateForm);
export { ClassEventCreateForm };
