import { ClientsList } from "../components/List/ClientsList";
import { ClientsView } from "../components/View/ClientsView";
import { SettingFilled } from "@ant-design/icons";
export const CLIENTS_LIST = {
    path: "/admin/clients",
    component: ClientsList,
    menu: {
        key: "CLIENTS_LIST",
        label: "management.clients.menu.title",
        icon: SettingFilled,
    },
};
export const CLIENTS_CREATE = {
    path: "/admin/clients/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const CLIENTS_EDIT = {
    path: "/admin/clients/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const CLIENTS_VIEW = {
    path: "/admin/clients/:id/view",
    component: ClientsView,
};
