import "./i18n";
import { AI_ARTICLES_LIST as BASE_AI_ARTICLES_LIST, AI_ARTICLES_CREATE as BASE_AI_ARTICLES_CREATE, AI_ARTICLES_EDIT as BASE_AI_ARTICLES_EDIT, AI_ARTICLES_VIEW as BASE_AI_ARTICLES_VIEW, } from "./config/routes";
export const AI_ARTICLES_LIST = {
    ...BASE_AI_ARTICLES_LIST,
};
export const AI_ARTICLES_CREATE = {
    ...BASE_AI_ARTICLES_CREATE,
};
export const AI_ARTICLES_EDIT = {
    ...BASE_AI_ARTICLES_EDIT,
};
export const AI_ARTICLES_VIEW = {
    ...BASE_AI_ARTICLES_VIEW,
};
