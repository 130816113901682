import { NewsletterSubscriptionsList } from "../components/List/NewsletterSubscriptionsList";
import { NewsletterSubscriptionsCreate } from "../components/Create/NewsletterSubscriptionsCreate";
import { NewsletterSubscriptionsView } from "../components/View/NewsletterSubscriptionsView";
import { SettingFilled } from "@ant-design/icons";
export const NEWSLETTER_SUBSCRIPTIONS_LIST = {
    path: "/admin/newsletter-subscriptions",
    component: NewsletterSubscriptionsList,
    menu: {
        key: "NEWSLETTER_SUBSCRIPTIONS_LIST",
        label: "management.newsletter_subscriptions.menu.title",
        icon: SettingFilled,
    },
};
export const NEWSLETTER_SUBSCRIPTIONS_CREATE = {
    path: "/admin/newsletter-subscriptions/create",
    component: NewsletterSubscriptionsCreate,
};
export const NEWSLETTER_SUBSCRIPTIONS_EDIT = {
    path: "/admin/newsletter-subscriptions/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const NEWSLETTER_SUBSCRIPTIONS_VIEW = {
    path: "/admin/newsletter-subscriptions/:id/view",
    component: NewsletterSubscriptionsView,
};
