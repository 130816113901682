import { TimezonesList } from "../components/List/TimezonesList";
import { TimezonesCreate } from "../components/Create/TimezonesCreate";
import { TimezonesEdit } from "../components/Edit/TimezonesEdit";
import { TimezonesView } from "../components/View/TimezonesView";
import { SettingFilled } from "@ant-design/icons";
export const TIMEZONES_LIST = {
    path: "/admin/timezones",
    component: TimezonesList,
    menu: {
        key: "TIMEZONES_LIST",
        label: "management.timezones.menu.title",
        icon: SettingFilled,
    },
};
export const TIMEZONES_CREATE = {
    path: "/admin/timezones/create",
    component: TimezonesCreate,
};
export const TIMEZONES_EDIT = {
    path: "/admin/timezones/:id/edit",
    component: TimezonesEdit,
};
export const TIMEZONES_VIEW = {
    path: "/admin/timezones/:id/view",
    component: TimezonesView,
};
