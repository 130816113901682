import "./i18n";
import { EMAIL_CAMPAIGNS_LIST as BASE_EMAIL_CAMPAIGNS_LIST, EMAIL_CAMPAIGNS_CREATE as BASE_EMAIL_CAMPAIGNS_CREATE, EMAIL_CAMPAIGNS_EDIT as BASE_EMAIL_CAMPAIGNS_EDIT, EMAIL_CAMPAIGNS_VIEW as BASE_EMAIL_CAMPAIGNS_VIEW, } from "./config/routes";
export const EMAIL_CAMPAIGNS_LIST = {
    ...BASE_EMAIL_CAMPAIGNS_LIST,
};
export const EMAIL_CAMPAIGNS_CREATE = {
    ...BASE_EMAIL_CAMPAIGNS_CREATE,
};
export const EMAIL_CAMPAIGNS_EDIT = {
    ...BASE_EMAIL_CAMPAIGNS_EDIT,
};
export const EMAIL_CAMPAIGNS_VIEW = {
    ...BASE_EMAIL_CAMPAIGNS_VIEW,
};
