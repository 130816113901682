import { BusinessSubscriptionsList } from "../components/List/BusinessSubscriptionsList";
import { BusinessSubscriptionsView } from "../components/View/BusinessSubscriptionsView";
import { SettingFilled } from "@ant-design/icons";
export const BUSINESS_SUBSCRIPTIONS_LIST = {
    path: "/admin/business-subscriptions",
    component: BusinessSubscriptionsList,
    menu: {
        key: "BUSINESS_SUBSCRIPTIONS_LIST",
        label: "management.business_subscriptions.menu.title",
        icon: SettingFilled,
    },
};
export const BUSINESS_SUBSCRIPTIONS_CREATE = {
    path: "/admin/business-subscriptions/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const BUSINESS_SUBSCRIPTIONS_EDIT = {
    path: "/admin/business-subscriptions/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const BUSINESS_SUBSCRIPTIONS_VIEW = {
    path: "/admin/business-subscriptions/:id/view",
    component: BusinessSubscriptionsView,
};
