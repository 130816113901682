import { CouponsList } from "../components/List/CouponsList";
import { CouponsCreate } from "../components/Create/CouponsCreate";
import { CouponsEdit } from "../components/Edit/CouponsEdit";
import { CouponsView } from "../components/View/CouponsView";
import { SettingFilled } from "@ant-design/icons";
export const COUPONS_LIST = {
    path: "/admin/coupons",
    component: CouponsList,
    menu: {
        key: "COUPONS_LIST",
        label: "management.coupons.menu.title",
        icon: SettingFilled,
    },
};
export const COUPONS_CREATE = {
    path: "/admin/coupons/create",
    component: CouponsCreate,
};
export const COUPONS_EDIT = {
    path: "/admin/coupons/:id/edit",
    component: CouponsEdit,
};
export const COUPONS_VIEW = {
    path: "/admin/coupons/:id/view",
    component: CouponsView,
};
