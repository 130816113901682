import "./i18n";
import { MEDIA_SPOTLIGHT_ITEMS_LIST as BASE_MEDIA_SPOTLIGHT_ITEMS_LIST, MEDIA_SPOTLIGHT_ITEMS_CREATE as BASE_MEDIA_SPOTLIGHT_ITEMS_CREATE, MEDIA_SPOTLIGHT_ITEMS_EDIT as BASE_MEDIA_SPOTLIGHT_ITEMS_EDIT, MEDIA_SPOTLIGHT_ITEMS_VIEW as BASE_MEDIA_SPOTLIGHT_ITEMS_VIEW, } from "./config/routes";
export const MEDIA_SPOTLIGHT_ITEMS_LIST = {
    ...BASE_MEDIA_SPOTLIGHT_ITEMS_LIST,
};
export const MEDIA_SPOTLIGHT_ITEMS_CREATE = {
    ...BASE_MEDIA_SPOTLIGHT_ITEMS_CREATE,
};
export const MEDIA_SPOTLIGHT_ITEMS_EDIT = {
    ...BASE_MEDIA_SPOTLIGHT_ITEMS_EDIT,
};
export const MEDIA_SPOTLIGHT_ITEMS_VIEW = {
    ...BASE_MEDIA_SPOTLIGHT_ITEMS_VIEW,
};
