import { PostCategoriesList } from "../components/List/PostCategoriesList";
import { PostCategoriesCreate } from "../components/Create/PostCategoriesCreate";
import { PostCategoriesEdit } from "../components/Edit/PostCategoriesEdit";
import { PostCategoriesView } from "../components/View/PostCategoriesView";
import { SettingFilled } from "@ant-design/icons";
export const POST_CATEGORIES_LIST = {
    path: "/admin/post-categories",
    component: PostCategoriesList,
    menu: {
        key: "POST_CATEGORIES_LIST",
        label: "management.post_categories.menu.title",
        icon: SettingFilled,
    },
};
export const POST_CATEGORIES_CREATE = {
    path: "/admin/post-categories/create",
    component: PostCategoriesCreate,
};
export const POST_CATEGORIES_EDIT = {
    path: "/admin/post-categories/:id/edit",
    component: PostCategoriesEdit,
};
export const POST_CATEGORIES_VIEW = {
    path: "/admin/post-categories/:id/view",
    component: PostCategoriesView,
};
