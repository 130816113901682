import { CoursesList } from "../components/List/CoursesList";
import { CoursesCreate } from "../components/Create/CoursesCreate";
import { CoursesEdit } from "../components/Edit/CoursesEdit";
import { CoursesView } from "../components/View/CoursesView";
import { SettingFilled } from "@ant-design/icons";
export const COURSES_LIST = {
    path: "/admin/courses",
    component: CoursesList,
    menu: {
        key: "COURSES_LIST",
        label: "management.courses.menu.title",
        icon: SettingFilled,
    },
};
export const COURSES_CREATE = {
    path: "/admin/courses/create",
    component: CoursesCreate,
};
export const COURSES_EDIT = {
    path: "/admin/courses/:id/edit",
    component: CoursesEdit,
};
export const COURSES_VIEW = {
    path: "/admin/courses/:id/view",
    component: CoursesView,
};
