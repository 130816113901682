import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { Menu as AntdMenu } from "antd";
import { use, useGuardian, useRouter, useTranslate, useUIComponents } from "@bluelibs/x-ui";
import { MenuService } from "@bluelibs/x-ui-admin";
const AntdSubMenu = AntdMenu.SubMenu;
export function AdminMenu() {
    const menuService = use(MenuService);
    const guardian = useGuardian();
    const router = useRouter();
    const t = useTranslate();
    const Components = useUIComponents();
    if (!guardian.state.initialised) {
        return null;
    }
    const itemsThatShouldNotBeDisplayed = [
        "BUSINESS_DETAILS",
        "EMAIL_CAMPAIGNS",
        "BUSINESSES_COMPLETED_COURSE_SECTIONS",
        "CALENDAR_EVENTS",
        "CALENDARS",
        "IMAGES",
        "LEADS_LIST",
        "NOTE_LISTS",
        "NOTES",
        "ORDER_ITEMS",
        "TEAM_MEMBER_INVITES",
        "TEAM_MEMBERS",
        "TODO_LISTS",
        "TODOS",
    ];
    // We filter on each render for now because it should be super fast.
    // Otherwise we would need to do it in each MenuItem which can be cumbersome.
    const items = menuService.items.filter((item) => {
        if (itemsThatShouldNotBeDisplayed.some((x) => item.key.startsWith(x))) {
            return false;
        }
        if (item.roles) {
            return guardian.hasRole(item.roles);
        }
        return true;
    });
    // Detect which paths are active based on their logic
    const { pathname } = router.history.location;
    const selectedOrOpenKeys = getSelectedKeys(items, pathname);
    return (_jsx(AntdMenu, Object.assign({ mode: "inline", defaultOpenKeys: selectedOrOpenKeys, defaultSelectedKeys: selectedOrOpenKeys }, { children: items.map((item) => {
            return renderItem(item, router, t);
        }) }), void 0));
}
export function renderItem(item, router, t) {
    if (item.subitems && item.subitems.length) {
        return (_jsx(AntdSubMenu, Object.assign({ title: _jsx(ItemRender, { item: item, t: t }, void 0), icon: item.icon ? React.createElement(item.icon) : undefined, onTitleClick: (e) => {
                if (item.path) {
                    router.history.push(item.path);
                }
            } }, { children: item.subitems.map((subitem) => {
                return renderItem(subitem, router, t);
            }) }), item.key));
    }
    return (_jsx(AntdMenu.Item, Object.assign({ onClick: () => {
            if (item.path) {
                router.history.push(item.path);
            }
        }, icon: item.icon ? React.createElement(item.icon) : undefined }, { children: _jsx(ItemRender, { item: item, t: t }, void 0) }), item.key));
}
function ItemRender(props) {
    const { item, t } = props;
    if (typeof item.label === "string") {
        return _jsx("span", { children: t(item.label) }, void 0);
    }
    return React.createElement(item.label);
}
function getSelectedKeys(items, pathname) {
    const selectedKeys = [];
    items.forEach((item) => {
        if (item.isSelected) {
            if (item.isSelected(pathname)) {
                selectedKeys.push(item.key ? item.key : item.inject);
            }
        }
        else if (item.inject === pathname || item.key === pathname) {
            selectedKeys.push(item.key ? item.key : item.inject);
        }
        if (item.subitems) {
            const itemKeys = getSelectedKeys(item.subitems, pathname);
            selectedKeys.push(...itemKeys);
        }
    });
    return selectedKeys;
}
