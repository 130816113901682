import "./i18n";
import { TEAM_MEMBERS_LIST as BASE_TEAM_MEMBERS_LIST, TEAM_MEMBERS_CREATE as BASE_TEAM_MEMBERS_CREATE, TEAM_MEMBERS_EDIT as BASE_TEAM_MEMBERS_EDIT, TEAM_MEMBERS_VIEW as BASE_TEAM_MEMBERS_VIEW, } from "./config/routes";
export const TEAM_MEMBERS_LIST = {
    ...BASE_TEAM_MEMBERS_LIST,
};
export const TEAM_MEMBERS_CREATE = {
    ...BASE_TEAM_MEMBERS_CREATE,
};
export const TEAM_MEMBERS_EDIT = {
    ...BASE_TEAM_MEMBERS_EDIT,
};
export const TEAM_MEMBERS_VIEW = {
    ...BASE_TEAM_MEMBERS_VIEW,
};
