import "./i18n";
import { CREDITS_PACKAGES_LIST as BASE_CREDITS_PACKAGES_LIST, CREDITS_PACKAGES_CREATE as BASE_CREDITS_PACKAGES_CREATE, CREDITS_PACKAGES_EDIT as BASE_CREDITS_PACKAGES_EDIT, CREDITS_PACKAGES_VIEW as BASE_CREDITS_PACKAGES_VIEW, } from "./config/routes";
export const CREDITS_PACKAGES_LIST = {
    ...BASE_CREDITS_PACKAGES_LIST,
};
export const CREDITS_PACKAGES_CREATE = {
    ...BASE_CREDITS_PACKAGES_CREATE,
};
export const CREDITS_PACKAGES_EDIT = {
    ...BASE_CREDITS_PACKAGES_EDIT,
};
export const CREDITS_PACKAGES_VIEW = {
    ...BASE_CREDITS_PACKAGES_VIEW,
};
