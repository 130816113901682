var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let TeamMemberList = class TeamMemberList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "firstName",
                title: t("management.team_members.fields.firstName"),
                key: "management.team_members.fields.firstName",
                dataIndex: ["firstName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "lastName",
                title: t("management.team_members.fields.lastName"),
                key: "management.team_members.fields.lastName",
                dataIndex: ["lastName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phoneNumber",
                title: t("management.team_members.fields.phoneNumber"),
                key: "management.team_members.fields.phoneNumber",
                dataIndex: ["phoneNumber"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fullName",
                title: t("management.team_members.fields.fullName"),
                key: "management.team_members.fields.fullName",
                dataIndex: ["fullName"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "email",
                title: t("management.team_members.fields.email"),
                key: "management.team_members.fields.email",
                dataIndex: ["email"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.dashboard",
                title: t("management.team_members.fields.permissions.dashboard"),
                key: "management.team_members.fields.permissions.dashboard",
                dataIndex: ["permissions", "dashboard"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.agency",
                title: t("management.team_members.fields.permissions.agency"),
                key: "management.team_members.fields.permissions.agency",
                dataIndex: ["permissions", "agency"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.clients",
                title: t("management.team_members.fields.permissions.clients"),
                key: "management.team_members.fields.permissions.clients",
                dataIndex: ["permissions", "clients"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.campaigns",
                title: t("management.team_members.fields.permissions.campaigns"),
                key: "management.team_members.fields.permissions.campaigns",
                dataIndex: ["permissions", "campaigns"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.fulfillmentCenter",
                title: t("management.team_members.fields.permissions.fulfillmentCenter"),
                key: "management.team_members.fields.permissions.fulfillmentCenter",
                dataIndex: ["permissions", "fulfillmentCenter"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.learningCenter",
                title: t("management.team_members.fields.permissions.learningCenter"),
                key: "management.team_members.fields.permissions.learningCenter",
                dataIndex: ["permissions", "learningCenter"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.resourceCenter",
                title: t("management.team_members.fields.permissions.resourceCenter"),
                key: "management.team_members.fields.permissions.resourceCenter",
                dataIndex: ["permissions", "resourceCenter"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.agencyStore",
                title: t("management.team_members.fields.permissions.agencyStore"),
                key: "management.team_members.fields.permissions.agencyStore",
                dataIndex: ["permissions", "agencyStore"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                title: t("management.team_members.fields.createdAt"),
                key: "management.team_members.fields.createdAt",
                dataIndex: ["createdAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                title: t("management.team_members.fields.updatedAt"),
                key: "management.team_members.fields.updatedAt",
                dataIndex: ["updatedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.team_members.fields.createdById"),
                key: "management.team_members.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.team_members.fields.updatedById"),
                key: "management.team_members.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "business",
                title: t("management.team_members.fields.business"),
                key: "management.team_members.fields.business",
                dataIndex: ["business"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESSES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "logo",
                title: t("management.team_members.fields.logo"),
                key: "management.team_members.fields.logo",
                dataIndex: ["logo"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.IMAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "todosList",
                title: t("management.team_members.fields.todosList"),
                key: "management.team_members.fields.todosList",
                dataIndex: ["todosList"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TODOS_LISTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "owner",
                title: t("management.team_members.fields.owner"),
                key: "management.team_members.fields.owner",
                dataIndex: ["owner"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            business: "business.name",
            logo: "logo._id",
            todosList: "todosList._id",
            owner: "owner.fullName",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            firstName: 1,
            lastName: 1,
            phoneNumber: 1,
            fullName: 1,
            email: 1,
            permissions: {
                dashboard: {
                    analytics: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    todoList: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    dealsToClose: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                agency: {
                    projects: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    sales: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    calendar: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                clients: {
                    myLeads: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    search: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    leads: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    clients: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                campaigns: {
                    email: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    campaigns: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                fulfillmentCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                learningCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                resourceCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                agencyStore: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
            },
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
        };
    }
};
TeamMemberList = __decorate([
    Service({ transient: true })
], TeamMemberList);
export { TeamMemberList };
