import { TrainingPlanActionItemList } from "../components/List/TrainingPlanActionItemList";
import { TrainingPlanActionItemCreate } from "../components/Create/TrainingPlanActionItemCreate";
import { TrainingPlanActionItemEdit } from "../components/Edit/TrainingPlanActionItemEdit";
import { TrainingPlanActionItemView } from "../components/View/TrainingPlanActionItemView";
import { SettingFilled } from "@ant-design/icons";
export const TRAINING_PLAN_ACTION_ITEM_LIST = {
    path: "/admin/training-plan-action-item",
    component: TrainingPlanActionItemList,
    menu: {
        key: "TRAINING_PLAN_ACTION_ITEM_LIST",
        label: "management.training_plan_action_item.menu.title",
        icon: SettingFilled,
    },
};
export const TRAINING_PLAN_ACTION_ITEM_CREATE = {
    path: "/admin/training-plan-action-item/create",
    component: TrainingPlanActionItemCreate,
};
export const TRAINING_PLAN_ACTION_ITEM_EDIT = {
    path: "/admin/training-plan-action-item/:id/edit",
    component: TrainingPlanActionItemEdit,
};
export const TRAINING_PLAN_ACTION_ITEM_VIEW = {
    path: "/admin/training-plan-action-item/:id/view",
    component: TrainingPlanActionItemView,
};
