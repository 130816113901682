import { LeadsListsList } from "../components/List/LeadsListsList";
import { LeadsListsCreate } from "../components/Create/LeadsListsCreate";
import { LeadsListsEdit } from "../components/Edit/LeadsListsEdit";
import { LeadsListsView } from "../components/View/LeadsListsView";
import { SettingFilled } from "@ant-design/icons";
export const LEADS_LISTS_LIST = {
    path: "/admin/leads-lists",
    component: LeadsListsList,
    menu: {
        key: "LEADS_LISTS_LIST",
        label: "management.leads_lists.menu.title",
        icon: SettingFilled,
    },
};
export const LEADS_LISTS_CREATE = {
    path: "/admin/leads-lists/create",
    component: LeadsListsCreate,
};
export const LEADS_LISTS_EDIT = {
    path: "/admin/leads-lists/:id/edit",
    component: LeadsListsEdit,
};
export const LEADS_LISTS_VIEW = {
    path: "/admin/leads-lists/:id/view",
    component: LeadsListsView,
};
