import { Collection, } from "@bluelibs/x-ui";
import { TodosList } from "@root/api.types";
import { TodosCollection, BusinessesCollection, TeamMembersCollection, } from "@bundles/UIAppBundle/collections";
export class TodosListsCollection extends Collection {
    getName() {
        return "TodosLists";
    }
    getInputs() {
        return {
            insert: "TodosListInsertInput!",
            update: "TodosListUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
            {
                collection: () => TeamMembersCollection,
                name: "teamMember",
                field: "teamMemberId",
            },
            {
                collection: () => TodosCollection,
                name: "todos",
                many: true,
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
