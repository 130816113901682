import { Collection, } from "@bluelibs/x-ui";
import { SubscriptionPackage } from "@root/api.types";
import { SubscriptionBundlesCollection } from "@bundles/UIAppBundle/collections";
export class SubscriptionPackagesCollection extends Collection {
    getName() {
        return "SubscriptionPackages";
    }
    getInputs() {
        return {
            insert: "SubscriptionPackageInsertInput!",
            update: "SubscriptionPackageUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => SubscriptionBundlesCollection,
                name: "subscriptionBundle",
                field: "subscriptionBundleId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
