import { SubscriptionPackagesCollection as BaseSubscriptionPackagesCollection } from "./SubscriptionPackages.collection";
import { gql } from "@apollo/client";
import { SubscriptionPackage } from "@root/api.types";
export class SubscriptionPackagesCollection extends BaseSubscriptionPackagesCollection {
    async getSubscriptions() {
        try {
            const response = await this.apolloClient.query({
                query: gql `
          query GetSubscriptions {
            GetSubscriptions {
              _id
              pricingType
              accessName
            }
          }
        `,
            });
            return response.data.GetSubscriptions;
        }
        catch (error) {
            console.error("Error fetching subscriptions:", error);
            throw error;
        }
    }
}
