import { Collection, } from "@bluelibs/x-ui";
import { EmailCampaign } from "@root/api.types";
import { BusinessesCollection } from "@bundles/UIAppBundle/collections";
export class EmailCampaignsCollection extends Collection {
    getName() {
        return "EmailCampaigns";
    }
    getInputs() {
        return {
            insert: "EmailCampaignInsertInput!",
            update: "EmailCampaignUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
