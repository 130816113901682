var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let TrainingPlanActionItemListFiltersForm = class TrainingPlanActionItemListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.training_plan_action_item.fields.title"),
                name: ["title"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "text",
                label: t("management.training_plan_action_item.fields.text"),
                name: ["text"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "description",
                label: t("management.training_plan_action_item.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "videoUrl",
                label: t("management.training_plan_action_item.fields.videoUrl"),
                name: ["videoUrl"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "blogUrl",
                label: t("management.training_plan_action_item.fields.blogUrl"),
                name: ["blogUrl"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "iframeLink",
                label: t("management.training_plan_action_item.fields.iframeLink"),
                name: ["iframeLink"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.training_plan_action_item.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.training_plan_action_item.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "TEXT" }, { children: "Text" }), "TEXT"), _jsx(Ant.Select.Option, Object.assign({ value: "VIDEO" }, { children: "Video" }), "VIDEO"), _jsx(Ant.Select.Option, Object.assign({ value: "BLOG" }, { children: "Blog" }), "BLOG"), _jsx(Ant.Select.Option, Object.assign({ value: "IFRAME" }, { children: "Iframe" }), "IFRAME")] }), void 0) }), void 0)),
            },
            {
                id: "duration",
                label: t("management.training_plan_action_item.fields.duration"),
                name: ["duration"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.training_plan_action_item.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.training_plan_action_item.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.training_plan_action_item.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.training_plan_action_item.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
        ]);
    }
};
TrainingPlanActionItemListFiltersForm = __decorate([
    Service({ transient: true })
], TrainingPlanActionItemListFiltersForm);
export { TrainingPlanActionItemListFiltersForm };
