var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { BusinessesCollection, } from "@bundles/UIAppBundle/collections";
let EmailCampaignListFiltersForm = class EmailCampaignListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "clientId",
                label: t("management.email_campaigns.fields.clientId"),
                name: ["clientId"],
                tooltip: t("management.email_campaigns.fields.clientId_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "campaigns",
                label: t("management.email_campaigns.fields.campaigns"),
                name: ["campaigns"],
                columns: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.email_campaigns.fields.campaigns.id"),
                        name: ["campaigns", "id"],
                        required: true,
                        initialValue: [],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "businessId",
                label: t("management.email_campaigns.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
EmailCampaignListFiltersForm = __decorate([
    Service({ transient: true })
], EmailCampaignListFiltersForm);
export { EmailCampaignListFiltersForm };
