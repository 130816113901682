import { EmailCampaignsList } from "../components/List/EmailCampaignsList";
import { EmailCampaignsCreate } from "../components/Create/EmailCampaignsCreate";
import { EmailCampaignsEdit } from "../components/Edit/EmailCampaignsEdit";
import { EmailCampaignsView } from "../components/View/EmailCampaignsView";
import { SettingFilled } from "@ant-design/icons";
export const EMAIL_CAMPAIGNS_LIST = {
    path: "/admin/email-campaigns",
    component: EmailCampaignsList,
    menu: {
        key: "EMAIL_CAMPAIGNS_LIST",
        label: "management.email_campaigns.menu.title",
        icon: SettingFilled,
    },
};
export const EMAIL_CAMPAIGNS_CREATE = {
    path: "/admin/email-campaigns/create",
    component: EmailCampaignsCreate,
};
export const EMAIL_CAMPAIGNS_EDIT = {
    path: "/admin/email-campaigns/:id/edit",
    component: EmailCampaignsEdit,
};
export const EMAIL_CAMPAIGNS_VIEW = {
    path: "/admin/email-campaigns/:id/view",
    component: EmailCampaignsView,
};
