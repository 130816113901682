import { ProjectsList } from "../components/List/ProjectsList";
import { ProjectsView } from "../components/View/ProjectsView";
import { SettingFilled } from "@ant-design/icons";
export const PROJECTS_LIST = {
    path: "/admin/projects",
    component: ProjectsList,
    menu: {
        key: "PROJECTS_LIST",
        label: "management.projects.menu.title",
        icon: SettingFilled,
    },
};
export const PROJECTS_CREATE = {
    path: "/admin/projects/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const PROJECTS_EDIT = {
    path: "/admin/projects/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const PROJECTS_VIEW = {
    path: "/admin/projects/:id/view",
    component: ProjectsView,
};
