import "./i18n";
import { POST_CATEGORIES_LIST as BASE_POST_CATEGORIES_LIST, POST_CATEGORIES_CREATE as BASE_POST_CATEGORIES_CREATE, POST_CATEGORIES_EDIT as BASE_POST_CATEGORIES_EDIT, POST_CATEGORIES_VIEW as BASE_POST_CATEGORIES_VIEW, } from "./config/routes";
export const POST_CATEGORIES_LIST = {
    ...BASE_POST_CATEGORIES_LIST,
};
export const POST_CATEGORIES_CREATE = {
    ...BASE_POST_CATEGORIES_CREATE,
};
export const POST_CATEGORIES_EDIT = {
    ...BASE_POST_CATEGORIES_EDIT,
};
export const POST_CATEGORIES_VIEW = {
    ...BASE_POST_CATEGORIES_VIEW,
};
