var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { TrainingPlanActionItemCollection, TrainingPlanSectionsCollection, } from "@bundles/UIAppBundle/collections";
let TrainingPlanSectionEditForm = class TrainingPlanSectionEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.training_plan_sections.fields.title"),
                name: ["title"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.training_plan_sections.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "action_itemsIds",
                label: t("management.training_plan_sections.fields.action_items"),
                name: ["action_itemsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TrainingPlanActionItemCollection, field: "_id", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            title: 1,
            description: 1,
            action_items: {
                _id: 1,
            },
            action_itemsIds: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.training_plan_sections.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TrainingPlanSectionsCollection),
    __metadata("design:type", typeof (_a = typeof TrainingPlanSectionsCollection !== "undefined" && TrainingPlanSectionsCollection) === "function" ? _a : Object)
], TrainingPlanSectionEditForm.prototype, "collection", void 0);
TrainingPlanSectionEditForm = __decorate([
    Service({ transient: true })
], TrainingPlanSectionEditForm);
export { TrainingPlanSectionEditForm };
