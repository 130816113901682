var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { Inject, Service } from "@bluelibs/core";
import { ClassEventCreateForm as BaseClassEventCreateForm } from "./ClassEventCreateForm.base";
import * as Ant from "antd";
import { TimezonesCollection } from "@bundles/UIAppBundle/collections";
import { useEffect, useState } from "react";
import { SmileOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
let ClassEventCreateForm = class ClassEventCreateForm extends BaseClassEventCreateForm {
    build() {
        super.build();
        // const { UIComponents } = this;
        this.add([
            {
                id: "timezone",
                label: "Timezone",
                name: ["timezone"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const [options, setOptions] = useState([]);
                    useEffect(() => {
                        this.timezonesCollection.getTimezones().then((timezones) => {
                            const mappedOptions = timezones.map((timezone) => ({
                                key: timezone._id,
                                label: timezone.name,
                                value: timezone.name
                            }));
                            setOptions(mappedOptions);
                        });
                    }, []);
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, { placeholder: "Timezone", showSearch: true, optionFilterProp: "children", filterOption: (input, option) => { var _a; return ((_a = option === null || option === void 0 ? void 0 : option.label) !== null && _a !== void 0 ? _a : '').includes(input); }, filterSort: (optionA, optionB) => { var _a, _b; return ((_a = optionA === null || optionA === void 0 ? void 0 : optionA.label) !== null && _a !== void 0 ? _a : '').toLowerCase().localeCompare(((_b = optionB === null || optionB === void 0 ? void 0 : optionB.label) !== null && _b !== void 0 ? _b : '').toLowerCase()); }, options: options }, void 0) }), void 0));
                }
            }
        ]);
        const { t } = this.i18n;
        const eventDaysID = "eventDays";
        const eventDaysData = {
            id: "eventDays",
            label: t("management.class_events.fields.eventDays"),
            name: ["eventDays"],
            required: true,
            initialValue: [],
            render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker, { showTime: true }, void 0) }), void 0)),
            isList: true,
        };
        this.update(eventDaysID, eventDaysData);
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    async onSubmit(document) {
        const { t } = this.i18n;
        return this.collection.
            addClassEvent(document)
            .then(() => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.class_events.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            return this.router.go(Routes.CLASS_EVENTS_LIST);
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TimezonesCollection),
    __metadata("design:type", typeof (_a = typeof TimezonesCollection !== "undefined" && TimezonesCollection) === "function" ? _a : Object)
], ClassEventCreateForm.prototype, "timezonesCollection", void 0);
ClassEventCreateForm = __decorate([
    Service({ transient: true })
], ClassEventCreateForm);
export { ClassEventCreateForm };
