import { BusinessesList } from "../components/List/BusinessesList";
import { BusinessesEdit } from "../components/Edit/BusinessesEdit";
import { BusinessesView } from "../components/View/BusinessesView";
import { SettingFilled } from "@ant-design/icons";
export const BUSINESSES_LIST = {
    path: "/admin/businesses",
    component: BusinessesList,
    menu: {
        key: "BUSINESSES_LIST",
        label: "management.businesses.menu.title",
        icon: SettingFilled,
    },
};
export const BUSINESSES_CREATE = {
    path: "/admin/businesses/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const BUSINESSES_EDIT = {
    path: "/admin/businesses/:id/edit",
    component: BusinessesEdit,
};
export const BUSINESSES_VIEW = {
    path: "/admin/businesses/:id/view",
    component: BusinessesView,
};
