import { AiArticlesList } from "../components/List/AiArticlesList";
import { AiArticlesView } from "../components/View/AiArticlesView";
import { SettingFilled } from "@ant-design/icons";
export const AI_ARTICLES_LIST = {
    path: "/admin/ai-articles",
    component: AiArticlesList,
    menu: {
        key: "AI_ARTICLES_LIST",
        label: "management.ai_articles.menu.title",
        icon: SettingFilled,
    },
};
export const AI_ARTICLES_CREATE = {
    path: "/admin/ai-articles/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const AI_ARTICLES_EDIT = {
    path: "/admin/ai-articles/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const AI_ARTICLES_VIEW = {
    path: "/admin/ai-articles/:id/view",
    component: AiArticlesView,
};
