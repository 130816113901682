import "./i18n";
import { TICKETS_LIST as BASE_TICKETS_LIST, TICKETS_CREATE as BASE_TICKETS_CREATE, TICKETS_EDIT as BASE_TICKETS_EDIT, TICKETS_VIEW as BASE_TICKETS_VIEW, } from "./config/routes";
export const TICKETS_LIST = {
    ...BASE_TICKETS_LIST,
};
export const TICKETS_CREATE = {
    ...BASE_TICKETS_CREATE,
};
export const TICKETS_EDIT = {
    ...BASE_TICKETS_EDIT,
};
export const TICKETS_VIEW = {
    ...BASE_TICKETS_VIEW,
};
