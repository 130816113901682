import "./i18n";
import { NOTE_LISTS_LIST as BASE_NOTE_LISTS_LIST, NOTE_LISTS_CREATE as BASE_NOTE_LISTS_CREATE, NOTE_LISTS_EDIT as BASE_NOTE_LISTS_EDIT, NOTE_LISTS_VIEW as BASE_NOTE_LISTS_VIEW, } from "./config/routes";
export const NOTE_LISTS_LIST = {
    ...BASE_NOTE_LISTS_LIST,
};
export const NOTE_LISTS_CREATE = {
    ...BASE_NOTE_LISTS_CREATE,
};
export const NOTE_LISTS_EDIT = {
    ...BASE_NOTE_LISTS_EDIT,
};
export const NOTE_LISTS_VIEW = {
    ...BASE_NOTE_LISTS_VIEW,
};
