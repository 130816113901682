import { Collection, } from "@bluelibs/x-ui";
import { User } from "@root/api.types";
import { BusinessesCollection, ImagesCollection, } from "@bundles/UIAppBundle/collections";
export class UsersCollection extends Collection {
    getName() {
        return "Users";
    }
    getInputs() {
        return {
            insert: "UserInsertInput!",
            update: "UserUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => UsersCollection,
                name: "createdBy",
                field: "createdById",
            },
            {
                collection: () => UsersCollection,
                name: "updatedBy",
                field: "updatedById",
            },
            {
                collection: () => ImagesCollection,
                name: "profilePhoto",
                field: "profilePhotoId",
            },
            {
                collection: () => BusinessesCollection,
                name: "business",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
