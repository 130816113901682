import "./i18n";
import { COMMENTS_LIST as BASE_COMMENTS_LIST, COMMENTS_CREATE as BASE_COMMENTS_CREATE, COMMENTS_EDIT as BASE_COMMENTS_EDIT, COMMENTS_VIEW as BASE_COMMENTS_VIEW, } from "./config/routes";
export const COMMENTS_LIST = {
    ...BASE_COMMENTS_LIST,
};
export const COMMENTS_CREATE = {
    ...BASE_COMMENTS_CREATE,
};
export const COMMENTS_EDIT = {
    ...BASE_COMMENTS_EDIT,
};
export const COMMENTS_VIEW = {
    ...BASE_COMMENTS_VIEW,
};
