import "./i18n";
import { CLIENTS_LIST as BASE_CLIENTS_LIST, CLIENTS_CREATE as BASE_CLIENTS_CREATE, CLIENTS_EDIT as BASE_CLIENTS_EDIT, CLIENTS_VIEW as BASE_CLIENTS_VIEW, } from "./config/routes";
export const CLIENTS_LIST = {
    ...BASE_CLIENTS_LIST,
};
export const CLIENTS_CREATE = {
    ...BASE_CLIENTS_CREATE,
};
export const CLIENTS_EDIT = {
    ...BASE_CLIENTS_EDIT,
};
export const CLIENTS_VIEW = {
    ...BASE_CLIENTS_VIEW,
};
