import { MediaSpotlightItemsList } from "../components/List/MediaSpotlightItemsList";
import { MediaSpotlightItemsCreate } from "../components/Create/MediaSpotlightItemsCreate";
import { MediaSpotlightItemsEdit } from "../components/Edit/MediaSpotlightItemsEdit";
import { MediaSpotlightItemsView } from "../components/View/MediaSpotlightItemsView";
import { SettingFilled } from "@ant-design/icons";
export const MEDIA_SPOTLIGHT_ITEMS_LIST = {
    path: "/admin/media-spotlight-items",
    component: MediaSpotlightItemsList,
    menu: {
        key: "MEDIA_SPOTLIGHT_ITEMS_LIST",
        label: "management.media_spotlight_items.menu.title",
        icon: SettingFilled,
    },
};
export const MEDIA_SPOTLIGHT_ITEMS_CREATE = {
    path: "/admin/media-spotlight-items/create",
    component: MediaSpotlightItemsCreate,
};
export const MEDIA_SPOTLIGHT_ITEMS_EDIT = {
    path: "/admin/media-spotlight-items/:id/edit",
    component: MediaSpotlightItemsEdit,
};
export const MEDIA_SPOTLIGHT_ITEMS_VIEW = {
    path: "/admin/media-spotlight-items/:id/view",
    component: MediaSpotlightItemsView,
};
