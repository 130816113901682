var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ClientsCollection, OrdersCollection, TeamMembersCollection, ImagesCollection, TodosListsCollection, NoteListsCollection, BusinessesCollection, } from "@bundles/UIAppBundle/collections";
let ProjectListFiltersForm = class ProjectListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.projects.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "description",
                label: t("management.projects.fields.description"),
                name: ["description"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.projects.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.projects.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "IN_PROGRESS" }, { children: "In Progress" }), "IN_PROGRESS"), _jsx(Ant.Select.Option, Object.assign({ value: "REVIEW" }, { children: "Review" }), "REVIEW"), _jsx(Ant.Select.Option, Object.assign({ value: "CANCELED" }, { children: "Canceled" }), "CANCELED"), _jsx(Ant.Select.Option, Object.assign({ value: "COMPLETED" }, { children: "Completed" }), "COMPLETED")] }), void 0) }), void 0)),
            },
            {
                id: "deadline",
                label: t("management.projects.fields.deadline"),
                name: ["deadline"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "docusignEnvelopesIds",
                label: t("management.projects.fields.docusignEnvelopesIds"),
                name: ["docusignEnvelopesIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "createdAt",
                label: t("management.projects.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.projects.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.projects.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.projects.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "clientId",
                label: t("management.projects.fields.client"),
                name: ["clientId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ClientsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "serviceOrdersIds",
                label: t("management.projects.fields.serviceOrders"),
                name: ["serviceOrdersIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrdersCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "assignedTeamMembersIds",
                label: t("management.projects.fields.assignedTeamMembers"),
                name: ["assignedTeamMembersIds"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TeamMembersCollection, field: "fullName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.projects.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "coverId",
                label: t("management.projects.fields.cover"),
                name: ["coverId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "todosListId",
                label: t("management.projects.fields.todosList"),
                name: ["todosListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TodosListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "noteListId",
                label: t("management.projects.fields.noteList"),
                name: ["noteListId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: NoteListsCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.projects.fields.business"),
                name: ["businessId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ProjectListFiltersForm = __decorate([
    Service({ transient: true })
], ProjectListFiltersForm);
export { ProjectListFiltersForm };
