import { Collection, } from "@bluelibs/x-ui";
import { CalendarEvent } from "@root/api.types";
import { TeamMembersCollection, CalendarsCollection, } from "@bundles/UIAppBundle/collections";
export class CalendarEventsCollection extends Collection {
    getName() {
        return "CalendarEvents";
    }
    getInputs() {
        return {
            insert: "CalendarEventInsertInput!",
            update: "CalendarEventUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => TeamMembersCollection,
                name: "invitedTeamMembers",
                many: true,
                field: "invitedTeamMembersIds",
            },
            {
                collection: () => CalendarsCollection,
                name: "calendar",
                field: "calendarId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            date: (v) => (v && new Date(v) ? new Date(v) : v),
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
