import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { ChannelMessagesCollection, } from "@bundles/UIAppBundle/collections";
import { Routes } from "@bundles/UIAppBundle";
import { DeleteOutlined, DownOutlined, EditOutlined, EyeOutlined, } from "@ant-design/icons";
import { AntTableSmart } from "@bluelibs/x-ui-admin";
import { features } from "../../config/features";
import { ChannelMessageList } from "../../config/ChannelMessageList";
export class ChannelMessagesAntTableSmart extends AntTableSmart {
    constructor() {
        super(...arguments);
        this.collectionClass = ChannelMessagesCollection;
    }
    getBody() {
        return ChannelMessageList.getRequestBody();
    }
    getColumns() {
        const list = this.container.get(ChannelMessageList);
        list.build();
        return [...list.rest(), this.getActionsColumn()];
    }
    getActionsColumn() {
        return {
            title: this.i18n.t("generics.list_actions"),
            key: "actions",
            fixed: "right",
            width: 180,
            render: (_, model) => {
                return this.generateActions(model, {
                    label: this.i18n.t("generics.list_actions"),
                    icon: _jsx(DownOutlined, {}, void 0),
                    items: this.getActionItems(),
                });
            },
        };
    }
    getSortMap() {
        return ChannelMessageList.getSortMap();
    }
    getActionItems() {
        const actions = [];
        if (features.view) {
            actions.push({
                label: this.i18n.t("generics.view"),
                icon: _jsx(EyeOutlined, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.CHANNEL_MESSAGES_VIEW, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.edit) {
            actions.push({
                label: this.i18n.t("generics.edit"),
                icon: _jsx(EditOutlined, {}, void 0),
                action: (model) => {
                    this.router.go(Routes.CHANNEL_MESSAGES_EDIT, {
                        params: { id: model._id.toString() },
                    });
                },
            });
        }
        if (features.delete) {
            actions.push({
                label: this.i18n.t("generics.delete"),
                icon: _jsx(DeleteOutlined, {}, void 0),
                confirm: this.i18n.t("generics.ask_confirmation"),
                action: (model) => {
                    this.collection.deleteOne(model._id).then(() => {
                        this.load();
                    });
                },
            });
        }
        return actions;
    }
}
