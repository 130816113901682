var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ImagesCollection, } from "@bundles/UIAppBundle/collections";
let ServiceItemListFiltersForm = class ServiceItemListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.service_items.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "details",
                label: t("management.service_items.fields.details"),
                name: ["details"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "detailsAsHtml",
                label: t("management.service_items.fields.detailsAsHtml"),
                name: ["detailsAsHtml"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "idealClient",
                label: t("management.service_items.fields.idealClient"),
                name: ["idealClient"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "whatWeNeedFromYou",
                label: t("management.service_items.fields.whatWeNeedFromYou"),
                name: ["whatWeNeedFromYou"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "type",
                label: t("management.service_items.fields.type"),
                name: ["type"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.service_items.fields.type") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "SELLABLE" }, { children: "Sellable" }), "SELLABLE"), _jsx(Ant.Select.Option, Object.assign({ value: "CONSUMABLE" }, { children: "Consumable" }), "CONSUMABLE")] }), void 0) }), void 0)),
            },
            {
                id: "recommendations",
                label: t("management.service_items.fields.recommendations"),
                name: ["recommendations"],
                columns: true,
                nest: [
                    {
                        id: "businessType",
                        label: t("management.service_items.fields.recommendations.businessType"),
                        name: ["recommendations", "businessType"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "NEW", label: "New" },
                                { value: "ESTABLISHED", label: "Established" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.service_items.fields.recommendations.businessType") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.service_items.fields.recommendations.businessType_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "keyGoal",
                        label: t("management.service_items.fields.recommendations.keyGoal"),
                        name: ["recommendations", "keyGoal"],
                        required: true,
                        render: (props) => {
                            const options = [
                                { value: "REVENUE", label: "Revenue" },
                                { value: "GROW", label: "Grow" },
                                { value: "GAIN_AUTHORITY", label: "Gain Authority" },
                            ];
                            return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.service_items.fields.recommendations.keyGoal") }, { children: options.map((option) => {
                                        var _a;
                                        return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.service_items.fields.recommendations.keyGoal_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                                    }) }), void 0) }), void 0));
                        },
                    },
                    {
                        id: "budget",
                        label: t("management.service_items.fields.recommendations.budget"),
                        name: ["recommendations", "budget"],
                        required: true,
                        nest: [],
                    },
                    {
                        id: "usesSocialMedia",
                        label: t("management.service_items.fields.recommendations.usesSocialMedia"),
                        name: ["recommendations", "usesSocialMedia"],
                        required: true,
                        render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
                        isList: true,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.service_items.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.service_items.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.service_items.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.service_items.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.service_items.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
ServiceItemListFiltersForm = __decorate([
    Service({ transient: true })
], ServiceItemListFiltersForm);
export { ServiceItemListFiltersForm };
