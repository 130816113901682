var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let TeamMemberViewer = class TeamMemberViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.team_members.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "firstName",
                label: t("management.team_members.fields.firstName"),
                dataIndex: ["firstName"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "lastName",
                label: t("management.team_members.fields.lastName"),
                dataIndex: ["lastName"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "phoneNumber",
                label: t("management.team_members.fields.phoneNumber"),
                dataIndex: ["phoneNumber"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "fullName",
                label: t("management.team_members.fields.fullName"),
                dataIndex: ["fullName"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "email",
                label: t("management.team_members.fields.email"),
                dataIndex: ["email"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.dashboard",
                label: t("management.team_members.fields.permissions.dashboard"),
                dataIndex: ["permissions", "dashboard"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.agency",
                label: t("management.team_members.fields.permissions.agency"),
                dataIndex: ["permissions", "agency"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.clients",
                label: t("management.team_members.fields.permissions.clients"),
                dataIndex: ["permissions", "clients"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.campaigns",
                label: t("management.team_members.fields.permissions.campaigns"),
                dataIndex: ["permissions", "campaigns"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.fulfillmentCenter",
                label: t("management.team_members.fields.permissions.fulfillmentCenter"),
                dataIndex: ["permissions", "fulfillmentCenter"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.learningCenter",
                label: t("management.team_members.fields.permissions.learningCenter"),
                dataIndex: ["permissions", "learningCenter"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.resourceCenter",
                label: t("management.team_members.fields.permissions.resourceCenter"),
                dataIndex: ["permissions", "resourceCenter"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "permissions.agencyStore",
                label: t("management.team_members.fields.permissions.agencyStore"),
                dataIndex: ["permissions", "agencyStore"],
                render: (value) => {
                    const props = {
                        type: "object",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdAt",
                label: t("management.team_members.fields.createdAt"),
                dataIndex: ["createdAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedAt",
                label: t("management.team_members.fields.updatedAt"),
                dataIndex: ["updatedAt"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                label: t("management.team_members.fields.createdById"),
                dataIndex: ["createdById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                label: t("management.team_members.fields.updatedById"),
                dataIndex: ["updatedById"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "business",
                label: t("management.team_members.fields.business"),
                dataIndex: ["business"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESSES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "logo",
                label: t("management.team_members.fields.logo"),
                dataIndex: ["logo"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.IMAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "todosList",
                label: t("management.team_members.fields.todosList"),
                dataIndex: ["todosList"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.TODOS_LISTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "owner",
                label: t("management.team_members.fields.owner"),
                dataIndex: ["owner"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.USERS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "fullName",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            firstName: 1,
            lastName: 1,
            phoneNumber: 1,
            fullName: 1,
            email: 1,
            permissions: {
                dashboard: {
                    analytics: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    todoList: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    dealsToClose: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                agency: {
                    projects: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    sales: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    calendar: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                clients: {
                    myLeads: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    search: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    leads: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    clients: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                campaigns: {
                    email: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                    campaigns: {
                        view: 1,
                        edit: 1,
                        delete: 1,
                        create: 1,
                    },
                },
                fulfillmentCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                learningCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                resourceCenter: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
                agencyStore: {
                    view: 1,
                    edit: 1,
                    delete: 1,
                    create: 1,
                },
            },
            createdAt: 1,
            updatedAt: 1,
            createdById: 1,
            updatedById: 1,
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
            logo: {
                _id: 1,
            },
            logoId: 1,
            todosList: {
                _id: 1,
            },
            todosListId: 1,
            owner: {
                _id: 1,
                fullName: 1,
            },
            ownerId: 1,
        };
    }
};
TeamMemberViewer = __decorate([
    Service({ transient: true })
], TeamMemberViewer);
export { TeamMemberViewer };
