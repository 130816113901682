var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { XList } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service } from "@bluelibs/core";
let BusinessSubscriptionList = class BusinessSubscriptionList extends XList {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "stripeSubscriptionId",
                title: t("management.business_subscriptions.fields.stripeSubscriptionId"),
                key: "management.business_subscriptions.fields.stripeSubscriptionId",
                dataIndex: ["stripeSubscriptionId"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "isActive",
                title: t("management.business_subscriptions.fields.isActive"),
                key: "management.business_subscriptions.fields.isActive",
                dataIndex: ["isActive"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "boolean",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "startedAt",
                title: t("management.business_subscriptions.fields.startedAt"),
                key: "management.business_subscriptions.fields.startedAt",
                dataIndex: ["startedAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "expiresAt",
                title: t("management.business_subscriptions.fields.expiresAt"),
                key: "management.business_subscriptions.fields.expiresAt",
                dataIndex: ["expiresAt"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "createdById",
                title: t("management.business_subscriptions.fields.createdById"),
                key: "management.business_subscriptions.fields.createdById",
                dataIndex: ["createdById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "updatedById",
                title: t("management.business_subscriptions.fields.updatedById"),
                key: "management.business_subscriptions.fields.updatedById",
                dataIndex: ["updatedById"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "acquiredPackage",
                title: t("management.business_subscriptions.fields.acquiredPackage"),
                key: "management.business_subscriptions.fields.acquiredPackage",
                dataIndex: ["acquiredPackage"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SUBSCRIPTION_PACKAGES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "_id",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "business",
                title: t("management.business_subscriptions.fields.business"),
                key: "management.business_subscriptions.fields.business",
                dataIndex: ["business"],
                sorter: true,
                render: (value, model) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.BUSINESSES_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getSortMap() {
        return {
            acquiredPackage: "acquiredPackage._id",
            business: "business.name",
        };
    }
    static getRequestBody() {
        return {
            _id: 1,
            stripeSubscriptionId: 1,
            isActive: 1,
            startedAt: 1,
            expiresAt: 1,
            createdById: 1,
            updatedById: 1,
            acquiredPackage: {
                _id: 1,
            },
            acquiredPackageId: 1,
            business: {
                _id: 1,
                name: 1,
            },
            businessId: 1,
        };
    }
};
BusinessSubscriptionList = __decorate([
    Service({ transient: true })
], BusinessSubscriptionList);
export { BusinessSubscriptionList };
