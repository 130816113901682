var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { CommentsCollection } from "@bundles/UIAppBundle/collections";
let CommentCreateForm = class CommentCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "isMainComment",
                label: t("management.comments.fields.isMainComment"),
                name: ["isMainComment"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "comment",
                label: t("management.comments.fields.comment"),
                name: ["comment"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "commenterId",
                label: t("management.comments.fields.commenterId"),
                name: ["commenterId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "commentIds",
                label: t("management.comments.fields.commentIds"),
                name: ["commentIds"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "likerIds",
                label: t("management.comments.fields.likerIds"),
                name: ["likerIds"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "isApproved",
                label: t("management.comments.fields.isApproved"),
                name: ["isApproved"],
                required: true,
                initialValue: false,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.comments.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.COMMENTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.COMMENTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.COMMENTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CommentsCollection),
    __metadata("design:type", typeof (_a = typeof CommentsCollection !== "undefined" && CommentsCollection) === "function" ? _a : Object)
], CommentCreateForm.prototype, "collection", void 0);
CommentCreateForm = __decorate([
    Service({ transient: true })
], CommentCreateForm);
export { CommentCreateForm };
