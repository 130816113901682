var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { SubscriptionBundlesCollection, } from "@bundles/UIAppBundle/collections";
let SubscriptionPackageListFiltersForm = class SubscriptionPackageListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "price",
                label: t("management.subscription_packages.fields.price"),
                name: ["price"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "pricingType",
                label: t("management.subscription_packages.fields.pricingType"),
                name: ["pricingType"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.subscription_packages.fields.pricingType") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "MONTHLY" }, { children: "Monthly" }), "MONTHLY"), _jsx(Ant.Select.Option, Object.assign({ value: "ANNUALLY" }, { children: "Annually" }), "ANNUALLY"), _jsx(Ant.Select.Option, Object.assign({ value: "LIFETIME" }, { children: "Lifetime" }), "LIFETIME")] }), void 0) }), void 0)),
            },
            {
                id: "subscriptionBundleId",
                label: t("management.subscription_packages.fields.subscriptionBundle"),
                name: ["subscriptionBundleId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SubscriptionBundlesCollection, field: "accessName", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
SubscriptionPackageListFiltersForm = __decorate([
    Service({ transient: true })
], SubscriptionPackageListFiltersForm);
export { SubscriptionPackageListFiltersForm };
