import { Collection, } from "@bluelibs/x-ui";
import { CustomServiceItem } from "@root/api.types";
import { ProjectsCollection } from "@bundles/UIAppBundle/collections";
export class CustomServiceItemsCollection extends Collection {
    getName() {
        return "CustomServiceItems";
    }
    getInputs() {
        return {
            insert: "CustomServiceItemInsertInput!",
            update: "CustomServiceItemUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ProjectsCollection,
                name: "project",
                field: "projectId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
