import { Collection, } from "@bluelibs/x-ui";
import { Coupon } from "@root/api.types";
export class CouponsCollection extends Collection {
    getName() {
        return "Coupons";
    }
    getInputs() {
        return {
            insert: "CouponInsertInput!",
            update: "CouponUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
