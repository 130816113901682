import "./i18n";
import { BUSINESS_SUBSCRIPTIONS_LIST as BASE_BUSINESS_SUBSCRIPTIONS_LIST, BUSINESS_SUBSCRIPTIONS_CREATE as BASE_BUSINESS_SUBSCRIPTIONS_CREATE, BUSINESS_SUBSCRIPTIONS_EDIT as BASE_BUSINESS_SUBSCRIPTIONS_EDIT, BUSINESS_SUBSCRIPTIONS_VIEW as BASE_BUSINESS_SUBSCRIPTIONS_VIEW, } from "./config/routes";
export const BUSINESS_SUBSCRIPTIONS_LIST = {
    ...BASE_BUSINESS_SUBSCRIPTIONS_LIST,
};
export const BUSINESS_SUBSCRIPTIONS_CREATE = {
    ...BASE_BUSINESS_SUBSCRIPTIONS_CREATE,
};
export const BUSINESS_SUBSCRIPTIONS_EDIT = {
    ...BASE_BUSINESS_SUBSCRIPTIONS_EDIT,
};
export const BUSINESS_SUBSCRIPTIONS_VIEW = {
    ...BASE_BUSINESS_SUBSCRIPTIONS_VIEW,
};
