var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { TeamMembersCollection, CalendarsCollection, CalendarEventsCollection, } from "@bundles/UIAppBundle/collections";
let CalendarEventEditForm = class CalendarEventEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.calendar_events.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "date",
                label: t("management.calendar_events.fields.date"),
                name: ["date"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "durationInMinutes",
                label: t("management.calendar_events.fields.durationInMinutes"),
                name: ["durationInMinutes"],
                required: true,
                component: Ant.InputNumber,
            },
            {
                id: "invitedEmails",
                label: t("management.calendar_events.fields.invitedEmails"),
                name: ["invitedEmails"],
                required: true,
                initialValue: [],
                component: Ant.Input,
                isList: true,
            },
            {
                id: "meeting",
                label: t("management.calendar_events.fields.meeting"),
                name: ["meeting"],
                required: true,
                nest: [
                    {
                        id: "id",
                        label: t("management.calendar_events.fields.meeting.id"),
                        name: ["meeting", "id"],
                        component: Ant.Input,
                    },
                    {
                        id: "joinUrl",
                        label: t("management.calendar_events.fields.meeting.joinUrl"),
                        name: ["meeting", "joinUrl"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "password",
                        label: t("management.calendar_events.fields.meeting.password"),
                        name: ["meeting", "password"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "invitedTeamMembersIds",
                label: t("management.calendar_events.fields.invitedTeamMembers"),
                name: ["invitedTeamMembersIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: TeamMembersCollection, field: "fullName", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "calendarId",
                label: t("management.calendar_events.fields.calendar"),
                name: ["calendarId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: CalendarsCollection, field: "_id", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            date: 1,
            durationInMinutes: 1,
            invitedEmails: 1,
            meeting: {
                id: 1,
                joinUrl: 1,
                password: 1,
            },
            invitedTeamMembers: {
                _id: 1,
                fullName: 1,
            },
            invitedTeamMembersIds: 1,
            calendar: {
                _id: 1,
            },
            calendarId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.calendar_events.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => CalendarEventsCollection),
    __metadata("design:type", typeof (_a = typeof CalendarEventsCollection !== "undefined" && CalendarEventsCollection) === "function" ? _a : Object)
], CalendarEventEditForm.prototype, "collection", void 0);
CalendarEventEditForm = __decorate([
    Service({ transient: true })
], CalendarEventEditForm);
export { CalendarEventEditForm };
