import { NoteListsList } from "../components/List/NoteListsList";
import { NoteListsCreate } from "../components/Create/NoteListsCreate";
import { NoteListsEdit } from "../components/Edit/NoteListsEdit";
import { NoteListsView } from "../components/View/NoteListsView";
import { SettingFilled } from "@ant-design/icons";
export const NOTE_LISTS_LIST = {
    path: "/admin/note-lists",
    component: NoteListsList,
    menu: {
        key: "NOTE_LISTS_LIST",
        label: "management.note_lists.menu.title",
        icon: SettingFilled,
    },
};
export const NOTE_LISTS_CREATE = {
    path: "/admin/note-lists/create",
    component: NoteListsCreate,
};
export const NOTE_LISTS_EDIT = {
    path: "/admin/note-lists/:id/edit",
    component: NoteListsEdit,
};
export const NOTE_LISTS_VIEW = {
    path: "/admin/note-lists/:id/view",
    component: NoteListsView,
};
