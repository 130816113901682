import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian, useUIComponents, useRouter, useTranslate, } from "@bluelibs/x-ui";
import { useState } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, Alert, Card, PageHeader, } from "antd";
export function ChangePassword() {
    const guardian = useGuardian();
    const router = useRouter();
    const tl = useTranslate("authentication.changePassword");
    const UIComponents = useUIComponents();
    const [submitError, setSubmitError] = useState(null);
    const [isCompleted, setIsComplete] = useState(false);
    const onSubmit = (data) => {
        const { oldPassword, newPassword } = data;
        guardian
            .changePassword(oldPassword, newPassword)
            .then(() => {
            setIsComplete(true);
        })
            .catch((err) => {
            setSubmitError(err.toString());
        });
    };
    const style = { width: 400, border: 0 };
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(PageHeader, { title: tl("header") }, void 0), _jsxs(Card, Object.assign({ style: style, className: "change-password-page" }, { children: [isCompleted && _jsx(Alert, { type: "success", message: tl("successMessage") }, void 0), !isCompleted && (_jsxs(Form, Object.assign({ onFinish: (data) => onSubmit(data), className: "authentication-form" }, { children: [_jsx(Form.Item, Object.assign({ name: "oldPassword", rules: [
                                    {
                                        required: true,
                                    },
                                ] }, { children: _jsx(Input, { prefix: _jsx(LockOutlined, { className: "site-form-item-icon" }, void 0), type: "password", placeholder: tl("fields.current") }, void 0) }), void 0), _jsx(Form.Item, Object.assign({ name: "newPassword", rules: [{ required: true }] }, { children: _jsx(Input, { prefix: _jsx(LockOutlined, { className: "site-form-item-icon" }, void 0), type: "password", placeholder: tl("fields.newPassword") }, void 0) }), void 0), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", className: "authentication-form-button" }, { children: tl("submit") }), void 0) }, void 0), submitError && _jsx(Alert, { message: `${submitError}`, type: "error" }, void 0)] }), void 0))] }), void 0)] }, void 0));
}
