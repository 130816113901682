import { TeamMembersList } from "../components/List/TeamMembersList";
import { TeamMembersCreate } from "../components/Create/TeamMembersCreate";
import { TeamMembersEdit } from "../components/Edit/TeamMembersEdit";
import { TeamMembersView } from "../components/View/TeamMembersView";
import { SettingFilled } from "@ant-design/icons";
export const TEAM_MEMBERS_LIST = {
    path: "/admin/team-members",
    component: TeamMembersList,
    menu: {
        key: "TEAM_MEMBERS_LIST",
        label: "management.team_members.menu.title",
        icon: SettingFilled,
    },
};
export const TEAM_MEMBERS_CREATE = {
    path: "/admin/team-members/create",
    component: TeamMembersCreate,
};
export const TEAM_MEMBERS_EDIT = {
    path: "/admin/team-members/:id/edit",
    component: TeamMembersEdit,
};
export const TEAM_MEMBERS_VIEW = {
    path: "/admin/team-members/:id/view",
    component: TeamMembersView,
};
