import { AddonsList } from "../components/List/AddonsList";
import { AddonsView } from "../components/View/AddonsView";
import { SettingFilled } from "@ant-design/icons";
export const ADDONS_LIST = {
    path: "/admin/addons",
    component: AddonsList,
    menu: {
        key: "ADDONS_LIST",
        label: "management.addons.menu.title",
        icon: SettingFilled,
    },
};
export const ADDONS_CREATE = {
    path: "/admin/addons/create",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const ADDONS_EDIT = {
    path: "/admin/addons/:id/edit",
    component: () => {
        console.error("This route is not available.");
        return null;
    },
};
export const ADDONS_VIEW = {
    path: "/admin/addons/:id/view",
    component: AddonsView,
};
