import "./i18n";
import { CUSTOM_SERVICE_ITEMS_LIST as BASE_CUSTOM_SERVICE_ITEMS_LIST, CUSTOM_SERVICE_ITEMS_CREATE as BASE_CUSTOM_SERVICE_ITEMS_CREATE, CUSTOM_SERVICE_ITEMS_EDIT as BASE_CUSTOM_SERVICE_ITEMS_EDIT, CUSTOM_SERVICE_ITEMS_VIEW as BASE_CUSTOM_SERVICE_ITEMS_VIEW, } from "./config/routes";
export const CUSTOM_SERVICE_ITEMS_LIST = {
    ...BASE_CUSTOM_SERVICE_ITEMS_LIST,
};
export const CUSTOM_SERVICE_ITEMS_CREATE = {
    ...BASE_CUSTOM_SERVICE_ITEMS_CREATE,
};
export const CUSTOM_SERVICE_ITEMS_EDIT = {
    ...BASE_CUSTOM_SERVICE_ITEMS_EDIT,
};
export const CUSTOM_SERVICE_ITEMS_VIEW = {
    ...BASE_CUSTOM_SERVICE_ITEMS_VIEW,
};
