import { Collection, } from "@bluelibs/x-ui";
import { TeamMember } from "@root/api.types";
import { BusinessesCollection, ImagesCollection, TodosListsCollection, UsersCollection, } from "@bundles/UIAppBundle/collections";
export class TeamMembersCollection extends Collection {
    getName() {
        return "TeamMembers";
    }
    getInputs() {
        return {
            insert: "TeamMemberInsertInput!",
            update: "TeamMemberUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => BusinessesCollection,
                name: "business",
                field: "businessId",
            },
            {
                collection: () => ImagesCollection,
                name: "logo",
                field: "logoId",
            },
            {
                collection: () => TodosListsCollection,
                name: "todosList",
                field: "todosListId",
            },
            {
                collection: () => UsersCollection,
                name: "owner",
                field: "ownerId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
            updatedAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
