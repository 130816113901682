var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { TrainingPlanActionItemCollection, } from "@bundles/UIAppBundle/collections";
let TrainingPlanActionItemCreateForm = class TrainingPlanActionItemCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "title",
                label: t("management.training_plan_action_item.fields.title"),
                name: ["title"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "text",
                label: t("management.training_plan_action_item.fields.text"),
                name: ["text"],
                component: Ant.Input,
            },
            {
                id: "description",
                label: t("management.training_plan_action_item.fields.description"),
                name: ["description"],
                component: Ant.Input,
            },
            {
                id: "videoUrl",
                label: t("management.training_plan_action_item.fields.videoUrl"),
                name: ["videoUrl"],
                component: Ant.Input,
            },
            {
                id: "blogUrl",
                label: t("management.training_plan_action_item.fields.blogUrl"),
                name: ["blogUrl"],
                component: Ant.Input,
            },
            {
                id: "iframeLink",
                label: t("management.training_plan_action_item.fields.iframeLink"),
                name: ["iframeLink"],
                component: Ant.Input,
            },
            {
                id: "type",
                label: t("management.training_plan_action_item.fields.type"),
                name: ["type"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "TEXT", label: "Text" },
                        { value: "VIDEO", label: "Video" },
                        { value: "BLOG", label: "Blog" },
                        { value: "IFRAME", label: "Iframe" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.training_plan_action_item.fields.type") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.training_plan_action_item.fields.type_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "duration",
                label: t("management.training_plan_action_item.fields.duration"),
                name: ["duration"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: false }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.training_plan_action_item.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.TRAINING_PLAN_ACTION_ITEM_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.TRAINING_PLAN_ACTION_ITEM_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.TRAINING_PLAN_ACTION_ITEM_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => TrainingPlanActionItemCollection),
    __metadata("design:type", typeof (_a = typeof TrainingPlanActionItemCollection !== "undefined" && TrainingPlanActionItemCollection) === "function" ? _a : Object)
], TrainingPlanActionItemCreateForm.prototype, "collection", void 0);
TrainingPlanActionItemCreateForm = __decorate([
    Service({ transient: true })
], TrainingPlanActionItemCreateForm);
export { TrainingPlanActionItemCreateForm };
