import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useGuardian } from "@bluelibs/x-ui";
export function AdminFooter() {
    const guardian = useGuardian();
    const { user } = guardian.state;
    if (!guardian.state.initialised || !user) {
        return null;
    }
    return (_jsx("footer", Object.assign({ className: "ant-layout-footer x-ui-admin-footer" }, { children: _jsxs("div", Object.assign({ className: "x-ui-admin-footer", style: { textAlign: "center" } }, { children: ["X-Framework Admin by", " ", _jsx("a", Object.assign({ href: "https://agencybox.com", target: "_blank" }, { children: "Agencybox" }), void 0)] }), void 0) }), void 0));
}
