import { Collection, } from "@bluelibs/x-ui";
import { Publication } from "@root/api.types";
import { ImagesCollection, UsersCollection, MediaSpotlightItemsCollection, } from "@bundles/UIAppBundle/collections";
export class PublicationsCollection extends Collection {
    getName() {
        return "Publications";
    }
    getInputs() {
        return {
            insert: "PublicationInsertInput!",
            update: "PublicationUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => ImagesCollection,
                name: "publicationImage",
                field: "publicationImageId",
            },
            {
                collection: () => UsersCollection,
                name: "publicationOwner",
                field: "publicationOwnerId",
            },
            {
                collection: () => MediaSpotlightItemsCollection,
                name: "mediaSpotlight",
                many: true,
                field: "mediaSpotlightIds",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            scheduleDate: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
