var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { ImagesCollection, } from "@bundles/UIAppBundle/collections";
let MediaSpotlightItemListFiltersForm = class MediaSpotlightItemListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.media_spotlight_items.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "price",
                label: t("management.media_spotlight_items.fields.price"),
                name: ["price"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "publicationType",
                label: t("management.media_spotlight_items.fields.publicationType"),
                name: ["publicationType"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.media_spotlight_items.fields.publicationType") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "FEATURED" }, { children: "Featured" }), "FEATURED"), _jsx(Ant.Select.Option, Object.assign({ value: "STANDARD" }, { children: "Standard" }), "STANDARD")] }), void 0) }), void 0)),
            },
            {
                id: "details",
                label: t("management.media_spotlight_items.fields.details"),
                name: ["details"],
                columns: true,
                nest: [
                    {
                        id: "url",
                        label: t("management.media_spotlight_items.fields.details.url"),
                        name: ["details", "url"],
                        component: Ant.Input,
                    },
                    {
                        id: "sponsored",
                        label: t("management.media_spotlight_items.fields.details.sponsored"),
                        name: ["details", "sponsored"],
                        component: Ant.Input,
                    },
                    {
                        id: "domainReputation",
                        label: t("management.media_spotlight_items.fields.details.domainReputation"),
                        name: ["details", "domainReputation"],
                        component: Ant.Input,
                    },
                    {
                        id: "time",
                        label: t("management.media_spotlight_items.fields.details.time"),
                        name: ["details", "time"],
                        component: Ant.Input,
                    },
                    {
                        id: "domainAuthority",
                        label: t("management.media_spotlight_items.fields.details.domainAuthority"),
                        name: ["details", "domainAuthority"],
                        component: Ant.Input,
                    },
                    {
                        id: "indexed",
                        label: t("management.media_spotlight_items.fields.details.indexed"),
                        name: ["details", "indexed"],
                        component: Ant.Input,
                    },
                    {
                        id: "googleAnalytics",
                        label: t("management.media_spotlight_items.fields.details.googleAnalytics"),
                        name: ["details", "googleAnalytics"],
                        component: Ant.Input,
                    },
                    {
                        id: "image",
                        label: t("management.media_spotlight_items.fields.details.image"),
                        name: ["details", "image"],
                        component: Ant.Input,
                    },
                    {
                        id: "googleNews",
                        label: t("management.media_spotlight_items.fields.details.googleNews"),
                        name: ["details", "googleNews"],
                        component: Ant.Input,
                    },
                    {
                        id: "rating",
                        label: t("management.media_spotlight_items.fields.details.rating"),
                        name: ["details", "rating"],
                        component: Ant.Input,
                    },
                    {
                        id: "credits",
                        label: t("management.media_spotlight_items.fields.details.credits"),
                        name: ["details", "credits"],
                        component: Ant.Input,
                    },
                    {
                        id: "region",
                        label: t("management.media_spotlight_items.fields.details.region"),
                        name: ["details", "region"],
                        component: Ant.Input,
                    },
                    {
                        id: "genre",
                        label: t("management.media_spotlight_items.fields.details.genre"),
                        name: ["details", "genre"],
                        component: Ant.Input,
                    },
                ],
            },
            {
                id: "createdAt",
                label: t("management.media_spotlight_items.fields.createdAt"),
                name: ["createdAt"],
                tooltip: t("management.media_spotlight_items.fields.createdAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "updatedAt",
                label: t("management.media_spotlight_items.fields.updatedAt"),
                name: ["updatedAt"],
                tooltip: t("management.media_spotlight_items.fields.updatedAt_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "logoId",
                label: t("management.media_spotlight_items.fields.logo"),
                name: ["logoId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: ImagesCollection, field: "_id", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
MediaSpotlightItemListFiltersForm = __decorate([
    Service({ transient: true })
], MediaSpotlightItemListFiltersForm);
export { MediaSpotlightItemListFiltersForm };
