import { BusinessDetailsList } from "../components/List/BusinessDetailsList";
import { BusinessDetailsCreate } from "../components/Create/BusinessDetailsCreate";
import { BusinessDetailsEdit } from "../components/Edit/BusinessDetailsEdit";
import { BusinessDetailsView } from "../components/View/BusinessDetailsView";
import { SettingFilled } from "@ant-design/icons";
export const BUSINESS_DETAILS_LIST = {
    path: "/admin/business-details",
    component: BusinessDetailsList,
    menu: {
        key: "BUSINESS_DETAILS_LIST",
        label: "management.business_details.menu.title",
        icon: SettingFilled,
    },
};
export const BUSINESS_DETAILS_CREATE = {
    path: "/admin/business-details/create",
    component: BusinessDetailsCreate,
};
export const BUSINESS_DETAILS_EDIT = {
    path: "/admin/business-details/:id/edit",
    component: BusinessDetailsEdit,
};
export const BUSINESS_DETAILS_VIEW = {
    path: "/admin/business-details/:id/view",
    component: BusinessDetailsView,
};
