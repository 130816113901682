import "./i18n";
import { PUBLICATIONS_LIST as BASE_PUBLICATIONS_LIST, PUBLICATIONS_CREATE as BASE_PUBLICATIONS_CREATE, PUBLICATIONS_EDIT as BASE_PUBLICATIONS_EDIT, PUBLICATIONS_VIEW as BASE_PUBLICATIONS_VIEW, } from "./config/routes";
export const PUBLICATIONS_LIST = {
    ...BASE_PUBLICATIONS_LIST,
};
export const PUBLICATIONS_CREATE = {
    ...BASE_PUBLICATIONS_CREATE,
};
export const PUBLICATIONS_EDIT = {
    ...BASE_PUBLICATIONS_EDIT,
};
export const PUBLICATIONS_VIEW = {
    ...BASE_PUBLICATIONS_VIEW,
};
