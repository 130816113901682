var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { OrderItemsCollection, BusinessesCollection, OrdersCollection, } from "@bundles/UIAppBundle/collections";
let OrderCreateForm = class OrderCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "dueDate",
                label: t("management.orders.fields.dueDate"),
                name: ["dueDate"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "scheduleDate",
                label: t("management.orders.fields.scheduleDate"),
                name: ["scheduleDate"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "ownerId",
                label: t("management.orders.fields.ownerId"),
                name: ["ownerId"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "status",
                label: t("management.orders.fields.status"),
                name: ["status"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "orderDate",
                label: t("management.orders.fields.orderDate"),
                name: ["orderDate"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "service",
                label: t("management.orders.fields.service"),
                name: ["service"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "package",
                label: t("management.orders.fields.package"),
                name: ["package"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "retailPrice",
                label: t("management.orders.fields.retailPrice"),
                name: ["retailPrice"],
                tooltip: t("management.orders.fields.retailPrice_description"),
                component: Ant.InputNumber,
            },
            {
                id: "type",
                label: t("management.orders.fields.type"),
                name: ["type"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "SUBSCRIPTION", label: "Subscription" },
                        { value: "SERVICE_ITEM_PACKAGES", label: "Service Item Packages" },
                        { value: "CREDITS", label: "Credits" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.orders.fields.type") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.orders.fields.type_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "orderItemsIds",
                label: t("management.orders.fields.orderItems"),
                name: ["orderItemsIds"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: OrderItemsCollection, field: "_id", required: true, mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "businessId",
                label: t("management.orders.fields.business"),
                name: ["businessId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: BusinessesCollection, field: "name", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.orders.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.ORDERS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.ORDERS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.ORDERS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => OrdersCollection),
    __metadata("design:type", typeof (_a = typeof OrdersCollection !== "undefined" && OrdersCollection) === "function" ? _a : Object)
], OrderCreateForm.prototype, "collection", void 0);
OrderCreateForm = __decorate([
    Service({ transient: true })
], OrderCreateForm);
export { OrderCreateForm };
