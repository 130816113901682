import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGuardian, useRouter, useTranslate } from "@bluelibs/x-ui";
import { NumberOutlined } from "@ant-design/icons";
import { Routes } from "@bundles/UIAppBundle";
import { Form, Input, Button, Row, Col, Alert, Card, notification, } from "antd";
export const SubmitMagicLink = (props) => {
    const guardian = useGuardian();
    const { userId, code } = props.queryVariables;
    const tl = useTranslate("authentication.submitMagicLink");
    const router = useRouter();
    const [validCodeError, setValidCodeError] = useState(null);
    useEffect(() => {
        if (!userId) {
            router.go(Routes.REQUEST_MAGIC_LINK);
        }
        if (code) {
            onSubmit({ code });
        }
    }, []);
    const onSubmit = (data) => {
        guardian
            .verifyMagicCode(userId, data.code)
            .then(() => {
            notification.success({
                message: "Welcome!",
            });
            router.go(Routes.DASHBOARD);
        })
            .catch((err) => {
            setValidCodeError(err);
        });
    };
    const style = { minHeight: "100vh" };
    return (_jsx(Row, Object.assign({ justify: "center", align: "middle", style: style, className: "submit-magic-link-page" }, { children: _jsx(Col, Object.assign({ sm: 24, md: 12, lg: 6 }, { children: _jsx(Card, Object.assign({ title: tl("header") }, { children: _jsxs(Form, Object.assign({ className: "authentication-form", onFinish: (data) => onSubmit(data) }, { children: [_jsx(Form.Item, Object.assign({ name: "code", rules: [{ required: true }] }, { children: _jsx(Input, { prefix: _jsx(NumberOutlined, {}, void 0), placeholder: tl("magic_code_field") }, void 0) }), void 0), _jsx(Form.Item, { children: _jsx(Link, Object.assign({ className: "authentication-form-magic-code", to: router.path(Routes.REQUEST_MAGIC_LINK) }, { children: tl("send_link_again") }), void 0) }, void 0), _jsx(Form.Item, { children: _jsx(Link, Object.assign({ className: "authentication-form-login", to: router.path(Routes.LOGIN) }, { children: tl("simple_login_btn") }), void 0) }, void 0), _jsx(Form.Item, { children: _jsx(Button, Object.assign({ type: "primary", htmlType: "submit", className: "authentication-form-button" }, { children: tl("submit_magic_link") }), void 0) }, void 0), validCodeError && (_jsx(Alert, { message: validCodeError.networkError
                                ? validCodeError.toString()
                                : tl("invalid_credentials"), type: "error" }, void 0))] }), void 0) }), void 0) }), void 0) }), void 0));
};
