import "./i18n";
import { BUSINESS_ADDONS_LIST as BASE_BUSINESS_ADDONS_LIST, BUSINESS_ADDONS_CREATE as BASE_BUSINESS_ADDONS_CREATE, BUSINESS_ADDONS_EDIT as BASE_BUSINESS_ADDONS_EDIT, BUSINESS_ADDONS_VIEW as BASE_BUSINESS_ADDONS_VIEW, } from "./config/routes";
export const BUSINESS_ADDONS_LIST = {
    ...BASE_BUSINESS_ADDONS_LIST,
};
export const BUSINESS_ADDONS_CREATE = {
    ...BASE_BUSINESS_ADDONS_CREATE,
};
export const BUSINESS_ADDONS_EDIT = {
    ...BASE_BUSINESS_ADDONS_EDIT,
};
export const BUSINESS_ADDONS_VIEW = {
    ...BASE_BUSINESS_ADDONS_VIEW,
};
