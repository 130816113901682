import "./i18n";
import { LEADS_LISTS_LIST as BASE_LEADS_LISTS_LIST, LEADS_LISTS_CREATE as BASE_LEADS_LISTS_CREATE, LEADS_LISTS_EDIT as BASE_LEADS_LISTS_EDIT, LEADS_LISTS_VIEW as BASE_LEADS_LISTS_VIEW, } from "./config/routes";
export const LEADS_LISTS_LIST = {
    ...BASE_LEADS_LISTS_LIST,
};
export const LEADS_LISTS_CREATE = {
    ...BASE_LEADS_LISTS_CREATE,
};
export const LEADS_LISTS_EDIT = {
    ...BASE_LEADS_LISTS_EDIT,
};
export const LEADS_LISTS_VIEW = {
    ...BASE_LEADS_LISTS_VIEW,
};
