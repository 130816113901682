import "./i18n";
import { CLASS_EVENTS_LIST as BASE_CLASS_EVENTS_LIST, CLASS_EVENTS_CREATE as BASE_CLASS_EVENTS_CREATE, CLASS_EVENTS_EDIT as BASE_CLASS_EVENTS_EDIT, CLASS_EVENTS_VIEW as BASE_CLASS_EVENTS_VIEW, } from "./config/routes";
export const CLASS_EVENTS_LIST = {
    ...BASE_CLASS_EVENTS_LIST,
};
export const CLASS_EVENTS_CREATE = {
    ...BASE_CLASS_EVENTS_CREATE,
};
export const CLASS_EVENTS_EDIT = {
    ...BASE_CLASS_EVENTS_EDIT,
};
export const CLASS_EVENTS_VIEW = {
    ...BASE_CLASS_EVENTS_VIEW,
};
